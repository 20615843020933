import {Component, OnInit} from '@angular/core';
import {OwnerReferenceDataService} from "../../services/owner/reference-data-service";
import {MerchantService} from "../../services/merchant-service";
import {ModalController, NavParams} from "@ionic/angular";

@Component({
    selector: 'app-merchant-selection',
    templateUrl: './merchant-selection.component.html',
    styleUrls: ['./merchant-selection.component.scss'],
})
export class MerchantSelectionComponent implements OnInit {

    regionList: any = [];
    regionDropdownList: any = [];
    selectedRegion: any;
    selectMerchantList: any = [];

    constructor(public merchantService: MerchantService, public refdataService: OwnerReferenceDataService,
                public modalController: ModalController, public navParams: NavParams) {
    }

    ngOnInit() {

        let l = this.navParams.get("regionList");
        this.regionDropdownList.push({label: '', value: ''});
        for (let region of l) {
            this.regionDropdownList.push({label: region.region, value: region.region});
        }

    }

    selectRegion() {
        console.log('inside function, Selected region is ', this.selectedRegion);
        if (this.selectedRegion != '') {

            let s = this.merchantService.getMerchantListByRegion(this.selectedRegion).subscribe(v => {
                s.unsubscribe();
                if (v != null) {
                    console.log(v);
                    this.selectMerchantList = v;
                }
            })

        }else{
            this.selectMerchantList = null;
        }


    }

    openLink(link) {
        window.open(link, '_blank');
    }

    selectMerchant(merchant) {
        let data = {selectMerchant: merchant};
        this.modalController.dismiss(data);
    }

    dismiss() {
        this.modalController.dismiss();
    }

}
