import {Component, OnInit} from '@angular/core';
import {AlertController, ModalController, NavController, NavParams, Platform} from "@ionic/angular";
import {UserData} from "../../services/user-data";
import {AuthService} from "../../services/auth-service";
import {AuthenticationService} from "../../services/authentication-service";
import {Router} from "@angular/router";
import {UserAccountService} from "../../services/user-account-service";
import {FcmNotificationService} from "../../services/fcm_notification_service";
import {NgForm} from "@angular/forms";
import {EventsService} from "../../services/events-service";

@Component({
    selector: 'app-user-account-login',
    templateUrl: './user-account-login.component.html',
    styleUrls: ['./user-account-login.component.scss'],
})
export class UserAccountLoginComponent implements OnInit {


    accountType: any = 'Customer';
    showSignUp: any = true;
    loginOption: any = 'login';
    logoutAfterLogin: any = false;


    login: any = {username: '', password: ''};
    signup: any = {email: '', password: '', name: ''};
    loginSubmitted = false;
    singupSubmitted = false;
    showReset = false;
    showLogin = true;
    reset: any = {email: ''};
    resetSubmitted = false;


    constructor(public modalController: ModalController, public userData: UserData, public events: EventsService,
                public authenService: AuthenticationService, public platform: Platform, public router: Router, public navParams: NavParams,
                public userAccountService: UserAccountService, public alertCtrl: AlertController, public fcmService: FcmNotificationService) {
    }

    ngOnInit() {
        this.accountType = this.navParams.get("accountType") == undefined ? '' : this.navParams.get("accountType");
        this.showSignUp = this.navParams.get("showSignUp") == undefined ? '' : this.navParams.get("showSignUp");
        this.loginOption = this.navParams.get("loginOption") == undefined ? '' : this.navParams.get("loginOption");
        this.logoutAfterLogin = this.navParams.get("logoutAfterLogin") == undefined ? '' : this.navParams.get("logoutAfterLogin");
    }

    onLogin(form: NgForm) {
        this.loginSubmitted = true;
        if (form.valid) {
            this.authenService.emailLogin(this.login.username, this.login.password).then((result) => {
                console.log('success login ', result);
                const s1 = this.userAccountService.getUserAccount(this.authenService.currentUserId).subscribe(v => {
                    if (v != null) {
                        s1.unsubscribe();
                        let u: any = v;
                        const userID = this.authenService.currentUserId;
                        this.userData.setUserAccount({
                            "name": u.fullName,
                            "email": u.emailAddress,
                            "phone": u.phoneNumber
                        });
                        console.log('get user account ', u);
                        if (this.platform.is('cordova')) {
                            console.log('initialize the message service')
                            this.fcmService.initialize(userID);
                            this.fcmService.getToken();
                            this.fcmService.listenToNotifications();
                        }
                        let data = {userAccount: u.emailAddress, userID: userID,fullName:u.fullName};
                        if (this.logoutAfterLogin) {
                            this.authenService.signOut().then(v => {
                                this.userData.setUserAccount(null);
                                console.log('log out after login');
                                this.modalController.dismiss(data);
                                this.dismiss();
                            });
                        } else {
                            console.log('the user id ', userID, ' result ', result);
                            this.events.publish('user:login');
                            this.modalController.dismiss(data);
                            this.dismiss();
                            console.log('Finished login');
                        }
                    }
                });
            }).catch(error => {
                console.log(error);
                this.showAlert('User Login', error);
            });
        }

    }

    onSignup(form: NgForm) {
        this.singupSubmitted = true;
        if (form.valid) {
            this.authenService.signupUser(this.signup.email, this.signup.password, this.signup.name)
                .then((userID) => {
                    console.log('result is ', JSON.stringify(userID));
                    this.userAccountService.createUserAccount(this.signup.email, this.signup.name, userID).then(result => {
                        console.log(`creating new account result ${JSON.stringify(result)}`);
                        const s1 = this.userAccountService.getUserAccount(this.authenService.currentUserId).subscribe(v => {
                            s1.unsubscribe();
                            if (v != null) {
                                let u: any = v;
                                this.userData.setUserAccount({
                                    "name": u.fullName,
                                    "email": u.emailAddress,
                                    "phone": u.phoneNumber
                                });
                                let data = {userAccount: u.emailAddress, userID: userID,fullName:u.fullName};
                                if (this.logoutAfterLogin) {
                                    this.authenService.signOut().then(v => {
                                        this.userData.setUserAccount(null);
                                        console.log('log out after login');
                                        this.modalController.dismiss(data);
                                        this.dismiss();
                                    });
                                } else {
                                    console.log('the user id ', userID, ' result ', result);
                                    this.events.publish('user:signup');
                                    this.modalController.dismiss(data);
                                    this.dismiss();
                                }
                            }
                        })
                    });

                })
                .catch(error => {
                    console.log(error);
                    this.showAlert('Account Sign Up', error);
                });
            ;
        }
    }


    async recoverPassword() {
        let alert = await this.alertCtrl.create({
            header: 'Retrieve Password',
            message: 'Enter your login email address',
            inputs: [
                {
                    name: 'email',
                    placeholder: 'Email'
                },
            ],
            buttons: [
                {
                    text: 'Cancel',
                    handler: () => {
                        console.log('Cancel clicked');
                    }
                },
                {
                    text: 'Request',
                    handler: (data) => {
                        console.log('Saved clicked', data.email);
                        this.authenService.passwordReset(data.email).then(() => {
                        })
                    }
                }
            ]
        });
        await alert.present();
    }

    async showAlert(header, title) {
        const alert = await this.alertCtrl.create({
            header: header,
            subHeader: title,
            message: title,
            buttons: ['OK']
        });

        await alert.present();
    }


    showPasswordReset() {
        this.showLogin = false;
        this.showReset = true;
    }

    resetPassword(resetPasswordForm: NgForm) {
        this.resetSubmitted = true;
        console.log('reset password is called');
        if (resetPasswordForm.valid) {
            this.authenService.passwordReset(this.reset.email)
                .then((result) => {
                    console.log('result is ', JSON.stringify(result));
                    this.showAlert('ResetPassword', 'Password reset success, please check your email.');
                })
                .catch(error => {
                    console.log(error);
                    this.showAlert('ResetPassword', error);
                });
            ;
        }

    }

    showLoginform() {
        this.showLogin = true;
        this.showReset = false;
    }

    dismiss() {
        this.modalController.dismiss();
    }

}
