import { Component, OnInit } from '@angular/core';
import {OwnerMerchantMenuService} from "../../../services/owner/merchant-menu-service";
import {AlertController, LoadingController, ModalController, NavParams} from "@ionic/angular";

@Component({
  selector: 'app-merchant-menu-category-edit',
  templateUrl: './merchant-menu-category-edit.component.html',
  styleUrls: ['./merchant-menu-category-edit.component.scss'],
})
export class MerchantMenuCategoryEditComponent  {

  merchantMenu : any;

  merchantid :any;
  menuitemMap: Map<string, any> = new Map<string, any>();
  originalCategory:any;
  menutype: any;

  categoryName: any;
  categoryDescription: any;
  selectedMenuItem: any[] = [];
  menuitemList: any[] = [];

  selectedMenuItemMap :Map<string, any> = new Map<string, any>();

  constructor(public merchantMenuService : OwnerMerchantMenuService,public navParams: NavParams,public modalCtrl: ModalController,
              public loadingCtrl: LoadingController,public alertCtrl: AlertController) {
    this.merchantid = navParams.get("merchantid");
    this.menuitemMap = navParams.get("menuitemMap");
    this.originalCategory = navParams.get("category");
    this.menutype = navParams.get("menutype");
    this.menuitemList=navParams.get('menuitemlist');
    //console.log('Original Category ', this.originalCategory, 'menu items ', this.originalCategory.menuitems);
    if(this.originalCategory === null ){
      //this is a new category
      this.categoryName = '';
      this.categoryDescription = '';
    }else if (this.originalCategory.menuitems === null || this.originalCategory.menuitems === undefined  ){
      this.categoryName = this.originalCategory.name;
      this.categoryDescription = this.originalCategory.description;
    }else{
      this.categoryName = this.originalCategory.name;
      this.categoryDescription = this.originalCategory.description;
      for(let item of this.originalCategory.menuitems){
        this.selectedMenuItem.push({item:item,selected: true});
        this.selectedMenuItemMap.set(item,true);
      }
    }
    for(let item of this.menuitemList){
      if(this.selectedMenuItemMap.get(item.id) == null){
        //this is a menu item not selected yet
        this.selectedMenuItem.push({item:item.id,selected: false});
      }
    }



  }

  save(){
    var newcategory: any = {};
    newcategory.name=this.categoryName;
    newcategory.description=this.categoryDescription;
    newcategory.menuitems = [];
    for(let item of this.selectedMenuItem){
      if(item.selected === true){
        newcategory.menuitems.push(item.item);
      }
    }
    console.log('original category is: ', this.originalCategory, 'new category is ', newcategory);
    let data = {'oldCategory': this.originalCategory, 'newCategory': newcategory};
    this.modalCtrl.dismiss(data);
  }

  dismiss() {
    this.modalCtrl.dismiss();
  }

}
