import { Component, OnInit } from '@angular/core';
import {UserData} from "../../services/user-data";
import {AuthService} from "../../services/auth-service";
import {AlertController, ModalController} from "@ionic/angular";
import {AuthenticationService} from "../../services/authentication-service";

@Component({
  selector: 'app-order-customer-contact',
  templateUrl: './order-customer-contact.component.html',
  styleUrls: ['./order-customer-contact.component.scss'],
})
export class OrderCustomerContactComponent implements OnInit {

  profile: any = {name:'',email:'',phone:''}

  constructor(public userData: UserData,public modalController: ModalController ,
              public alertCtrl: AlertController, public authService: AuthenticationService) {

  }
  ngOnInit() {
    let userAccount :any = this.userData.getUserAccount();
    console.log(' user account is ', userAccount );
    this.profile.name = userAccount.name || '';
    this.profile.email = userAccount.email || '';
    this.profile.phone = userAccount.phone || '';
  }

  async saveProfile(){
    let missingField : any = [];
    if(this.profile.name.trim() === '')
      missingField.push('Customer Name');
    if(this.profile.email.trim() === '')
      missingField.push('Email Address');
    if(this.profile.phone.trim() === '')
      missingField.push('Phone Number')
    if(missingField.length > 0){
      let alert = await this.alertCtrl.create({
        header: 'Missing Field',
        message: 'Please make sure the following required fields are filled: '+ missingField,
        buttons: ['OK']
      });
      alert.present();
    }else{
      this.userData.setUserAccount({"name": this.profile.name,"email": this.profile.email,"phone":this.profile.phone});
      this.dismiss();
    }

  }

  dismiss(){
    this.modalController.dismiss();
  }


}
