import {Injectable} from '@angular/core';
import {AngularFireDatabase, AngularFireList, AngularFireObject} from '@angular/fire/database';
import 'rxjs/add/operator/map';

import {OwnerUserDataService} from "./user-data-service";
import {OwnerAuthService} from "./vendor-auth-service";
import {AlertController} from "@ionic/angular";
import {Subject} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {ShareDataService} from "../shareData-service";


export class FoodOrder {
  constructor(
    public orderNumber: string,
    public customerId: any,
    public customerName: string,
    public customerEmail: any,
    public customerPhone: any,
    public orderComment: any,
    public subtotal: number,
    public salesTax: number,
    public discount: number,
    public totalOrderPrice: number,
    public merchantId: number,
    public merchantLocationId: string,
    public merchantName: string,
    public orderItems: OrderItem[],
    public status: string,
    public qrCode: string,
    public orderYear: number,
    public orderMonth: number,
    public orderDay: number,
    public orderTimeStamp: number,
    public orderType: string,
    public orderBy: string,
    public dateKey: string,
    public merchantRegion: string
  ) {
  }
}

export class OrderItem {
  constructor(
    public menuitemId: string,
    public primaryItemDescription: string,
    public optionalItemDescription: string,
    public totalPrice: number,
    public quantity: number,
    public itemComment: string
  ) {
  }
}


@Injectable()
export class OwnerOrderProcessService {

  foodcart: any[];
  orderSubmission = new Subject();
  merchantid: any;

  constructor(private db: AngularFireDatabase,
              private alertCtrl: AlertController,
              private userData: OwnerUserDataService,
              private authService: OwnerAuthService,
              private http: HttpClient,
              private sharedDataService: ShareDataService
  ) {

  }

  submitOrder(customerName, customerPhone, comment,locationId): any {
    let s1 = this.http.get('https://us-central1-gulpmealdev.cloudfunctions.net/getDate', {responseType: 'text'}).subscribe({
      next: data => {
        s1.unsubscribe();
        console.log('the order submission date from server is ', data);
        let orderTime: Date = new Date();
        orderTime.setTime(Date.parse(data));
        console.log('Will use server date ', orderTime);
        this.submitOrderDetail(customerName, customerPhone, comment, orderTime,locationId)
      },
      error: error => {
        console.error('Error happened when calling server ', error);
        let orderTime: Date = new Date();
        console.log('Will use local date ', orderTime);
        this.submitOrderDetail(customerName, customerPhone, comment, orderTime,locationId)
      }
    });

  }


  submitOrderDetail(customerName, customerPhone, comment, orderTime,locationID): any {
    var userAccount = this.userData.getUserAccount();
    let merchant = this.sharedDataService.merchant;
    console.log(this.authService.currentUserId, '--', userAccount, ' -- ', merchant);

    this.foodcart = this.userData.getCartStatus();
    var orderitems: OrderItem[] = [];
    var totalPrice: number = 0;
    for (var orderitem of this.foodcart) {
      var optionalDescription: string = "";
      if (orderitem.optionalSelections != null && orderitem.optionalSelections.length > 0) {
        optionalDescription = orderitem.optionalSelections.join(',');
      }
      var item: OrderItem = new OrderItem(orderitem.menuitem.id, orderitem.orderdescription, optionalDescription, orderitem.totalPrice, orderitem.quantity, orderitem.comment || '');
      totalPrice = totalPrice + orderitem.totalPrice;
      orderitems.push(item);
    }

    let orderRef = this.db.database.ref('merchantOrder/' + userAccount.account.merchantID + '/' + locationID);
    let orderKey = orderRef.push().key;


    let path = 'merchantOrder/' + userAccount.account.merchantID + '/' + locationID + '/';
    console.log('path to save order is', path);


    let year = orderTime.getFullYear();
    let month = orderTime.getMonth() + 1;
    let day = orderTime.getDate();
    let timestamp = orderTime.getTime();
    let dateKey = this.sharedDataService.getDateKey(orderTime);
    let name = customerName;
    let email = '';
    let phone = customerPhone;
    let qrCode = '';


    var merchantOrder: FoodOrder = new FoodOrder('', userAccount.id, customerName, email, customerPhone, comment,
      totalPrice, 0, 0, totalPrice, userAccount.account.merchantID, locationID, merchant.sharedInfo.name, orderitems, "ACCEPTED",
      qrCode, year, month, day, timestamp, "Merchant", userAccount.id, dateKey, '');


    let orderSummaryRef = this.db.database.ref('merchantOrderSummary/' + dateKey + '/' + merchantOrder.merchantId + '/' + merchantOrder.merchantLocationId);
    orderSummaryRef.transaction((summary) => {
      //use a transaction to get the total order number to generate the order number
      console.log('order summary is ', summary)
      if (summary == null) {
        summary = {totalOrder: 0, orders: {}}
      }
      summary.totalOrder++;
      let orderNumber = dateKey.replaceAll('-', '') + '-' + this.prepenZero(summary.totalOrder, 4);
      summary.orders[orderKey] = {
        orderNumber: orderNumber,
        total: merchantOrder.totalOrderPrice,
        merchantName: merchantOrder.merchantName,
        region: merchantOrder.merchantRegion,
        merchantId: merchantOrder.merchantId,
        locationId: merchantOrder.merchantLocationId
      };
      merchantOrder.orderNumber = orderNumber;
      return summary;
    }).then(v => {

      console.log("the final customer order will be : ", merchantOrder)
      const merchantOrderTrack = {
        orderId: orderKey,
        dateKey: merchantOrder.dateKey,
        total: merchantOrder.totalOrderPrice
      }

      const customerOrder = {
        merchantId: merchantOrder.merchantId,
        merchantLocationId: merchantOrder.merchantLocationId,
        orderId: orderKey,
        dateKey: merchantOrder.dateKey,
        orderTime: merchantOrder.orderTimeStamp,
        merchantName: merchantOrder.merchantName,
        total: merchantOrder.totalOrderPrice
      };

      const orderHistory = {
        customerId: merchantOrder.customerId,
        merchantId: merchantOrder.merchantId,
        merchantLocationId: merchantOrder.merchantLocationId,
        orderPlaceTimeStamp: merchantOrder.orderTimeStamp,
        orderNumber: merchantOrder.orderNumber,
        orderCompleteTime: 0,
        TotalTime: 0,
        statusTrack: {
          SUBMITTED:{
            status: "SUBMITTED",
            timeStamp: merchantOrder.orderTimeStamp,
            updateAccountId: merchantOrder.customerId,
            updateUserName: userAccount.account.fullName
          },
          ACCEPTED:{
            status: "ACCEPTED",
            timeStamp: merchantOrder.orderTimeStamp,
            updateAccountId: merchantOrder.customerId,
            updateUserName: userAccount.account.fullName
          }
        }
      };

      console.log('user account ', userAccount, 'order history will be ', orderHistory);

      const cusOrderPath = 'customerOrderTrack/' + merchantOrder.customerId + '/' + orderKey + '/';
      const orderHisPath = 'orderHistory/' + merchantOrder.dateKey + '/' +  orderKey + '/';
      const merOrderTrackPath = 'merchantOrderTrack/' + merchantOrder.merchantId + '/' + merchantOrder.dateKey + '/' + merchantOrder.merchantLocationId + '/' + orderKey + '/';
      const merOrderListPath = 'merchantOrder/' + merchantOrder.merchantId + '/' + merchantOrder.merchantLocationId + '/' + orderKey + '/';

      let updates = {};
      updates[cusOrderPath] = customerOrder;
      updates[orderHisPath] = orderHistory;
      updates[merOrderTrackPath] = merchantOrderTrack;
      this.db.database.ref(merOrderListPath).update(merchantOrder).then(x => {
        this.db.database.ref(cusOrderPath).update(customerOrder).then(x => {
          this.db.database.ref(merOrderTrackPath).update(merchantOrderTrack).then(x => {
            this.db.database.ref(orderHisPath).update(orderHistory).then(x => {


              let orderSubmissionSummary = {
                customerName: customerName,
                customerPhone: customerPhone,
                orderID: orderKey,
                orderNumber: merchantOrder.orderNumber,
                status: merchantOrder.status,
                merchantId: merchantOrder.merchantId,
                merchantName: merchantOrder.merchantName,
                orderItems: merchantOrder.orderItems,
                subtotal: merchantOrder.subtotal,
                discount: merchantOrder.discount,
                salesTax: merchantOrder.salesTax,
                totalOrderPrice: merchantOrder.totalOrderPrice,
                orderComment: comment
              };
              this.orderSubmission.next(orderSubmissionSummary);

            })
          })
        })
      })
    });

  }


  prepenZero(str, len) {
    if (typeof str === 'number' || Number(str)) {
      str = str.toString();
      return (len - str.length > 0) ? new Array(len + 1 - str.length).join('0') + str : str;
    } else {
      return str;
    }
  }


}
