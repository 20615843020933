import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import {DomSanitizer,SafeResourceUrl,} from '@angular/platform-browser';


import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/observable/of';


@Injectable()
export class OwnerAddressGeocodeService {

  constructor(public httpClient: HttpClient,public sanitizer:DomSanitizer) { }

  geoResult: any;


  getGeocodeFromAddress(address: string) :Observable<any>{
    //let a = 'https://maps.googleapis.com/maps/api/geocode/json?address=1600+Amphitheatre+Parkway,+Mountain+View,+CA&key=AIzaSyBq5eSUrGI64BPBx2LLmSuSuMSQuHiMf_0';
    //let a = 'https://maps.googleapis.com/maps/api/geocode/json?address=1221+Avenue+of+American,New+York,NY&key=AIzaSyBq5eSUrGI64BPBx2LLmSuSuMSQuHiMf_0';
    let a = this.createAddressString(address);
    this.geoResult = this.httpClient.get(a);
    return this.geoResult.map(data => ({address: data.results[0].formatted_address, lat:  data.results[0].geometry.location.lat, lng:  data.results[0].geometry.location.lng}));

  }


  createAddressString(address: string){
    let addStr = 'https://maps.googleapis.com/maps/api/geocode/json?address=';
    addStr = addStr.concat(address.replace(/ /g,"+"));
    addStr = addStr.concat('&key=AIzaSyBq5eSUrGI64BPBx2LLmSuSuMSQuHiMf_0');
    return addStr;
  }



}
