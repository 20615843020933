import {Component, OnInit} from '@angular/core';
import {AlertController, LoadingController, ModalController, NavParams} from "@ionic/angular";
import {OwnerMerchantService} from "../../../services/owner/merchant-service";
import {MerchantLocationSearchComponent} from "../merchant-location-search/merchant-location-search.component";

@Component({
  selector: 'app-merchant-favorite-location-edit',
  templateUrl: './merchant-favorite-location-edit.component.html',
  styleUrls: ['./merchant-favorite-location-edit.component.scss'],
})
export class MerchantFavoriteLocationEditComponent {


  merchantid: any;
  locationId: any;
  location: any = {locationName: '', address: '', lat: 0.0, lng: 0.0, enterBy: ''}


  locationName: any;
  locationAddress: any;
  lat: any;
  lng: any;

  constructor(public navParams: NavParams, public modalCtrl: ModalController, private merchantService: OwnerMerchantService,
              public loadingCtrl: LoadingController, public alertCtrl: AlertController) {


  }

  ionViewWillEnter() {
    this.merchantid = this.navParams.get("merchantid");
    let loc = this.navParams.get("currentLocation");
    if (loc === null) {
      this.locationId = null;
      this.location = {locationName: '', address: '', lat: 0.0, lng: 0.0, enterBy: ''};
    } else {
      this.location = {locationName: '', address: '', lat: 0.0, lng: 0.0, enterBy: ''};
      this.location.locationName = loc.locationName;
      this.location.address = loc.address;
      this.location.lat = loc.lat;
      this.location.lng = loc.lng;
      this.locationId = loc.key;

      this.locationAddress = loc.address;
      this.locationName = loc.locationName;
      this.lat = loc.lat;
      this.lng = loc.lng;

    }
  }

  async save() {
    this.location.locationName = this.locationName || '';
    this.location.address = this.locationAddress || '';
    this.location.lat = this.lat;
    this.location.lng = this.lng;
    if (this.location.locationName == '' || this.location.address == '') {
      let alert = await this.alertCtrl.create({
        header: 'Missing Field',
        message: 'Please make sure the following required fields are filled: Location Name and Address',
        buttons: ['OK']
      });
      alert.present();

    } else {
      console.log('Save location for ', this.location);
      if (this.locationId === null) {
        //this is a new location
        this.merchantService.createMerchantFavoriteLocation(this.merchantid, this.location).then(v => {
          this.dismiss();
        });
      } else {
        this.merchantService.updateMerchantFavoriteLocation(this.merchantid, this.locationId, this.location).then(v => {
          this.dismiss();
        });
      }
    }
  }

  removeLocation() {
    this.merchantService.removeMerchantFavoriteLocation(this.merchantid, this.locationId).then(v => {
      this.dismiss();
    });
  }


  dismiss() {
    this.modalCtrl.dismiss();
  }

  async locationSearch() {
    console.log('location search is called');
    let modal = await this.modalCtrl.create(
      {
        component: MerchantLocationSearchComponent,
        componentProps: {
          showFavorite: false
        }
      });
    modal.onDidDismiss().then(v => {
      let data = v.data;
      console.log('will use the new location to update merchant', data);
      if (data != null) {
        this.locationAddress = data.address;
        this.lat = data.lat;
        this.lng = data.lng;
      }

    });
    await modal.present();

  }


}
