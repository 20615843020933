import { Component, OnInit } from '@angular/core';
import {AlertController, LoadingController, ModalController, NavParams} from "@ionic/angular";
import {OwnerMerchantMenuService} from "../../../services/owner/merchant-menu-service";

@Component({
  selector: 'app-merchant-menu-item-edit',
  templateUrl: './merchant-menu-item-edit.component.html',
  styleUrls: ['./merchant-menu-item-edit.component.scss'],
})
export class MerchantMenuItemEditComponent  {

  merchantid :any;
  optionalitemMap: Map<string, any> = new Map<string, any>();
  selectedOptionalItemMap :Map<string, any> = new Map<string, any>();
  originalItem:any;
  updatedItem = {id:'', item:{name:'',description:'',image:'',ingredients:'',optionalItemKey:[],price:0.0}};
  selectedOptionalItem: any[] = [];
  optionalItemList: any[] = [];


  merchantMenu : any;

  menuitemMap: Map<string, any> = new Map<string, any>();
  originalCategory:any;
  menutype: any;

  categoryName: any;
  categoryDescription: any;
  selectedMenuItem: any[] = [];
  menuitemList: any[] = [];

  selectedMenuItemMap :Map<string, any> = new Map<string, any>();

  constructor(public merchantMenuService : OwnerMerchantMenuService,public navParams: NavParams,
              public loadingCtrl: LoadingController,public alertCtrl: AlertController,public modalCtrl: ModalController) {
    this.merchantid = navParams.get("merchantid");
    this.optionalitemMap = navParams.get("optionalItemMap");
    this.originalItem = navParams.get("menuitem");
    this.optionalItemList = navParams.get("optionalItemList");

    if(this.originalItem === null ){
      //this is a new menu item
      this.updatedItem.id = null;

    }else{
      this.updatedItem.id = this.originalItem.id;
      this.updatedItem.item.name = this.originalItem.primaryMenuItem.name;
      this.updatedItem.item.description = this.originalItem.primaryMenuItem.description;
      this.updatedItem.item.price = parseFloat(this.originalItem.primaryMenuItem.price);
      console.log('menu item is ',this.updatedItem.item);
      for(let item of this.originalItem.requiredMenuItems){
        this.selectedOptionalItemMap.set(item.id,true);
        this.selectedOptionalItem.push({item:item.id,selected:true});
      }
      for(let item of this.originalItem.optionalMenuItems){
        this.selectedOptionalItemMap.set(item.id,true);
        this.selectedOptionalItem.push({item:item.id,selected:true});
      }
    }
    console.log(this.optionalItemList);


    for(let item of this.optionalItemList){
      if(this.selectedOptionalItemMap.get(item.key) == null){
        //this is a optional item not selected yet
        this.selectedOptionalItem.push({item:item.key,selected: false});
      }
    }



  }

  save(){
    for(let item of this.selectedOptionalItem){
      if(item.selected === true){
        this.updatedItem.item.optionalItemKey.push(item.item);
      }
    }
    console.log('the update item will be ', this.updatedItem);
    this.updatedItem.item.price = parseFloat(''+this.updatedItem.item.price);
    let data = {'oldItem': this.originalItem, 'updatedItem': this.updatedItem};
    console.log('return data: ', data)
    this.modalCtrl.dismiss(data);
  }

  dismiss() {
    this.modalCtrl.dismiss();
  }


}
