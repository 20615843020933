import { Component, OnInit } from '@angular/core';
import {OwnerMerchantMenuService} from "../../../services/owner/merchant-menu-service";
import {AlertController, ModalController, NavParams} from "@ionic/angular";

@Component({
  selector: 'app-merchant-menu-category-reorder',
  templateUrl: './merchant-menu-category-reorder.component.html',
  styleUrls: ['./merchant-menu-category-reorder.component.scss'],
})
export class MerchantMenuCategoryReorderComponent  {

  merchantMenu : any;

  merchantid :any;
  menutype: any;

  menuTypeName: any;
  categoryList: any[] = [];


  constructor(public merchantMenuService : OwnerMerchantMenuService,public navParams: NavParams,public modalCtrl: ModalController,
              public alertCtrl: AlertController) {
    this.merchantid = navParams.get("merchantid");
    this.menutype = navParams.get("menutype");

    for(let item of this.menutype.categories){
      this.categoryList.push(item);
    }
  }

  save(){

    console.log('new categoryList is: ', this.categoryList);
    let data = {'categoryList': this.categoryList};
    this.modalCtrl.dismiss(data);
  }

  dismiss() {
    this.modalCtrl.dismiss();
  }


  onRenderItems(event) {
    console.log(`Moving item from ${event.detail.from} to ${event.detail.to}`);
    let draggedItem = this.categoryList.splice(event.detail.from,1)[0];
    this.categoryList.splice(event.detail.to,0,draggedItem)
    event.detail.complete();
  }

}
