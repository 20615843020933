import { Component, OnInit } from '@angular/core';
import {AlertController, ModalController, NavParams} from "@ionic/angular";
import {AuthService} from "../../../services/auth-service";
import {OwnerMerchantOrderService} from "../../../services/owner/merchant-order-service";

@Component({
  selector: 'app-merchant-order-detail',
  templateUrl: './merchant-order-detail.component.html',
  styleUrls: ['./merchant-order-detail.component.scss'],
})
export class MerchantOrderDetailComponent  {

  merchantID: any;
  merchantLocationID: any;
  orderID: any;
  orderDetail: any;
  nextStatus: any = "Accept";
  statusButtonMap : any;
  nextOrderStatusMap: any;
  showStatusButton: boolean = true;



  constructor( public navParams: NavParams, public modalCtrl: ModalController,
               public alertCtrl: AlertController,
               public authService : AuthService,
               public merchantOrderService : OwnerMerchantOrderService ){
    //this.orderDetail = navParams.data.orderDetail;
    this.orderID = navParams.get('orderID');
    this.merchantID = navParams.get('merchantID');
    this.merchantLocationID = navParams.get('merchantLocationID');
    console.log('order id ', this.orderID)
    const s = this.merchantOrderService.getMerchantOrderDetail(this.merchantID,this.merchantLocationID,this.orderID).subscribe(v=>{
      s.unsubscribe();
      if(v != null){
        console.log(v);
        this.orderDetail = v;
      }
      this.statusButtonMap = new Map<string,string>();
      this.statusButtonMap.set('SUBMITTED', 'ACCEPT');
      this.statusButtonMap.set('ACCEPTED', 'PREPARE');
      this.statusButtonMap.set('PREPARED', 'READY');
      this.statusButtonMap.set('READY', 'COMPLETE');
      this.nextOrderStatusMap = new Map<string,string>();
      this.nextOrderStatusMap.set('ACCEPT', 'ACCEPTED');
      this.nextOrderStatusMap.set('PREPARE', 'PREPARED');
      this.nextOrderStatusMap.set('READY', 'READY');
      this.nextOrderStatusMap.set('COMPLETE', 'COMPLETE');
      this.setButton();
    })
  }

  setButton(){
    if(this.getNextStatus(this.orderDetail.status) != null){
      this.nextStatus = this.getNextStatus(this.orderDetail.status);
      this.showStatusButton = true;
    }else{
      this.showStatusButton = false;
    }
  }



  hasOptionalSelections(optionalSelections: any){
    if(optionalSelections == "")
      return false;
    else
      return true;
  }

  dismiss() {
    this.modalCtrl.dismiss();
  }

  updateStatus(){
    console.log('Will update order status to ',this.nextStatus, this.nextOrderStatusMap.get(this.nextStatus) );
    if(this.nextOrderStatusMap.get(this.nextStatus) != null){
      this.merchantOrderService.updateOrderStatus(this.merchantID,this.merchantLocationID,this.orderDetail,this.nextOrderStatusMap.get(this.nextStatus));
      this.orderDetail.status = this.nextOrderStatusMap.get(this.nextStatus);
      this.setButton();
    }

  }

  cancelOrder(){
    this.merchantOrderService.updateOrderStatus(this.merchantID,this.merchantLocationID,this.orderDetail,'CANCELLED');
    this.dismiss();
  }

  isCancelOrder(currentStatus):any{
    if(currentStatus === 'CANCELLED'){
      return true;
    }else{
      return false;
    }
  }

  getNextStatus(currentStatus): string{
    //console.log('status is ', currentStatus, 'mapped value is ', this.statusButtonMap.get(currentStatus));
    return this.statusButtonMap.get(currentStatus);
  }

  notEmptyString(content):any{
    if(content !== undefined && content !== null && content.trim() !== ''){
      return true;
    }else {
      return false;
    }
  }
}
