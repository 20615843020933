import { Component, OnInit } from '@angular/core';
import {AlertController, ModalController, NavParams} from "@ionic/angular";
import {OwnerMerchantMenuService} from "../../../services/owner/merchant-menu-service";

@Component({
  selector: 'app-merchant-menu-item-reorder',
  templateUrl: './merchant-menu-item-reorder.component.html',
  styleUrls: ['./merchant-menu-item-reorder.component.scss'],
})
export class MerchantMenuItemReorderComponent {

  merchantMenu : any;

  merchantid :any;
  menuitemMap: Map<string, any> = new Map<string, any>();
  originalCategory:any;
  menutype: any;

  categoryName: any;
  categoryDescription: any;
  selectedMenuItem: any[] = [];
  menuitemList: any[] = [];


  constructor(public merchantMenuService : OwnerMerchantMenuService,public navParams: NavParams,public modalCtrl: ModalController,
              public alertCtrl: AlertController) {
    this.merchantid = navParams.get("merchantid");
    this.menuitemMap = navParams.get("menuitemMap");
    this.originalCategory = navParams.get("category");
    this.menutype = navParams.get("menutype");
    this.menuitemList=navParams.get('menuitemlist');

    this.categoryName = this.originalCategory.name;
    this.categoryDescription = this.originalCategory.description;
    for(let item of this.originalCategory.menuitems){
      this.selectedMenuItem.push({item:item,selected: true});
    }
  }

  save(){
    var newcategory: any = {};
    newcategory.name=this.categoryName;
    newcategory.description=this.categoryDescription;
    newcategory.menuitems = [];
    for(let item of this.selectedMenuItem){
      if(item.selected === true){
        newcategory.menuitems.push(item.item);
      }
    }
    console.log('original category is: ', this.originalCategory, 'new category is ', newcategory);
    let data = {'oldCategory': this.originalCategory, 'newCategory': newcategory};
    this.modalCtrl.dismiss(data);
  }

  dismiss() {
    this.modalCtrl.dismiss();
  }

  onRenderItems(event) {
    let draggedItem = this.selectedMenuItem.splice(event.detail.from,1)[0];
    this.selectedMenuItem.splice(event.detail.to,0,draggedItem)
    event.detail.complete();
  }


}
