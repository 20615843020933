import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class EventsService {
    private eventObject = new Subject<any>();

    publish(data: any) {
        this.eventObject.next(data);
    }

    getObservable(): Subject<any> {
        return this.eventObject;
    }
}