import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFireAuth } from '@angular/fire/auth';
import * as firebase from 'firebase';
import "firebase/auth";

import {AlertController} from '@ionic/angular';


import { UserData } from '../services/user-data';
import {Subject} from "rxjs";
import {EventsService} from "./events-service";


@Injectable({
  providedIn: 'root'
})
export class AuthService {


  authState: any = null;
  loginCredential : any = null;
  loginStatus = new Subject();
  isFacebookLogin: boolean = false;
  isEmailLogin: boolean = false;
  isGoogleLogin: boolean = false;
  isTwitterLogin: boolean = false;



  constructor(private afAuth: AngularFireAuth,
              private db: AngularFireDatabase,
              private alertCtrl: AlertController,
              private events: EventsService,
              private userData: UserData
              ) {
            /*
            this.afAuth.authState.subscribe((auth) => {
              console.log(`current auth state is ${auth}`);
              this.authState = auth;
            });*/
          }

  // Returns true if user is logged in
  get authenticated(): boolean {
    return this.authState !== null;
  }

  // Returns current user data
  get currentUser(): any {
    return this.authenticated ? this.authState : null;
  }

  //return current login credential
  get currentCredential():any{
    return this.loginCredential ? this.loginCredential : null;
  }

  // Returns
  get currentUserObservable(): any {
    return this.afAuth.authState
  }

  // Returns current user UID
  get currentUserId(): string {
    return this.authenticated ? this.authState.uid : '';
  }

  // Anonymous User
  get currentUserAnonymous(): boolean {
    return this.authenticated ? this.authState.isAnonymous : true
  }



  // Returns current user display name or Guest
  get currentUserDisplayName(): string {
    if (!this.authState) { return 'Guest' }
    else if (this.currentUserAnonymous) { return 'Anonymous' }
    else { return this.authState['displayName'] || 'User without a Name' }
  }

  //// Social Auth ////
  /*
  githubLogin() {
    const provider = new firebase.auth.GithubAuthProvider();
    return this.socialSignIn(provider);
  }

  googleLogin() {
    const provider = new firebase.auth.GoogleAuthProvider();
    return this.socialSignIn(provider).then((v)=>{
      this.isGoogleLogin = true;
      this.updateUserData(true,false,false,false);
      this.isEmailLogin = false;
      this.events.publish('user:login');
    });
  }

  facebookLogin() {
    const provider = new firebase.auth.FacebookAuthProvider();
    return this.socialSignIn(provider).then((v)=>{
      this.isFacebookLogin = true;
      this.updateUserData(false,true,false,false);
    });
  }

  twitterLogin(){
    const provider = new firebase.auth.TwitterAuthProvider();
    return this.socialSignIn(provider).then((v)=>{
      this.isTwitterLogin = true;
      this.updateUserData(false,false,true,false);
    });
  }

  private socialSignIn(provider) {
    console.log(provider);

    if (!(<any>window).cordova) {
      //this is the login from browser
    		return this.afAuth.auth.signInWithPopup(provider).then((credential) =>  {
                console.log('socail login through web',credential);
                  this.authState = credential.user;
                  this.loginCredential = credential.credential;
                  this.events.publish('user:login');
                  console.log('the login Credential object is ', this.loginCredential)
                  return credential;
              })
              .catch(error => alert(error.messgage));;
    	} else {
        //this is login with cordova
        console.log('socail login through device');
        this.afAuth.auth.signInWithRedirect(provider);
        return this.afAuth.auth.getRedirectResult().then( credential => {
          console.log('socail login through device, result ',credential);
          this.authState = credential.user;
          this.loginCredential = credential.credential;
          this.events.publish('user:login');
          console.log('the login Credential object is ', this.loginCredential)
        }).catch(function(error) {
           alert(error.message);
        });
    		/*return this.afAuth.auth.signInWithRedirect(provider)
    		.then(() => {
    			return this.afAuth.auth.getRedirectResult().then( credential => {
            this.authState = credential.user;
            this.loginCredential = credential.credential;
            this.events.publish('user:login');
            console.log('the login Credential object is ', this.loginCredential)
            return credential;
    			}).catch(function(error) {
    				 alert(error.message);
    			});
    		});
    	};
  }
*/

  //// Anonymous Auth ////
  anonymousLogin() : Promise<any> {
    return this.afAuth.signInAnonymously()
    .then((user) => {
      this.authState = user;
      this.resetLoginProviderStatus();
      console.log('Anonymous login ', this.authState);
      this.events.publish('user:logout');
      return this.afAuth.authState
    })
    .catch(error => {
      console.log(error);
    });
  }

  //// Email/Password Auth ////
  emailSignUp(email:string, password:string) {
    return this.afAuth.createUserWithEmailAndPassword(email, password)
      .then((user) => {
        this.authState = user;
        this.isEmailLogin = true;
        this.updateUserData(false,false,false,true);
        this.events.publish('user:signup');
      })
      .catch(error => {
        console.log(error);
        this.showSignUpErrorAlert(error);
      });
  }

  emailLogin(email:string, password:string) {
     return this.afAuth.signInWithEmailAndPassword(email, password)
       .then((user) => {
         this.authState = user;
         this.isEmailLogin = true;
         this.events.publish('user:login');
       })
       .catch(error => {
         console.log(error);
         this.showLoginErrorAlert(error);
       });
  }

  // Sends email allowing user to reset password
  resetPassword(email: string) {

    return this.afAuth.sendPasswordResetEmail(email)
      .then(() => console.log("email sent"))
      .catch((error) => console.log(error))
  }


  //// Sign Out ////
  signOut() {
    return this.afAuth.signOut().then(v =>{
      this.loginCredential = null;
    });
  }


  //// Helpers ////
  private updateUserData(googleSignup: any,facebookSignup: any, twitterSignup: any,emailSignup: any): any {
  // Writes user name and email to realtime db
  // useful if your app displays information about users or for admin features
    let path = `customerAccount/${this.currentUserId}`; // Endpoint on firebase
    let data = {
                  emailAddress: this.authState.email,
                  fullName: this.authState.displayName || "",
                  facebookAccount: facebookSignup,
                  googleAccount: googleSignup,
                  twitterAccount:twitterSignup,
                  emailAccount: emailSignup
                };

    this.db.object(path).update(data).then(  v=>  {console.log("user updated for ", data); return v; }
)
    .catch(error => console.log(error));

  }

  async  showSignUpErrorAlert(title){
    const alert = await this.alertCtrl.create({
      header: 'Account Sign up',
      subHeader: title,
      message: title,
      buttons: ['OK']
    });

    await alert.present();
  }

  async  showLoginErrorAlert(title){
    const alert = await this.alertCtrl.create({
      header: 'User Login',
      subHeader: title,
      message: title,
      buttons: ['OK']
    });

    await alert.present();
  }


  setUserAccountAfterLogin(userID: string){
    this.db.object('customerAccount/' + userID).valueChanges().subscribe(v =>{
      if(v != null){
        var u = v;
        this.userData.setUserAccount(u);
      }
    });

  }

  resetLoginProviderStatus(){
    this.isFacebookLogin = false;
    this.isEmailLogin = false;
    this.isGoogleLogin = false;
    this.isTwitterLogin = false;
  }

  isLogin(){
    if(this.authenticated && this.currentUserAnonymous == false){
      //this is a real login user
      return true;
    }else{
      return false;
    }
  }

}
