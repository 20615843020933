import { Injectable  } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import {map} from "rxjs/operators";
import {GeoFire} from "geofire";


@Injectable({
  providedIn: 'root'
})
export class GeoLocationService {

  geoFire: any;


  constructor(public af:AngularFireDatabase) {

    this.geoFire = new GeoFire(af.database.ref('geoLocation'))
    console.log('geo file link is ', this.geoFire);

  }

  addLocation(locationId, lat, lng){
    this.geoFire.set(locationId, [lat, lng]).then(function() {
      console.log('Create new location for ', locationId, ' -- [', lat, ',',lng,']');
    }, function(error) {
      console.log("Error: " + error);
    });
  }

  cleanupAllLocation(){
    this.af.object('geoLocation').set(null).then(v=>{
      this.geoFire = new GeoFire(this.af.database.ref('geoLocation'))
    });
  }

  getQueryByLocation(queryCriteria){
    return this.geoFire.query(queryCriteria);
  }




}
