import { OwnerMerchantService } from '../owner/merchant-service';
import { Firebase } from '@ionic-native/firebase/ngx';
import { Injectable } from '@angular/core';

import {Platform, ToastController} from "@ionic/angular";


@Injectable()
export class OwnerFcmNotificationService {


  merchantID:any = '';

  constructor(public merchantService: OwnerMerchantService, public firebaseNative: Firebase,
    public toastCtrl: ToastController,    private platform: Platform) {


  }

  initialize(merchantID){
    this.merchantID = merchantID;
  }

  async presentToast(content) {
    const toast = await this.toastCtrl.create({
      message: content,
      duration: 3000,
      position: 'bottom'
    });
    await toast.present();
  }


  async getToken() {

    let token;
    console.log('start retrieve notification token ',this.platform);

    if (this.platform.is('desktop')) {
      token = await this.firebaseNative.getToken()
    }

    if (this.platform.is('android')) {
      token = await this.firebaseNative.getToken()
    }

    if (this.platform.is('ios')) {
      token = await this.firebaseNative.getToken();
      await this.firebaseNative.grantPermission();
    }
    console.log('token is ', token);
    if(token != null && token != undefined){
      return  this.merchantService.registerFCMToken(this.merchantID,token);
    }
  }

  refreshToken(){
    this.firebaseNative.onTokenRefresh().subscribe(token=>{
      return  this.merchantService.registerFCMToken(this.merchantID,token);
    })
  }

  listenToNotifications() {
    return this.firebaseNative.onNotificationOpen().subscribe(notofication=>{
      if(notofication.wasTapped){
          console.log("Received in background ", JSON.stringify(notofication));
      } else {
          console.log("Received in foreground ", JSON.stringify(notofication));
      };
      this.presentToast(JSON.stringify(notofication));
    })
  }

  hasPermission(){
    return this.firebaseNative.hasPermission();
  }

  subscribeTopic(topic){
    return this.firebaseNative.subscribe(topic);
  }

  unSubscribeTopic(topic){
    return this.firebaseNative.unsubscribe(topic);
  }

}
