import {NgModule} from "@angular/core";
import {CalendarModule} from "primeng/calendar";
import {RatingModule} from 'primeng/rating';
import {PanelModule} from 'primeng/panel';
import {TreeModule} from 'primeng/tree';
import {DataViewModule} from 'primeng/dataview';
import {AccordionModule} from 'primeng/accordion';

import {CarouselModule} from 'primeng/carousel';
import {StepsModule} from 'primeng/steps';
import {ToastModule} from 'primeng/toast';

import {GMapModule} from 'primeng/gmap';
import {FieldsetModule} from 'primeng/fieldset';
import {CheckboxModule} from 'primeng/checkbox';
import {RadioButtonModule} from 'primeng/radiobutton';
import {SliderModule} from 'primeng/slider';
import {DropdownModule} from 'primeng/dropdown';
import {ListboxModule} from 'primeng/listbox';
import {InputTextareaModule} from 'primeng/inputtextarea';
import {InputTextModule} from 'primeng/inputtext';
import {CardModule} from 'primeng/card';
import {MultiSelectModule} from 'primeng/multiselect';
import {DialogModule} from 'primeng/dialog';
import {ButtonModule} from 'primeng/button';
import {SplitButtonModule} from 'primeng/splitbutton';
import {MenuModule} from 'primeng/menu';
import {OverlayPanelModule} from 'primeng/overlaypanel';
import {TableModule} from 'primeng/table';
import {FileUploadModule} from "primeng/fileupload";
import {DividerModule} from 'primeng/divider';
import { TagModule } from 'primeng/tag';
import {InputNumberModule} from 'primeng/inputnumber';
import {AvatarModule} from 'primeng/avatar';
import {AvatarGroupModule} from 'primeng/avatargroup';
import {AutoCompleteModule} from "primeng/autocomplete";
import {KeyFilterModule} from 'primeng/keyfilter';
import {InputSwitchModule} from 'primeng/inputswitch';
import {ScrollTopModule} from 'primeng/scrolltop';

@NgModule({
  exports: [
    CalendarModule,
    RatingModule,
    PanelModule,
    TreeModule,
    DataViewModule,
    CarouselModule,
    AccordionModule,
    StepsModule,
    ToastModule,
    GMapModule,
    FileUploadModule,
    FieldsetModule,
    CheckboxModule,
    RadioButtonModule,
    SliderModule,
    DropdownModule,
    ListboxModule,
    InputTextareaModule,
    InputTextModule,
    CardModule,
    MultiSelectModule,
    DialogModule,
    ButtonModule,
    SplitButtonModule,
    MenuModule,
    OverlayPanelModule,
    TableModule,
    DividerModule,
    TagModule,
    InputNumberModule,
    AvatarModule,
    AvatarGroupModule,
    AutoCompleteModule,
    KeyFilterModule,
    InputSwitchModule,
    ScrollTopModule
  ]
})
export class PrimeNGModule {
}
