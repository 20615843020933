import { Component, OnInit } from '@angular/core';
import {AlertController, ModalController, NavParams} from "@ionic/angular";
import {OwnerMerchantService} from "../../../services/owner/merchant-service";
import {ShareDataService} from "../../../services/shareData-service";

@Component({
  selector: 'app-admin-view-schedule',
  templateUrl: './admin-view-schedule.component.html',
  styleUrls: ['./admin-view-schedule.component.scss'],
})
export class AdminViewScheduleComponent implements OnInit {
  merchant:any;
  location:any;
  merchantLocation: any[] = [];
  dailyScheduleMap: Map<string, Map<String, any>> = new Map<string, Map<String,any>>();
  regularScheduleMap: Map<string, Map<String, any>> = new Map<string, Map<String,any>>();

  weekday = new Array(7);
  scheduleList:any = [];


  constructor(public navParams: NavParams, public modalCtrl: ModalController, private merchantService: OwnerMerchantService,
              public shareDataService: ShareDataService,public alertCtrl: AlertController) { }

  ngOnInit() {}

  dismiss() {
    this.modalCtrl.dismiss();
  }

  ionViewWillEnter() {


    this.merchant = this.navParams.get("merchant");
    this.location = this.navParams.get("location");

    this.loadMerchantSchedules(this.merchant.id,this.location.id,this.location.locationName,this.location.locationShortName);
  }

  loadMerchantSchedules(merchantId,locationId,locationName,locationShortName){
    let dailyScheduleSub = this.merchantService.getMerchantSchedule(merchantId, locationId).subscribe(v => {
      dailyScheduleSub.unsubscribe();
      if (v != null) {
        this.scheduleList = v;
        this.scheduleList.sort(function (a, b) {
          return (a.year + a.month + a.day).toString().localeCompare((b.year + b.month + b.day).toString())
        });
        for (let schedule of this.scheduleList) {
          let key = schedule.locationDetail.dateKey;
          let subKey = locationId+'-'+schedule.locationDetail.acStartTime
          let result = {id:locationId,locationName: locationName,locationShortName:locationShortName,scheduleLocationDetail:schedule.locationDetail}
          let scheduleResult :Map<String,any>  = new Map<String,any>();
          scheduleResult.set(subKey,result);
          this.dailyScheduleMap.set(key, scheduleResult);
        }
        console.log('daily schedule map is ', this.dailyScheduleMap);
      }
    });
    for(let day = 0; day<7;day++){
      const s = this.merchantService.getMerchantRegularSchedule(merchantId, locationId, this.weekday[day]).subscribe(v => {
        s.unsubscribe();
        if (v != null) {
          console.log('schedule is ', v);
          let list:any = v;
          for (let schedule of list) {
            let key = schedule.locationDetail.dateKey;
            let subKey = locationId+'-'+schedule.locationDetail.acStartTime
            let result = {id:locationId,locationName: locationName,locationShortName:locationShortName, scheduleLocationDetail:schedule.locationDetail}
            let scheduleResult :Map<String,any>  = new Map<String,any>();
            scheduleResult.set(subKey,result);
            this.regularScheduleMap.set(key, scheduleResult);
          }
        }
      });
    }
    console.log('regular schedule map is ', this.dailyScheduleMap);
  }




  checkScheduleDay(date) {
    let year = '' + date.year;
    let month = date.month + 1 > 9 ? '' + (date.month + 1) : '0' + (date.month + 1);
    let day = date.day > 9 ? '' + date.day : '0' + date.day;
    let d :Date = new Date(date.year,date.month + 1,date.day);

    let weekDay = this.weekday[d.getDay()];
    return this.dailyScheduleMap.has(year + '-' + month + '-' + day) || this.regularScheduleMap.has(weekDay) ;
  }

  selectDate(event) {
    let selectDate: Date = event;
    let year = selectDate.getFullYear();
    let month = (selectDate.getMonth() + 1) > 9 ? '' + (selectDate.getMonth() + 1) : '0' + (selectDate.getMonth() + 1);
    let day = selectDate.getDate() > 9 ? '' + selectDate.getDate() : '0' + selectDate.getDate();
    let d :Date = new Date(selectDate.getFullYear(),selectDate.getMonth() + 1,selectDate.getDate());
    let weekDay = this.weekday[d.getDay()];

    let key = year + '-' + month + '-' + day;
    this.merchantLocation = [];
    if(this.dailyScheduleMap.has(key)){
      for (const item of this.getValues(this.dailyScheduleMap.get(key))) {
        let result = item;
        let location = {locationName:result.locationName, currentLocation: result.scheduleLocationDetail,sharedInfo: this.merchant.sharedInfo,id:result.id,locationShortName:result.locationShortName}
        this.merchantLocation.push(location);
      }
    }
    if(this.regularScheduleMap.has(weekDay)){
      for (const item of this.getValues(this.regularScheduleMap.get(weekDay))) {
        let result = item;
        result.scheduleLocationDetail.eventYear = year;
        result.scheduleLocationDetail.eventMonth = month;
        result.scheduleLocationDetail.eventDay = day;
        let location = {locationName:result.locationName, currentLocation: result.scheduleLocationDetail,sharedInfo: this.merchant.sharedInfo,id:result.id,locationShortName:result.locationShortName}
        this.merchantLocation.push(location);
      }
    }
    console.log(this.merchantLocation);
  }


  getValues(map): any {
    return Array.from(map.values());
  }

}
