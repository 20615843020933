import { Component, OnInit } from '@angular/core';
import { NavParams } from '@ionic/angular';
import { FormControl } from '@angular/forms';
import { ModalController ,AlertController  } from '@ionic/angular';


import { UserData } from '../../services/user-data';
import { MerchantData } from '../../services/merchant-data';

import {SelectItem} from 'primeng/api';


@Component({
  selector: 'app-merchant-filter',
  templateUrl: './merchant-filter.component.html',
  styleUrls: ['./merchant-filter.component.scss'],
})
export class MerchantFilterComponent implements OnInit {

    merchantDetail: any;


    merchant: any;

    searchControl: FormControl;
    searching: any = false;


    filterMerchantList: any[] = [];
    fullMerchantList: any[] = [];
    locationTagMap : Map<string,[any]> = new Map<string, [any]>();
    merchantTagMap : Map<string,{}> = new Map<string, {}>();
    tagList: any[] = [];

    searchCriteria = {distance:5, star:0, openNow: false, todaySchedule:false,  ownerClaimed:false, favorite: false, orderby:'distance',tagSet: null};
    viewSelection = 'filter';
    selectedTag: Set<string> = new Set<string>();

    distanceList: SelectItem[];
    tagSelection: SelectItem[] = [];
    selectedTags;


    constructor(public dataProvider: MerchantData, public userData: UserData, public modalController: ModalController ,
                public navParams: NavParams) {
        this.searchControl = new FormControl();
        this.distanceList = [
            {label:'1 Miles', value:1},
            {label:'2 Miles', value:2},
            {label:'5 Miles', value:5},
            {label:'10 Miles', value:10},
            {label:'15 Miles', value:15},
            {label:'20 Miles', value:20}
        ];
    }

    ngOnInit() {
        this.locationTagMap = new Map<string, [any]>();
        //this.fullMerchantList = this.userData.getAllMerchantLocationList();
        this.fullMerchantList = this.navParams.get("locationList");
        this.searchCriteria = this.navParams.get("searchCriteria");

        for(let merchant of this.fullMerchantList){
            for(let tag of merchant.merchant.sharedInfo.tags){
                if(this.locationTagMap.has(tag)){
                    //add merchant to this tag location list
                    this.locationTagMap.get(tag).push({key:merchant.key,location:merchant.location,distance:merchant.distance,merchant:merchant.merchant});
                    let item:any = this.merchantTagMap.get(tag);
                    item.count++;
                    this.merchantTagMap.set(tag,item);
                }else{
                    //this is a new tag
                    this.locationTagMap.set(tag,[{key:merchant.key,location:merchant.location,distance:merchant.distance,merchant:merchant.merchant}]);
                    this.merchantTagMap.set(tag,{count:1,selected:false,icon:"square-outline"});
                }
            }
        }
        let keyList:any[] = this.getKeys(this.merchantTagMap);
        keyList = keyList.sort();
        for(let key of keyList)
        {
            let item:any = this.merchantTagMap.get(key)
            this.tagList.push({tag:key,count:item.count,selected:false,icon:"square-outline"})
            this.tagSelection.push({label:key+ ' - ( ' + item.count + ' )',value:key});
        }
        console.log(this.tagList, this.tagSelection);



    }



    selectTag(tag){
        //let data = {selectedMerchantList: this.locationTagMap.get(tag),selectMerchant: null,filter:tag};
        //this.viewCtrl.dismiss(data);
        if(tag.selected === true){
            tag.selected = false;
            tag.icon = "square-outline";
            this.selectedTag.delete(tag.tag);
        }else{
            tag.selected = true;
            tag.icon = "checkbox";
            this.selectedTag.add(tag.tag);
        }
    }



    dismiss() {
        this.modalController.dismiss();
    }

    getKeys(map){
        return Array.from(map.keys());
    }

    getcheckIcon(selected): string{
        if(selected === true)
            return "checkbox"
        else
            return "square-outline";
    }

    applySelection(){
        console.log('the current search searchCriteria is ', this.searchCriteria, 'selected tag is ', this.selectedTag, this.tagSelection);
        if(this.selectedTags != null && this.selectedTags.length > 0){
            this.searchCriteria.tagSet = this.selectedTags;
        }
        console.log('Will return the following search criteria ', this.searchCriteria);
        this.modalController.dismiss({searchCriteria:this.searchCriteria,reset:false});
    }

    resetSelection(){
        this.searchCriteria = {distance:5, star:0, openNow: false, todaySchedule:false, ownerClaimed:false, favorite: false, orderby:'distance',tagSet: null};
        this.modalController.dismiss({searchCriteria:this.searchCriteria,reset:true});
    }



}
