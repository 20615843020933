import { Component, OnInit } from '@angular/core';
import {MerchantService} from "../../services/merchant-service";
import {UserData} from "../../services/user-data";
import {ModalController, NavParams} from "@ionic/angular";
import {AuthService} from "../../services/auth-service";
import {AuthenticationService} from "../../services/authentication-service";

@Component({
  selector: 'app-merchant-review-add',
  templateUrl: './merchant-review-add.component.html',
  styleUrls: ['./merchant-review-add.component.scss'],
})
export class MerchantReviewAddComponent implements OnInit {


  reviewContent : string = '';
  rating: any = 0;
  userID:any;
  merchantID: any = '';
  merchant: any;


  constructor(public merchantService : MerchantService,public userData: UserData,public navParams: NavParams,public modalCtrl: ModalController,
              public authService: AuthenticationService) {

  }

  ionViewWillEnter() {
    let userAccount :any = this.userData.getUserAccount();
    this.merchant = this.navParams.get("merchant");
    this.merchantID = this.merchant.id;

    console.log(' user account is ', userAccount, ' merchant is ', this );
    this.userID = this.authService.currentUserId;

  }

  ngOnInit() {

  }

  dismiss() {
    this.modalCtrl.dismiss();
  }

  save() {
    console.log(this.merchantID, '-', this.reviewContent, '-', this.rating, '-', this.userID)
    this.merchantService.createMerchantReview(this.merchantID, this.reviewContent, this.rating, this.userID).then(v => {
      this.dismiss();
    })
  }

}
