import { Component, OnInit } from '@angular/core';
import {ModalController, NavParams} from "@ionic/angular";
import {UserAccountService} from "../../services/user-account-service";
import {AuthService} from "../../services/auth-service";
import {UserData} from "../../services/user-data";

@Component({
  selector: 'app-order-history-detail',
  templateUrl: './order-history-detail.component.html',
  styleUrls: ['./order-history-detail.component.scss'],
})
export class OrderHistoryDetailComponent implements OnInit {

  orderDetail: any;
  elementType : 'url' | 'canvas' | 'img' = 'url';


  constructor(public userData: UserData,
              public navParams: NavParams, public modalCtrl: ModalController,
              public orderHistoryService: UserAccountService,
              public authService : AuthService){
    this.orderDetail = this.navParams.get("orderDetail");
    console.log('the order detail is', this.orderDetail);
  }

  createQRString(){
    var s = {
      orderID: this.orderDetail.key,
      merchantID: this.orderDetail.merchantId,
      merchantLocationID: this.orderDetail.merchantLocationId,
      customerID: this.authService.currentUserId
    };
    return JSON.stringify(s);
  }

  hasOptionalSelections(optionalSelections: any){
    if(optionalSelections === undefined || optionalSelections.trim() === "")
      return false;
    else
      return true;
  }

  hasContent(content: any){
    if(content === undefined || content.trim() === "")
      return false;
    else
      return true;
  }

  dismiss() {
    this.modalCtrl.dismiss();
  }

  ngOnInit(): void {
  }

}
