import { UserAccountService } from '../services/user-account-service';
import { Platform } from '@ionic/angular';
import { Firebase } from '@ionic-native/firebase/ngx';
import { Injectable } from '@angular/core';

import { ToastController } from '@ionic/angular';


@Injectable({
  providedIn: 'root'
})
export class FcmNotificationService {


  customerID:any = '';

  constructor(public userAccountService: UserAccountService, public firebaseNative: Firebase,
    public toastCtrl: ToastController,    private platform: Platform) {


  }

  initialize(customerID){
    this.customerID = customerID;
    /*
    this.fcm.subscribeToTopic('merchantID');

    this.fcm.getToken().then(token => {
      console.log('push message token is ', token);
      if(token != null){
        this.merchantService.registerFCMToken(merchantID,token);
      }
    });

    this.fcm.onNotification().subscribe(data => {
      if(data.wasTapped){
          console.log("Received in background ", JSON.stringify(data));
      } else {
          console.log("Received in foreground ", JSON.stringify(data));
      };
      this.presentToast(JSON.stringify(data));
    });

    this.fcm.onTokenRefresh().subscribe(token => {
      this.merchantService.registerFCMToken(merchantID,token);
    });
    */
  }

  async presentToast(content) {
    const toast = await this.toastCtrl.create({
      message: content,
      duration: 3000,
      position: 'bottom'
    });
    toast.present();
  }


  async getToken() {

    let token;
    console.log('start retrieve notification token ',this.platform);

    if (this.platform.is('desktop')) {
      token = await this.firebaseNative.getToken()
    }

    if (this.platform.is('android')) {
      token = await this.firebaseNative.getToken()
    }

    if (this.platform.is('ios')) {
      token = await this.firebaseNative.getToken();
      await this.firebaseNative.grantPermission();
    }
    console.log('token is ', token);
    if(token != null && token != undefined){
      return  this.userAccountService.registerCustomerFCMToken(this.customerID,token);
    }
  }

  refreshToken(){
    this.firebaseNative.onTokenRefresh().subscribe(token=>{
      return  this.userAccountService.registerCustomerFCMToken(this.customerID,token);
    })
  }

  listenToNotifications() {
    return this.firebaseNative.onNotificationOpen().subscribe(notofication=>{
      if(notofication.wasTapped){
          console.log("Received in background ", JSON.stringify(notofication));
      } else {
          console.log("Received in foreground ", JSON.stringify(notofication));
      };
      this.presentToast(JSON.stringify(notofication));
    })
  }

  hasPermission(){
    return this.firebaseNative.hasPermission();
  }

  subscribeTopic(topic){
    return this.firebaseNative.subscribe(topic);
  }

  unSubscribeTopic(topic){
    return this.firebaseNative.unsubscribe(topic);
  }

}
