import { Component, OnInit } from '@angular/core';
import {UserData} from "../../services/user-data";
import {ModalController, NavParams} from "@ionic/angular";

@Component({
  selector: 'app-order-submission-summary',
  templateUrl: './order-submission-summary.component.html',
  styleUrls: ['./order-submission-summary.component.scss'],
})
export class OrderSubmissionSummaryComponent {

  merchant: any;
  submittedOrder: any;

  constructor(public userData: UserData,
              public navParams: NavParams,public modalCtrl: ModalController
              ){


  }


  ionViewWillEnter() {
    this.submittedOrder = this.navParams.get("submittedOrder");
    this.merchant = this.navParams.get("merchant")
  }

  hasOptionalSelections(optionalSelections: any){
    if(optionalSelections == "")
      return false;
    else
      return true;
  }


  dismiss() {
    this.modalCtrl.dismiss();
  }

}
