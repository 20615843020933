import { Component, OnInit } from '@angular/core';
import {ModalController, NavParams} from "@ionic/angular";
import {OwnerUserDataService} from "../../../services/owner/user-data-service";
import {BaseMenuItem, MenuItem, OptionalMenuItem} from "../../../services/owner/merchant-menu-service";


export interface OrderItem {
  orderItemId?: number;
  //merchantId?: string;
  //merchantName?: string;
  merchant?: any;
  orderdescription?: string;
  optionalSelections?: any[];
  menuitem?: any;
  quantity?: number;
  totalPrice?: number;
  comment?: string;
};


@Component({
  selector: 'app-merchant-menu-item',
  templateUrl: './merchant-menu-item.component.html',
  styleUrls: ['./merchant-menu-item.component.scss'],
})
export class MerchantMenuItemComponent implements OnInit   {

  menuitem: MenuItem;
  totalPrice: any;
  quantity: any;
  orderItem: OrderItem;
  orderItemId: number;
  comment: string;
  //merchantId: string;
  //merchantName: string;
  //optionalMenudetail: OptionalMenuItem;
  allowSubmit: boolean = false;
  merchant: any;



  constructor(public navParams: NavParams,public modalCtrl: ModalController,
              public userData: OwnerUserDataService
  ) {


  }

  ngOnInit(){
    this.menuitem = this.navParams.get("menuItem");
    this.quantity = this.navParams.get("quantity");
    this.orderItemId = this.navParams.get("orderItemId");
    this.comment = this.navParams.get("comment");
    this.recalculatePrice();
    this.orderItem = {};
  }

  dismiss() {
    this.modalCtrl.dismiss();
  }

  changeQuantity(i: number){
    if((this.quantity + i) > 0 && (this.quantity + i) < 10){
      this.quantity = this.quantity + i;
    }
    this.recalculatePrice();
  }

  updateSelection(menuSelected: BaseMenuItem, parentMenu:OptionalMenuItem ){
    if(menuSelected.selected == true){
      //we need to check if max slection is over
      var selected = 1;
      for(var i = parentMenu.selections.length - 1;i>=0;i--){
        var item = parentMenu.selections[i];
        if(item.name != menuSelected.name){
          if(item.selected == true){
            if(selected == parentMenu.maxSelection){
              item.selected = false;
            }else{
              selected++;
            }
          }
        }
      }
    }
    this.recalculatePrice();

  }


  recalculatePrice(){
    var additionalPrice : number = 0;
    if(this.hasRequiredSelection){
      this.allowSubmit = true;
      for(var requiredMenuItem of this.menuitem.requiredMenuItems){
        var selectCount = 0;
        for(var menu of requiredMenuItem.selections){
          if(menu.selected ===true){
            additionalPrice = additionalPrice + menu.price;
            selectCount++;
          }
        }
        if(selectCount < requiredMenuItem.requiredSelection){
          this.allowSubmit = false;
        }
      }
    }else{
      this.allowSubmit = true;
    }
    if(this.hasOptionalSelection){
      for(var optionalMenuItem of this.menuitem.optionalMenuItems){
        for(var menu of optionalMenuItem.selections){
          if(menu.selected == true)
            additionalPrice = additionalPrice + menu.price;
        }
      }
    }
    this.totalPrice = this.quantity * (this.menuitem.primaryMenuItem.price + additionalPrice);

  }


  addToCart(){
    //this.orderItem.merchantId = this.merchantId;
    this.orderItem.menuitem = this.menuitem;
    this.orderItem.quantity = this.quantity;
    this.orderItem.totalPrice = this.totalPrice;
    this.orderItem.orderdescription = this.menuitem.primaryMenuItem.name;
    //this.orderItem.merchantName = this.merchantName;
    this.orderItem.merchant = this.merchant;
    this.orderItem.orderItemId = this.orderItemId;
    this.orderItem.optionalSelections = [];
    if(this.hasRequiredSelection){
      for(var requiredItem of this.menuitem.requiredMenuItems){
        for(var menu of requiredItem.selections){
          if(menu.selected == true){
            this.orderItem.optionalSelections.push(menu.name);
          }
        }
      }
    }
    if(this.hasOptionalSelection){
      for(var optionalItem of this.menuitem.optionalMenuItems){
        for(var menu of optionalItem.selections){
          if(menu.selected == true){
            this.orderItem.optionalSelections.push(menu.name);
          }
        }
      }
    }
    this.orderItem.comment = this.comment;
    this.userData.addToCart(this.orderItem);
    console.log('Add order to cart: ', this.orderItem);
    this.dismiss();
  }


  hasRequiredSelection(){
    if(this.menuitem.requiredMenuItems != null && this.menuitem.requiredMenuItems.length > 0)
      return true;
    else
      return false;
  }

  hasOptionalSelection(){
    if(this.menuitem.optionalMenuItems != null && this.menuitem.optionalMenuItems.length > 0)
      return true;
    else
      return false;
  }

  getRequiredSelection(requiredMenuItem){
    let selItem = '';
    let count = 0;
    for(let menu of requiredMenuItem.selections){
      if(menu.selected == true){
        if(count == 0){
          selItem = selItem + menu.name;
        }else{
          selItem = selItem + ',' + menu.name;
        }
        count++;
      }
    }
    if(count == 0){
      if(requiredMenuItem.requiredSelection > 0 && (requiredMenuItem.maxSelection == 0 || requiredMenuItem.maxSelection == requiredMenuItem.requiredSelection)){
        return requiredMenuItem.name + ', must choose ' + requiredMenuItem.requiredSelection + ' option(s)';
      }else if(requiredMenuItem.requiredSelection > 0 && requiredMenuItem.maxSelection > 0){
        return requiredMenuItem.name + ', choose ' + requiredMenuItem.requiredSelection + ' to '+ requiredMenuItem.maxSelection+' option(s)';
      }else if(requiredMenuItem.requiredSelection == 0 && requiredMenuItem.maxSelection > 0){
        return requiredMenuItem.name + ', choose up to '+ requiredMenuItem.maxSelection+' option(s)';
      }else{
        return requiredMenuItem.name;
      }
    }else if(count<requiredMenuItem.requiredSelection){
      return 'Selected item: ' + selItem + ' , choose '+ (requiredMenuItem.requiredSelection-count) + ' more option(s)';
    }else{
      return 'Selected item: ' + selItem;
    }
  }


}
