import { Injectable  } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import {map} from "rxjs/operators";


@Injectable({
  providedIn: 'root'
})
export class ReferenceDataService {




  constructor(public af:AngularFireDatabase) {

  }




  getPopularLocationList():any {
    return this.af.list('referencedata/popularlocation').valueChanges();
  }

  getRegionList(): any{
    //return this.af.list('referencedata/regionList').valueChanges();
    return this.af.list('referencedata/regionList').snapshotChanges().pipe(map(values => {
      return values.map(c => ({ key: c.payload.key, ...c.payload.val() as {} }));
    }));
  }

  updateRegionList(list){
    return this.af.object('referencedata/regionList').set(list);
  }

  updateAdminSchedule(){
    let d:Date = new Date();
    console.log('will update with value ', d);
    return this.af.object('referencedata/admin/scedule').set(' '+d);

  }


  getHomeRegionList(){
      return this.af.list('referencedata/regionList', ref => ref.orderByChild('homeRegion').equalTo(true)).snapshotChanges().pipe(map(values => {
        return values.map(c => {
          return {key: c.payload.key, ...c.payload.val() as {}};
        });
      }));
  }

  getHomeRegion(region){
    return this.af.list('referencedata/regionList', ref => ref.orderByChild('shortName').equalTo(region)).snapshotChanges().pipe(map(values => {
      return values.map(c => {
        return {key: c.payload.key, ...c.payload.val() as {}};
      });
    }));
  }

  UpdateLatestMerchantID(id){
    return this.af.object('referencedata/latestID/latestMerchantID').set(id);
  }


}
