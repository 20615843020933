import { Component, OnInit } from '@angular/core';
import {AlertController, ModalController, NavParams} from '@ionic/angular';
import {OwnerMerchantService} from '../../../services/owner/merchant-service';

@Component({
  selector: 'app-admin-modify-merchant-location',
  templateUrl: './admin-modify-merchant-location.component.html',
  styleUrls: ['./admin-modify-merchant-location.component.scss'],
})
export class AdminModifyMerchantLocationComponent implements OnInit {

  merchantId: any;
  merchantLocation: any = {id: '', locationName: '', merchantID: '',
    processTime: '10', sharedInfo: {}, status: 'Active', type: 'mobile', currentLocation: {}};
  sharedInfo: any;
  infoSubmitted: boolean = false;
  newLocation: boolean = false;

  constructor(public navParams: NavParams, public modalCtrl: ModalController,  private merchantService: OwnerMerchantService,
              public alertCtrl: AlertController) { }

  ngOnInit() {
    this.merchantId = this.navParams.get('merchantId');
    this.sharedInfo = this.navParams.get('sharedInfo');
    if (this.navParams.get('merchantLocation') === undefined || this.navParams.get('merchantLocation') === null) {
      // this is to create a new location
      this.merchantLocation = {id: '', locationName: '', merchantID: '',
        processTime: '10', sharedInfo: {}, status: 'Active', type: 'mobile', currentLocation: {}};
      this.merchantLocation.sharedInfo = this.sharedInfo;
      this.merchantLocation.merchantID = this.merchantId;
      this.newLocation = true;

    } else {
      this.merchantLocation = this.navParams.get('merchantLocation');
    }
  }

  saveLocation() {
    this.infoSubmitted = true;
    if(this.newLocation == true){
      //sae new location to staging table
      this.merchantService.createNewMerchantLocation(this.merchantLocation).then(value => {
        this.dismiss();
      })
    }else{
      this.merchantService.updateMerchantLocation(this.merchantLocation);
      this.dismiss();
    }
  }

  removeLocation(location) {

  }

  getBriefAddress(address){
    if(address != null){
      let a = address.split(',');
      return a[0];
    }
    else
      return '';
  }


  getStartTime(locationDetail){

    let t = locationDetail.acStartTime.split(':');
    let d;
    if(t.length === 3)
      d= new Date(locationDetail.eventYear,parseInt(locationDetail.eventMonth)-1,locationDetail.eventDay,t[0],t[1],t[2],0);
    else
      d= new Date(locationDetail.eventYear,parseInt(locationDetail.eventMonth)-1,locationDetail.eventDay,t[0],t[1],0,0);
    return d;
  }

  getEndTime(locationDetail){
    let t = locationDetail.acEndTime.split(':');
    let d;
    if(t.length === 3)
      d= new Date(locationDetail.eventYear,parseInt(locationDetail.eventMonth)-1,locationDetail.eventDay,t[0],t[1],t[2],0);
    else
      d= new Date(locationDetail.eventYear,parseInt(locationDetail.eventMonth)-1,locationDetail.eventDay,t[0],t[1],0,0);
    return d;
  }


  dismiss() {
    this.modalCtrl.dismiss();
  }
}
