import { Injectable  } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import {map} from "rxjs/operators";

import { AuthService } from '../services/auth-service';
import {BehaviorSubject, Observable} from "rxjs";
import {AuthenticationService} from "./authentication-service";


export class UserAccount {
  public userName : string;
  public emailAddress : string;
  public fullName : string;
  public phoneNumber : string;
  public isGoogleLogin : boolean = false;
  public isFacebookLogin : boolean = false;
  public isEmailLogin : boolean = true;
  public isTwitterLogin : boolean = false;
  public userAccountType : string = 'ANONYMOUS';
  public AssociatedMerchantID : string;
  public lastLogin : string;
  public lastUpdate: string;
  public lastUpdateBy: string;

  constructor(){

  }
}


@Injectable({
  providedIn: 'root'
})
export class UserAccountService {

  orderList : Observable<any[]>;
  currentUserAccount : BehaviorSubject<any>;

  constructor(private db: AngularFireDatabase,
              private authService : AuthenticationService
            ) {

  }

  getUserAccountDetail() : Observable<UserAccount> {
    let userAccount : UserAccount = new UserAccount();

    const s = this.db.object('userAccount/'+this.authService.currentUserId).valueChanges().subscribe(v=>{
      s.unsubscribe();
      if( v != null){
        const user:any = v;
        userAccount.userName = user.userName;
        userAccount.emailAddress = user.emailAddress;
        userAccount.fullName = user.fullName;
        userAccount.phoneNumber = user.phoneNumber;
        userAccount.isGoogleLogin = user.isGoogleLogin;
        userAccount.isFacebookLogin = user.isFacebookLogin;
        userAccount.isEmailLogin = user.isEmailLogin;
        userAccount.isTwitterLogin = user.isTwitterLogin;
        userAccount.userAccountType = user.userAccountType;
        userAccount.AssociatedMerchantID = user.AssociatedMerchantID;
        userAccount.lastLogin = user.lastLogin;
        userAccount.lastUpdate = user.lastUpdate;
        userAccount.lastUpdateBy = user.lastUpdateBy;
      }else{
        userAccount.userAccountType = 'ANONYMOUS';
      }
      this.currentUserAccount.next(userAccount);
    });
    return this.currentUserAccount;
  }



  getCustomerOrderHistory(currentUserId){
    var userId = currentUserId;//'kZFV2pGv0CV33GLF9Jv17w47O1A3';//this.authService.currentUserId;
    var orderPath = 'customerOrderTrack/' + userId;
    console.log('order history path is ', orderPath);
    this.orderList = this.db.list(orderPath).snapshotChanges();
    return this.orderList;
  }

  getMerchantOrderDetail(merchantID: string,merchantLocationID: string, orderId: string){
    var orderPath = 'merchantOrder/' + merchantID + '/' + merchantLocationID + '/' + orderId;
    return this.db.object(orderPath).snapshotChanges().pipe(map(c => ({ key: c.payload.key, ...c.payload.val() as {}})));
  }

  getUserFavoriteList(currentUserId){
    var favoritePath = 'userFavorite/'+currentUserId;
    console.log('get favorite list path is ',favoritePath)
    return this.db.object(favoritePath).valueChanges();
  }

  setUserFavoriteList(favoriteList,currentUserId){
    console.log('save favorite list ', favoriteList, ' path ', 'userFavorite/'+currentUserId);
    return this.db.object('userFavorite/'+currentUserId).set(favoriteList);
  }

  getUserAccount(currentUserId){
    console.log('searching user account for ', 'userAccount/'+currentUserId);
    return this.db.object('userAccount/'+currentUserId).valueChanges();
  }

  getUserAccountWithId(userId){
    console.log('searching user account for ', 'userAccount/'+userId);
    return this.db.object('userAccount/'+userId).valueChanges();
  }

  updateUserProfile(userID, profile){
    return this.db.object(`userAccount/${userID}/`).set(profile);
  }

  registerCustomerFCMToken(customer, token){
    return this.db.object(`customerNotification/${customer}/`).set(token);
  }

  getUserAccountByEmail(email){

    return this.db.list('userAccount',ref => ref.orderByChild('emailAddress').equalTo(email)).snapshotChanges().pipe(map(values => {
      return values.map(c => ({ key: c.payload.key, ...c.payload.val() as {} }));
    }));
  }

  createUserAccount(email:string, name:string,userID:string){
    console.log(`create user account ${email} , ${name}`)
    let path = `userAccount/${userID}`; // Endpoint on firebase
    console.log(`will create user account at ${path}`);
    let data = {
      accountType: 'CUSTOMER',
      emailAddress: email,
      fullName: name,
      phoneNumber: '',
      facebookAccount: false,
      googleAccount: false,
      twitterAccount:false,
      emailAccount: true,
      userId: userID,
      status: 'Active'
    };
    return new Promise((resolve, reject) => {
      this.db.object(path).set(data)
          .then(  v=>  {
                console.log(`user created for  ${JSON.stringify(data)} -- result: ${JSON.stringify(v)}`);
                resolve('success');
              }
          )
          .catch(error => {
            console.log(error);
            reject(error);
          });
    });


  }


}
