import { Component, OnInit } from '@angular/core';
import {OwnerMerchantService} from "../../../services/owner/merchant-service";
import {OwnerReferenceDataService} from "../../../services/owner/reference-data-service";
import {ModalController} from "@ionic/angular";

@Component({
  selector: 'app-admin-create-new-merchant',
  templateUrl: './admin-create-new-merchant.component.html',
  styleUrls: ['./admin-create-new-merchant.component.scss'],
})
export class AdminCreateNewMerchantComponent implements OnInit {

  defaultLogo:any = 'https://firebasestorage.googleapis.com/v0/b/gulpmealdev.appspot.com/o/default_truck.png?alt=media&token=65f2c97a-79bd-4687-9cde-ff863f596f2e'
  newMerchant:any = {
    contactInfo:{
      primaryEmail:'',
      primaryPhoneNumber:''
    },
    merchantID:'',
    sharedInfo:{
      name:'',
      logPicture:this.defaultLogo,
      region:'',
      tags:[],
      disabled:'No',
      merchantStatus:'New',
      shortDescription:'',
      review_summary:{
        rating:0,
        numberOfReview:0
      }
    },
    socialLinks:{
      website:'',
      facebookLink:'',
      tweetName:''
    },
    description:'',
    sourceInfo:{locationSourceType:'manual'}
  };
  regionList:any = [];
  merchantTags: any = [];
  primarySubmitted = false;

  constructor(public refdataService: OwnerReferenceDataService,private merchantService: OwnerMerchantService,public modalCtrl: ModalController) { }

  ngOnInit() {

    let regionListSub = this.refdataService.getRegionList().subscribe(v => {
      if (v != null) {
        regionListSub.unsubscribe();
        this.regionList = v;
      }
    });
    let s = this.refdataService.getTagList().subscribe(v => {
      s.unsubscribe();
      if (v != null) {
        this.merchantTags = v;
      }
    });
  }

  savePrimary(){
    console.log(this.newMerchant)
    this.merchantService.createNewMerchant(this.newMerchant);
    this.dismiss();
  }

  dismiss(){
    this.modalCtrl.dismiss();
  }

}
