import {Component} from '@angular/core';
import {AlertController, ModalController, NavParams} from "@ionic/angular";
import {OwnerMerchantService} from "../../../services/owner/merchant-service";
import {OwnerReferenceDataService} from "../../../services/owner/reference-data-service";
import {NgForm} from "@angular/forms";
import {UserAccountService} from "../../../services/user-account-service";
import {AuthenticationService} from "../../../services/authentication-service";

@Component({
    selector: 'app-merchant-modify-accounts',
    templateUrl: './merchant-modify-accounts.component.html',
    styleUrls: ['./merchant-modify-accounts.component.scss'],
})
export class MerchantModifyAccountsComponent {


    merchantID: any;
    merchantAccount: any = null;
    primaryInfo: any = {key:'', name: '', email: '', role: 'Member'}
    primarySubmitted = false;
    accountID: any = '';
    newAccount : any = true;
    roleList: any = [];

    constructor(
        public navParams: NavParams, private merchantService: OwnerMerchantService,
        public refDataService: OwnerReferenceDataService, public authenService: AuthenticationService,
        public alertCtrl: AlertController, public modalCtrl: ModalController, public userAccountService: UserAccountService) {
        this.merchantID = navParams.get("merchantID");
        this.merchantAccount = navParams.get("account");
        if (this.merchantAccount != null) {
            this.primaryInfo = {
                name: this.merchantAccount.value.fullName,
                email: this.merchantAccount.value.email,
                role: this.merchantAccount.value.role,
                phone: this.merchantAccount.value.phoneNumber
            }
            this.newAccount = false;
        } else {
            this.merchantAccount = {key: null, value: {}}
        }
        this.roleList.push({label: 'Owner Account', value: 'Owner'});
        this.roleList.push({label: 'Member Account', value: 'Member'});
    }


    savePrimary(form: NgForm) {
        this.primarySubmitted = true;

        if (form.valid) {
            console.log('Save primary information ', this.primaryInfo);
            this.merchantAccount.value.fullName = this.primaryInfo.name;
            this.merchantAccount.value.email = this.primaryInfo.email;
            this.merchantAccount.value.role = this.primaryInfo.role;
            this.merchantAccount.value.phoneNumber = this.primaryInfo.phone;
            console.log('will create/update merchant account ', this.merchantAccount)
            if (this.merchantAccount.key === null) {
                //this is a new merchant account
                const newAccountRequest: any = {
                    email: this.merchantAccount.value.email,
                    name: this.merchantAccount.value.fullName,
                    phoneNumber: this.merchantAccount.value.phoneNumber,
                    merchantID: this.merchantID,
                    role: this.merchantAccount.value.role,
                    requestBy: ''
                };
                this.merchantService.createNewMerchantAccount(newAccountRequest).then(function (v) {
                    console.log('return value is ', v);
                })
            } else {
                //this is update to existing account
                this.merchantService.updateMerchantAccount(this.merchantAccount);
            }

            this.dismiss();
        }
    }

    dismiss() {
        this.modalCtrl.dismiss();
    }


    async searchUser() {
        if (this.primaryInfo.email === null || this.primaryInfo.email === undefined || this.primaryInfo.email.trim().length === 0) {
            let alert = await this.alertCtrl.create({
                header: 'Empty email',
                message: 'Please provide email address to search!',
                buttons: ['OK']
            });
            alert.present();
        }else{
            let s = this.userAccountService.getUserAccountByEmail(this.primaryInfo.email).subscribe(v => {
                s.unsubscribe();
                if(v != null && v.length>0){
                    console.log('find use account ', v);
                    let userAccount: any = v[0];
                    this.primaryInfo.name = userAccount.fullName;
                    this.primaryInfo.key = userAccount.key;
                    this.primaryInfo.phone = userAccount.phoneNumber;
                    this.accountID = userAccount.key;
                }else{
                    this.primaryInfo.name = '';
                    this.primaryInfo.key = '';
                    this.primaryInfo.phone='';
                }
            });
        }
    }

    saveAccountChange() {
        console.log('Save primary information ', this.primaryInfo);
        this.merchantAccount.value.fullName = this.primaryInfo.name;
        this.merchantAccount.value.email = this.primaryInfo.email;
        this.merchantAccount.value.role = this.primaryInfo.role;
        this.merchantAccount.value.phoneNumber = this.primaryInfo.phone;
        console.log('will create/update merchant account ', this.merchantAccount)
        if (this.newAccount === true) {
            this.merchantAccount.key = this.accountID;
            this.merchantAccount.value.status = 'active';
            this.merchantAccount.value.merchantID = this.merchantID;
            this.merchantAccount.value.createdBy = this.authenService.currentUserId;
        }
        this.merchantService.updateMerchantAccount(this.merchantAccount);
        this.dismiss();
    }
}
