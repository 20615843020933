import { Injectable  } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import 'rxjs/add/operator/map';
import {Subject} from "rxjs";


export class BaseMenuItem {
  constructor(
      public name: string,
      public description: string,
      public price: number,
      public quantity: number,
      public selected: boolean) {
  }
}

export class OptionalMenuItem {
  constructor(
      public id: number,
      public name: string,
      public description: string,
      public selections: BaseMenuItem[],
      public requiredSelection: number,
      public maxSelection: number,
      public totalPrice: number) {
  }
}

export class MenuItem {
  constructor(
      public id: number,
      public name: string,
      public description: string,
      public image: string,
      public quantity: number,
      public totalPrice: number,
      public primaryMenuItem: BaseMenuItem,
      public requiredMenuItems: OptionalMenuItem[],
      public optionalMenuItems: OptionalMenuItem[]) {
  }
}

export class MenuCategory{
  constructor(
    public id: number,
    public name: string,
    public description: string,
    public menuitems: MenuItem[]
  ){}
}

export class MenuType{
  constructor(
    public id: number,
    public name: string,
    public description: string,
    public menuCategories: MenuCategory[]
  ){}
}

export class MerchantMenu{
  constructor(
    public id: number,
    public menuTypes : MenuType[],
    public fullMenuItemList: MenuItem[]
  ){}
}


@Injectable({
  providedIn: 'root'
})
export class MerchantMenuService {

  dbRef: any;
  geoFire: any;
  merchantMenu  = new Subject();

  constructor(public af:AngularFireDatabase) {

    this.dbRef = af.list("merchantLocation");



  }

  getMenuByMerchantKey(key){
    console.log('Look for menu ', 'merchantMenu/' + key);
    this.af.object('merchantMenu/' + key).valueChanges().subscribe(v =>{
      var m = <any>v;
      var mMenu = new MerchantMenu(0,[],[]);
      mMenu.id = key;
      //mMenu.menuTypes = m.menuTypes;
      let iType = 0;
      for(var t of m.menuTypes ){
        //process the menu type
        let tempMenuType = new MenuType(iType,t.name,t.description,[]);
        let iCat = 0;
        for(var cat of t.categories){
          //process the menu category
          if(cat.menuitems != null && cat.menuitems.length >0){
            let tempCategory = new MenuCategory(iCat,cat.name,cat.description,[]);
            //console.log('Category: ', cat.name, ' -- ', cat.menuitems);
            for(var item of cat.menuitems){
              //process the primary menu item
              let menuItemID = item;
              console.log('try to get the menu for ', 'menuitems/' + key + '/' + item);
              this.af.object('menuitems/' + key + '/' + item).valueChanges().subscribe(v1  =>{
                console.log('returned value is ', v1);
                let value1 : any = v1;
                let baseitem = new BaseMenuItem(value1.name,value1.description,value1.price,1,true);
                let menuItem = new MenuItem(menuItemID,value1.name,value1.description,value1.image,1,value1.price,baseitem,[],[]);
                if(value1.optionalItemKey != null && value1.optionalItemKey.length>0){
                  //if there is optional menu items
                  for(var optionalItem of value1.optionalItemKey){
                    //process the optional items
                    this.af.object('optionalMenuItems/' + key + '/'+ optionalItem).valueChanges().subscribe(
                      optV =>{
                        let optValue : any = optV;
                          let optItem = new OptionalMenuItem(optionalItem,optValue.name,optValue.description,[],optValue.requiredSelection,optValue.maxSelection,0);
                          for(var sel of optValue.selections){
                            let selItem = new BaseMenuItem(sel.name,sel.description,sel.price,1,false);
                            optItem.selections.push(selItem);
                          }
                          if(optItem.requiredSelection > 0){
                            menuItem.requiredMenuItems.push(optItem);
                          }else{
                            menuItem.optionalMenuItems.push(optItem);
                          }
                      }
                    );
                  }
                }
                console.log(item, ' menu item is', menuItem);
                tempCategory.menuitems.push(menuItem);
                mMenu.fullMenuItemList.push(menuItem);
              });
            }
            tempMenuType.menuCategories.push(tempCategory);
          }
          iCat++;
        }
        mMenu.menuTypes.push(tempMenuType);
        iType++;
      }
      this.merchantMenu.next(mMenu);
    });
  }

}
