import {Component, OnInit} from '@angular/core';
import {AlertController, ModalController, NavParams} from "@ionic/angular";
import {OwnerMerchantService} from "../../../services/owner/merchant-service";
import {MerchantLocationSearchComponent} from "../merchant-location-search/merchant-location-search.component";
import {BehaviorSubject} from "rxjs";

@Component({
    selector: 'app-merchant-regular-schedule-edit',
    templateUrl: './merchant-regular-schedule-edit.component.html',
    styleUrls: ['./merchant-regular-schedule-edit.component.scss'],
})
export class MerchantRegularScheduleEditComponent implements OnInit {


    merchantid: any;
    merchantLocationId: any;
    scheduleId: any;
    merchantSchedule: any = {
        eventYear: '',
        eventMonth: '',
        eventDay: '',
        acStartTime: '',
        acEndTime: '',
        locationName: '',
        address: '',
        lat: 0.0,
        lng: 0.0,
        enterBy: '',
        dateKey: ''
    }

    locationName: any;
    scheduledAddreass: any;
    lat: any;
    lng: any;
    scheduleDate: any;
    weekday = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    monthList = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

    weekDayList: any = [];
    startTime: Date = new Date();
    endTime: Date = new Date();


    disableWeekDaySelect: any = false;
    merchantLocationList: any = [];
    locationDropDownList: any = [];

    constructor(public navParams: NavParams, public modalCtrl: ModalController, private merchantService: OwnerMerchantService,
                public alertCtrl: AlertController) {
    }


    ngOnInit(): void {
        this.merchantid = this.navParams.get("merchantid");
        this.merchantLocationId = this.navParams.get("merchantLocationId");
        this.merchantLocationList = this.navParams.get("merchantLocationList");

        this.weekDayList.push({value: 'Monday', label: 'Monday'});
        this.weekDayList.push({value: 'Tuesday', label: 'Tuesday'});
        this.weekDayList.push({value: 'Wednesday', label: 'Wednesday'});
        this.weekDayList.push({value: 'Thursday', label: 'Thursday'});
        this.weekDayList.push({value: 'Friday', label: 'Friday'});
        this.weekDayList.push({value: 'Saturday', label: 'Saturday'});
        this.weekDayList.push({value: 'Sunday', label: 'Sunday'});

        if(this.merchantLocationList == undefined || this.merchantLocationList == null ){
            this.locationDropDownList.push({value:this.merchantLocationId,label:'Default Location'});
        }else{
            for(let location of this.merchantLocationList){
                this.locationDropDownList.push({value:location.id,label:location.name});
            }
        }
        console.log('location list is ', this.merchantLocationList, ' drop down list is ', this.locationDropDownList);


        let sc = this.navParams.get("currentSchedule");
        const selectWeekday = this.navParams.get("weekday");
        if (sc === null) {
            console.log(`selected weekday is ${selectWeekday}`);
            this.scheduleId = null;
            if (selectWeekday != null)
                this.scheduleDate = selectWeekday;
            this.merchantSchedule = {
                eventYear: '',
                eventMonth: '',
                eventDay: '',
                acStartTime: '',
                acEndTime: '',
                locationName: '',
                address: '',
                lat: 0.0,
                lng: 0.0,
                enterBy: '',
                dateKey: ''
            }
            this.startTime.setHours(11);
            this.startTime.setMinutes(0)
            this.endTime.setHours(15);
            this.endTime.setMinutes(0);
        } else {
            this.merchantSchedule = {
                eventYear: '',
                eventMonth: '',
                eventDay: '',
                acStartTime: '',
                acEndTime: '',
                locationName: '',
                address: '',
                lat: 0.0,
                lng: 0.0,
                enterBy: '',
                dateKey: ''
            }
            this.merchantSchedule.eventDay = sc.locationDetail.eventDay;
            this.merchantSchedule.acStartTime = sc.locationDetail.acStartTime,
                this.merchantSchedule.acEndTime = sc.locationDetail.acEndTime;
            this.merchantSchedule.locationName = sc.locationDetail.locationName;
            this.merchantSchedule.address = sc.locationDetail.address;
            this.merchantSchedule.lat = sc.locationDetail.lat;
            this.merchantSchedule.lng = sc.locationDetail.lng;
            this.merchantSchedule.enterBy = sc.locationDetail.enterBy;
            this.merchantSchedule.dateKey = sc.locationDetail.dateKey;
            this.scheduleId = sc.key;

            this.scheduledAddreass = sc.locationDetail.address;
            this.locationName = sc.locationDetail.locationName;
            this.scheduleDate = sc.locationDetail.eventDay;
            this.startTime = sc.locationDetail.acStartTime;
            this.endTime = sc.locationDetail.acEndTime;
            this.lat = sc.locationDetail.lat;
            this.lng = sc.locationDetail.lng;
            this.disableWeekDaySelect = true;
        }

    }

    save() {
        const savedSchedule = new BehaviorSubject(0); // 0 is the initial value

        if(this.scheduleDate == null || this.scheduleDate.length == 0){
            this.showSavingErrorAlert('Missing Data', 'Schedule Day cannot be empty');
            return;
        }
        if(this.lat == null || this.lng == null){
            this.showSavingErrorAlert('Missing Data', 'Schedule Location cannot be empty');
            return;
        }

        console.log(this.scheduleDate, ' ----', this.startTime, '----', this.endTime);
        this.merchantSchedule.eventYear = '';
        this.merchantSchedule.eventMonth = '';
        console.log(this.merchantSchedule.eventYear, '-', this.merchantSchedule.eventMonth, '-', this.merchantSchedule.eventDay);
        let startHour = this.startTime.getHours() > 9 ? '' + this.startTime.getHours() : '0' + this.startTime.getHours();
        let startMinute = this.startTime.getMinutes() > 9 ? '' + this.startTime.getMinutes() : '0' + this.startTime.getMinutes();
        let endHour = this.endTime.getHours() > 9 ? '' + this.endTime.getHours() : '0' + this.endTime.getHours();
        let endMinute = this.endTime.getMinutes() > 9 ? '' + this.endTime.getMinutes() : '0' + this.endTime.getMinutes();
        this.merchantSchedule.acStartTime = startHour + ':' + startMinute + ':00';
        this.merchantSchedule.acEndTime = endHour + ':' + endMinute + ':00';
        console.log(this.merchantSchedule.acStartTime, ' - ', this.merchantSchedule.acEndTime);
        this.merchantSchedule.locationName = this.locationName==null?'':this.locationName;
        this.merchantSchedule.address = this.scheduledAddreass;
        this.merchantSchedule.lat = this.lat;
        this.merchantSchedule.lng = this.lng;
        console.log('merchant id ', this.merchantid, ' location id', this.merchantLocationId);
        if (this.scheduleId === null) {
            //this is a new schedule
            //console('create a new schedule');
            let s = savedSchedule.subscribe(v=>{
                if(v == this.scheduleDate.length){
                    s.unsubscribe();
                    console.log('Saving completed');
                    this.dismiss();
                }
            })
            for (var i = 0; i < this.scheduleDate.length; i++) {
                this.merchantSchedule.eventDay = this.scheduleDate[i];
                this.merchantSchedule.dateKey = this.scheduleDate[i];

                const c = savedSchedule.getValue();
                savedSchedule.next(1 + c);
                this.merchantService.createMerchantRegularSchedule(this.merchantid,this.merchantLocationId,this.merchantSchedule).then(v=>{
                    console.log('Schedule save for ',this.merchantSchedule);
                });
            }

        } else {
            //console('update existing schedule');
            this.merchantService.updateMerchantRegularSchedule(this.merchantid, this.merchantLocationId, this.scheduleId, this.merchantSchedule).then(v => {
                this.dismiss();
            });
        }

    }

    removeSchedule() {
        this.merchantService.removeMerchantRegularSchedule(this.merchantid, this.merchantLocationId, this.scheduleId, this.scheduleDate).then(v => {
            this.dismiss();
        });
    }

    async showSavingErrorAlert(subTitle, title) {
        const alert = await this.alertCtrl.create({
            header: 'Schedule Update',
            subHeader: subTitle,
            message: title,
            buttons: ['OK']
        });

        await alert.present();
    }

    dismiss() {
        this.modalCtrl.dismiss();
    }

    async locationSearch() {
        console.log('location search is called');
        let modal = await this.modalCtrl.create(
            {
                component: MerchantLocationSearchComponent,
                componentProps: {
                    merchantId: this.merchantid,
                    showFavorite: true
                }
            });
        modal.onDidDismiss().then(v => {
            let data = v.data;
            console.log('will use the new location to update merchant', data);
            if (data != null) {
                this.scheduledAddreass = data.address;
                if (data.locationName === null) {
                    this.locationName = data.address;
                } else {
                    this.locationName = data.locationName;
                }
                this.lat = data.lat;
                this.lng = data.lng;
            }
        });
        await modal.present();

    }


}
