import { Injectable  } from '@angular/core';
import {map} from "rxjs/operators";
import * as GeoFire from "geofire";
import {AlertController} from '@ionic/angular';
import {OwnerUserDataService} from "./user-data-service";
import {OwnerAuthService} from "./vendor-auth-service";
import {AngularFireDatabase} from "@angular/fire/database";
import {Observable} from "rxjs";


@Injectable()
export class OwnerMerchantOrderService {

  orderList : Observable<any[]>;

  constructor(private db: AngularFireDatabase,
    private alertCtrl: AlertController,
    private userDataService: OwnerUserDataService,
    private authService : OwnerAuthService
  ) {

  }

  getMerchantOrderHistory(merchantID: string,merchantLocationID: string, dateKey:string){
    var orderPath = 'merchantOrderTrack/' + merchantID + '/' + dateKey + '/' + merchantLocationID ;
    let list = this.db.list(orderPath).snapshotChanges();
    return list;
  }

  getMerchantOrderDetail(merchantID: string,merchantLocationID: string, orderId: string){
    var orderPath = 'merchantOrder/' + merchantID + '/' + merchantLocationID + '/' + orderId;
    return this.db.object(orderPath).snapshotChanges().pipe(map(c => ({ key: c.payload.key, ...c.payload.val() as {} })));
  }



  updateOrderStatus(merchantID, merchantLocationID,orderdetail,newStatus){
  //  console.log('order detail is ', orderdetail, 'newStatus is ', newStatus);
    let userAccount = this.userDataService.getUserAccount();
    console.log('user is ', userAccount);
    let orderPath = 'merchantOrder/' + merchantID + '/'  + merchantLocationID + '/' + orderdetail.key + '/status' ;
  //  console.log('order path is ', orderPath);







    this.db.object(orderPath).set(newStatus).then(v=>{

/*
      let orderHistoryRef = this.db.database.ref('orderHistory/' + orderdetail.dateKey + '/' + orderdetail.key );
      console.log('Order history path ', 'orderHistory/' + orderdetail.dateKey + '/' + orderdetail.key );
      console.log('order db ref ', orderHistoryRef);
      orderHistoryRef.transaction(function (orderHistory)  {
        console.log('current order history  ', orderHistory);
        if(orderHistory == null){
          console.log('create order history object sicne it is null')
          orderHistory = {        customerId: 'test',
            statusTrack: []};
        }
        let orderHistoryTrack = {
          status: newStatus,
          timeStamp: new Date().getTime(),
          updateAccountId: userAccount.id,
          updateUserName: userAccount.account.fullName
        };
        orderHistory.statusTrack.push(orderHistoryTrack);
        console.log('order history will be ', orderHistory);
        return orderHistory;
      });
*/



      let orderHisPath =  'orderHistory/' + orderdetail.dateKey + '/' + orderdetail.key + '/statusTrack/'+newStatus;
      console.log('order history path is ', orderHisPath);
      let orderHistoryTrack = {
        status: newStatus,
        timeStamp: new Date().getTime(),
        updateAccountId: userAccount.id,
        updateUserName: userAccount.account.fullName
      };
      this.db.object(orderHisPath).set(orderHistoryTrack).then(v=>{
        console.log('Order history updated.', v)
      });

    });


  }

}
