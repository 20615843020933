import { Component, OnInit } from '@angular/core';
import {AlertController, LoadingController, ModalController, NavParams} from "@ionic/angular";
import {OwnerMerchantMenuService} from "../../../services/owner/merchant-menu-service";

@Component({
  selector: 'app-merchant-menu-selection-item-edit',
  templateUrl: './merchant-menu-selection-item-edit.component.html',
  styleUrls: ['./merchant-menu-selection-item-edit.component.scss'],
})
export class MerchantMenuSelectionItemEditComponent {

  merchantid :any;
  updatedItem: any = {name:'',description:'',price:0.0}
  originalSelection: any;

  selectionName: any;
  selectionDescription: any;
  selectionPrice: any = 0.0



  constructor(public merchantMenuService : OwnerMerchantMenuService,public navParams: NavParams,
              public alertCtrl: AlertController,public modalCtrl: ModalController
  ) {
    this.originalSelection = navParams.get("selectionItem");
    if(this.originalSelection != null){
      //this is to update existing optional item
      this.selectionName = this.originalSelection.name;
      this.selectionDescription = this.originalSelection.description;
      this.selectionPrice = parseFloat(this.originalSelection.price);
    }


    console.log('Original Item is ',this.originalSelection, 'updated item will be ', this.updatedItem);

  }

  save(){
    this.updatedItem = {name:this.selectionName,description:this.selectionDescription,price:parseFloat(this.selectionPrice)}

    console.log('the update item will be ', this.updatedItem);
    let data = {'oldItem': this.originalSelection, 'updatedItem': this.updatedItem};

    this.modalCtrl.dismiss(data);
  }

  dismiss() {
    this.modalCtrl.dismiss();
  }


}
