import { Injectable  } from '@angular/core';
import { AngularFireDatabase, AngularFireList, AngularFireObject } from '@angular/fire/database';
import {map} from "rxjs/operators";
import {Subject} from "rxjs";


export class BaseMenuItem {
  constructor(
      public name: string,
      public description: string,
      public price: number,
      public quantity: number,
      public selected: boolean) {
  }
}

export class OptionalMenuItem {
  constructor(
      public id: number,
      public name: string,
      public description: string,
      public selections: BaseMenuItem[],
      public requiredSelection: number,
      public maxSelection: number,
      public totalPrice: number) {
  }
}

export class MenuItem {
  constructor(
      public id: number,
      public name: string,
      public description: string,
      public image: string,
      public quantity: number,
      public totalPrice: number,
      public primaryMenuItem: BaseMenuItem,
      public requiredMenuItems: OptionalMenuItem[],
      public optionalMenuItems: OptionalMenuItem[]) {
  }
}

export class MenuCategory{
  constructor(
    public id: number,
    public name: string,
    public description: string,
    public menuitems: MenuItem[]
  ){}
}

export class MenuType{
  constructor(
    public id: number,
    public name: string,
    public description: string,
    public menuCategories: MenuCategory[]
  ){}
}

export class MerchantMenu{
  constructor(
    public id: number,
    public menuTypes : MenuType[],
    public fullMenuItemList: MenuItem[]
  ){}
}


@Injectable()
export class OwnerMerchantMenuService {

  dbRef: any;
  merchantMenu  = new Subject();

  constructor(public af:AngularFireDatabase) {

    this.dbRef = af.list("merchantLocation");



  }

  getMenuByMerchantKey(key){
    this.af.object('merchantMenu/' + key).valueChanges().subscribe(v =>{
      var m = <any>v;
      var mMenu = new MerchantMenu(0,[],[]);
      mMenu.id = key;
      //mMenu.menuTypes = m.menuTypes;
      let iType = 0;
      for(var t of m.menuTypes ){
        //process the menu type
        let tempMenuType = new MenuType(iType,t.name,t.description,[]);
        let iCat = 0;
        for(var cat of t.categories){
          //process the menu category
          if(cat.menuitems != null && cat.menuitems.length >0){
            let tempCategory = new MenuCategory(iCat,cat.name,cat.description,[]);
            for(var item of cat.menuitems){
              //process the primary menu item
              this.af.object('menuitems/' + key + '/' + item).valueChanges().subscribe(v1  =>{
                let value1 : any = v1;
                let baseitem = new BaseMenuItem(value1.name,value1.description,value1.price,1,true);
                let menuItem = new MenuItem(item,value1.name,value1.description,value1.image,1,value1.price,baseitem,[],[]);
                if(value1.optionalItemKey != null && value1.optionalItemKey.length>0){
                  //if there is optional menu items
                  for(var optionalItem of value1.optionalItemKey){
                    //process the optional items
                    this.af.object('optionalMenuItems/' + key + '/'+ optionalItem).valueChanges().subscribe(
                      optV =>{
                        let optValue : any = optV;
                          if(optValue != null){
                            let optItem = new OptionalMenuItem(optionalItem,optValue.name,optValue.description,[],optValue.requiredSelection,optValue.maxSelection,0);
                            for(var sel of optValue.selections){
                              let selItem = new BaseMenuItem(sel.name,sel.description,sel.price,1,false);
                              optItem.selections.push(selItem);
                            }
                            if(optItem.requiredSelection > 0){
                              menuItem.requiredMenuItems.push(optItem);
                            }else{
                              menuItem.optionalMenuItems.push(optItem);
                            }
                          }
                      }
                    );
                  }
                }
                console.log('menu item is', menuItem);
                tempCategory.menuitems.push(menuItem);
                mMenu.fullMenuItemList.push(menuItem);
              });
            }
            tempMenuType.menuCategories.push(tempCategory);
          }
          iCat++;
        }
        mMenu.menuTypes.push(tempMenuType);
        iType++;
      }
      this.merchantMenu.next(mMenu);
    });
  }

  getMerchantMenuItems(merchantID){
    let path = 'menuitems/'+merchantID;
    return this.af.list(path).snapshotChanges().pipe(map(values => {
      return values.map(c => ({ key: c.payload.key, ...c.payload.val() as {} }));
    }));
  }

  getMerchantOptionalMenuItems(merchantID){


    let path = 'optionalMenuItems/'+merchantID;
    return this.af.list(path).snapshotChanges().pipe(map(values => {
      return values.map(c => ({ key: c.payload.key, ...c.payload.val() as {} }));
    }));
  }

  getMerchantMenu(merchantID){
    let path = 'merchantMenu/'+merchantID;
    console.log('looking for me ', path);
    return this.af.object(path).valueChanges();
    //return this.af.list(path).snapshotChanges().map(values => {
    //  return values.map(c => ({ key: c.payload.key, ...c.payload.val() }));
    //});

  }



  updateMenuTye(merchantId,menuType) : any{
    let path = 'merchantMenu/' + merchantId + '/menuTypes/0';
    console.log('will update menutype with value ', menuType);
    return this.af.object(path).update(menuType);
  }

  createMenuTye(merchantId,menuType) : any{
    let path = 'merchantMenu/' + merchantId + '/menuTypes/0';
    console.log('will create menutype with value ', menuType);
    return this.af.object(path).set(menuType);
  }

    updateMenuItem(merchantId, menuItem) :any{
      if(menuItem.id === null){
        //this is a new menuitem
        console.log('will create menuitem with value ', menuItem);
        let path = 'menuitems/' + merchantId + '/';
        return this.af.list(path).push(menuItem.item);

      }else{
      //this is update to existing item
      console.log('will update menuitem with value ', menuItem);
        let path = 'menuitems/' + merchantId + '/' + menuItem.id;
        return this.af.object(path).update(menuItem.item);
      }

    }

    removeMenuItem(merchantId,menuItem):any{
      console.log('Remove menu item ', menuItem);
      let path = 'menuitems/' + merchantId + '/' + menuItem.id;
      return this.af.object(path).set(null);

    }

    updateOptionalItem(merchantId, optionaItem) :any{
      if(optionaItem.id === null || optionaItem.id === ''){
        //this is a new optionaItem
        console.log('will create optional item with value ', optionaItem);
        let path = 'optionalMenuItems/' + merchantId + '/';
        return this.af.list(path).push(optionaItem.item);

      }else{
      //this is update to existing item
      console.log('will update optional item with value ', optionaItem);
        let path = 'optionalMenuItems/' + merchantId + '/' + optionaItem.id;
        return this.af.object(path).update(optionaItem.item);
      }

    }

    removeOptionalItem(merchantId,optionalItem):any{
      console.log('Remove optional item ', optionalItem);
      let path = 'optionalMenuItems/' + merchantId + '/' + optionalItem.key;
      return this.af.object(path).set(null);
    }



}
