import {Component, NgZone, OnInit} from '@angular/core';
import { ModalController ,AlertController  } from '@ionic/angular';


import { UserData } from '../../services/user-data';
import { AddressGeocodeService } from '../../services/address-geocode-service';

import {  Geolocation } from '@ionic-native/geolocation/ngx';

declare var google: any;

@Component({
  selector: 'app-location-search',
  templateUrl: './location-search.component.html',
  styleUrls: ['./location-search.component.scss'],
})
export class LocationSearchComponent implements OnInit {

  autocompleteItems: any;
  autocomplete: any;
  acService:any;
  placesService: any;
  locationHistory: string[] = [];

  geocoder = new google.maps.Geocoder;

  searchOption:string = 'address';

  popularLocationList:any[] = [];

  popularLocationTreeList: any[] = [];
  selectedPopularLocation: any;

  constructor(public geolocation: Geolocation, private modalController: ModalController ,    public zone: NgZone,

              public userData: UserData,public alertCtrl: AlertController,public addressGeocodeService: AddressGeocodeService) {
  }

  ngOnInit() {
      this.acService = new google.maps.places.AutocompleteService();
      this.autocompleteItems = [];
      this.autocomplete = {
          query: ''
      };
      this.userData.getLocationHistory().then((value) => {
        console.log('location history value is', value);
        if(value != null){
          try{
            this.locationHistory = JSON.parse(value);
          }catch(e){
            console.log('error happened during parsing the locationHistory ', e);
            this.locationHistory = [];
          }
        }
      }

      );
      this.popularLocationList = this.userData.getPopularLocationList();
      console.log('popular location ', this.popularLocationList);
      for(let location of this.popularLocationList){
        let node:any = {label:'',data:{address:'',lat:0,lng:0},children:[]};
        node.label = location.locationName;
        node.expandedIcon = "pi pi-minus",
        node.collapsedIcon = "pi pi-plus",
        node.data.address = location.address;
        node.data.lat = location.latitude;
        node.data.lng = location.longitude;
        if(location.sublocation != null && location.sublocation.length >0){
          for(let subLocation of location.sublocation){
            let subNode:any = {label:'',data:{address:'',lat:0,lng:0},children:[]};
            subNode.label = subLocation.name;
            subNode.data.address = subLocation.address;
            subNode.data.lat = subLocation.latitude;
            subNode.data.lng = subLocation.longitude;
            node.children.push(subNode);
          }
        }
        this.popularLocationTreeList.push(node);
      }
      console.log(this.popularLocationTreeList)

  }


    async dismiss() {
    console.log('dismiss is called')
    await this.modalController.dismiss().then(result => {
      console.log('dismiss result ', result);
    });

  }

  chooseItem(item: any) {

      //console.log('modal > chooseItem > item > ', item);
      let s = this.addressGeocodeService.getGeocodeFromAddress(item.description).subscribe(v =>{
        s.unsubscribe();
        console.log('location for select item is: ', v);
        this.userData.addLocationHistory(v.address);
        this.modalController.dismiss(v);
      });
  }

  chooseHistoryItem(item: any) {
      //console.log('modal > chooseItem > item > ', item);
      let s = this.addressGeocodeService.getGeocodeFromAddress(item).subscribe(v =>{
        s.unsubscribe();
        console.log('location for select item is: ', v);
        this.modalController.dismiss(v);
      });
  }

  chooseCurrentLocation(){
    this.geolocation.getCurrentPosition().then(pos => {
        this.geoCodeLatLng(this.modalController,pos);
      }).catch((error) => {
        this.showAlert("Cannot retrieve your location", "Please check your browser set up to make sure you enable the location service. If you are using a smarphone, please make sure location service is enabled for your selected browser.")});
  }

  geoCodeLatLng(modalController,pos){
    let currentAddress: any = null;
    let latlng = {lat: pos.coords.latitude, lng:  pos.coords.longitude};
    this.geocoder.geocode({'location': latlng}, function(results, status) {
      if (status === 'OK') {
        if (results[0]) {
          //console.log(results[0]);
          currentAddress = {address:results[0].formatted_address, lat:pos.coords.latitude,lng:pos.coords.longitude};
          //console.log("current address for current location: ", JSON.stringify(results[0]), ' returned address will be ', currentAddress);
          console.log("select address: ", currentAddress)
          modalController.dismiss(currentAddress,null,'locationSearch');
        } else {
          console.log('No results found');
        }
      } else {
        console.log('Geocoder failed due to: ' + status);
      }

    });

  }





  async showAlert(title:string, description:string) {
      let alert = await this.alertCtrl.create({
            header: title,
            message: description,
                buttons: ['OK']
                });
      await alert.present();
}

  updateSearch() {
      console.log('modal > updateSearch with ' , this.autocomplete.query);
      if (this.autocomplete.query == '') {
          this.autocompleteItems = [];
          return;
      }
      //let self = this;
      let config = {
          types:  ['geocode'], // other types available in the API: 'establishment', 'regions', and 'cities'
          input: this.autocomplete.query//,
          //componentRestrictions: { country: 'US' }
      }
      console.log('auto complete item is ', this.autocompleteItems);
      this.acService.getPlacePredictions(config,  (predictions, status) => {
          console.log('modal > getPlacePredictions > status > ', status, this.autocompleteItems);
          if(status != 'ZERO_RESULTS')
          {
            this.autocompleteItems = [];
              this.zone.run(() => {
                  predictions.forEach((prediction) => {
                      this.autocompleteItems.push(prediction);
                  });
              });
            predictions.forEach((prediction) => {
                this.autocompleteItems.push(prediction);
                console.log(prediction, this.autocompleteItems);
            });
          }
      });
  }

  selectSubLocation(event){
    console.log('selected node: ', event)
    let address = {address:event.node.label, lat:event.node.data.lat,lng:event.node.data.lng};
    if(event.node.data.lat != undefined){
      //this is a valid address
      if(event.node.parent != null){
        //this is a child node
        address = {address:event.node.label + ' - ' + event.node.parent.label, lat:event.node.data.lat,lng:event.node.data.lng};
      }

    }else{
      address = {address:event.node.label, lat:event.node.children[0].data.lat,lng:event.node.children[0].data.lng};
    }
    this.modalController.dismiss(address);


  }

  cleanLocationHistory(){
    this.userData.cleanLocationHistory();
    this.locationHistory = [];

  }

    segmentChanged(ev: any) {
        console.log('Segment changed', ev);
    }

}
