import { Injectable } from '@angular/core';

import { Storage } from '@ionic/storage';

import {BehaviorSubject} from "rxjs";
import {AlertController} from "@ionic/angular";
import {EventsService} from "../events-service";

@Injectable()
export class OwnerUserDataService {

  LOCATION_HISTORY = 'locationHistory';

  currentUserAccount: any;
  foodcart: any[] = [];
  orderItemId : number = 0;
  currentMerchantId: any;
  locationHistory: string[] = [];
  currentMerchantLocationId: any;
  currentMerchant: any;

  totalorders:any = new BehaviorSubject(0);

  currentVendorAccount : any = null;



  constructor(
    public events: EventsService,
    public storage: Storage,
    public alertCtrl: AlertController
  ) {}

  setUserAccount(userAccount: any): void {
    console.log('set account is called for ', userAccount);
    this.currentUserAccount = userAccount;
  }

  getUserAccount(): any {
    return this.currentUserAccount;
  }

  setCurrentVendorAccount(vendorAccount :any){
    console.log('set current vendor account is called for ', vendorAccount);
    this.currentVendorAccount = vendorAccount;
  }

  getCurrentVendorAccount(){
    return this.currentVendorAccount;
  }


  addToCart(order){

    for(var i =0; i<this.foodcart.length; i++){
      if(this.foodcart[i].orderItemId == order.orderItemId){
        //this is the old order to be updated
        this.foodcart[i] = order;
      }
    }
    if(order.orderItemId == 0){
      //this is a new order
      this.orderItemId = this.orderItemId + 1;
      order.orderItemId = this.orderItemId;
      this.foodcart.push(order);
    }
    this.totalorders.next(this.foodcart.length);
    this.events.publish('Cart Updated');
  }

  getTotalOrderItem(){
    return this.totalorders;
  }

  removeFromCart(order){
    for(var i =0; i<this.foodcart.length; i++){
      if(this.foodcart[i].menuitem.name == order.menuitem.name){
        this.foodcart.splice(i,1);
        this.totalorders.next(this.foodcart.length);
        this.events.publish('Cart Updated');
        return;
      }
    }
  }

  getCartStatus(){
    return this.foodcart;
  }

  emptyCart(){
    this.foodcart = [];
    this.orderItemId = 0;
    this.totalorders.next(0);
    this.events.publish('Cart Updated');
  }

  setCurrentMerchantId(merchantId){
    this.currentMerchantId = merchantId;
  }

  getCurrentMerchantId(){
    return this.currentMerchantId;
  }

  setCurrentMerchant(merchant){
    this.currentMerchant = merchant;
  }

  getCurrentMerchant(){
    return this.currentMerchant;
  }

  addLocationHistory(newLocation: string): void {


    var locationList :string[] = [];

    this.getLocationHistory().then((value) => {

      if(value == null){
        locationList.push(newLocation);
        console.log("the location now is --- : " ,locationList);
      }else{
        var currentLocation = JSON.parse(value);
        console.log("current location", currentLocation," newLocation", newLocation);
        if(currentLocation != null){
          for(var location of currentLocation){
            if(location != newLocation){
              locationList.push(location);
            }
          }
        }
        locationList.push(newLocation);
        console.log("the location or is --- : " ,locationList);
      }
      console.log("The location list : ",locationList, "json object: ", JSON.stringify(locationList));
      this.storage.set(this.LOCATION_HISTORY, JSON.stringify(locationList));
    });


  };

  getLocationHistory(): Promise<string> {
    return this.storage.get(this.LOCATION_HISTORY).then((value) => {
      return value;
    });
  };

  cleanLocationHistory(){
    this.storage.set(this.LOCATION_HISTORY, JSON.stringify(null));
  }

  resetLocalStorage(){
    this.storage.clear();
  }

  getCurrentLocationId() : any{
    return this.currentMerchantLocationId;
  }

  setCurrentLocationId(id){
    this.currentMerchantLocationId = id;
  }

}
