import { Component, OnInit } from '@angular/core';
import {LoadingController, NavParams} from '@ionic/angular';
import { FormControl } from '@angular/forms';
import { ModalController ,AlertController  } from '@ionic/angular';

import 'rxjs/add/operator/debounceTime';

import { UserData } from '../../services/user-data';
import { MerchantData } from '../../services/merchant-data';
import {ShareDataService} from "../../services/shareData-service";



@Component({
  selector: 'app-merchant-search',
  templateUrl: './merchant-search.component.html',
  styleUrls: ['./merchant-search.component.scss'],
})
export class MerchantSearchComponent  implements OnInit{

  merchantDetail: any;


  merchant: any;

  searchTerm: string = '';
  searchControl: FormControl;
  searching: any = false;


  filterMerchantList: any[] = [];
  fullMerchantList: any[] = [];
  locationTagMap : Map<string,[any]> = new Map<string, [any]>();

  constructor(public dataProvider: MerchantData, public userData: UserData,public modalController: ModalController ,
    public navParams: NavParams,public shareDataService: ShareDataService) {
      this.searchControl = new FormControl();
  }

  ngOnInit() {
    let l = this.navParams.get("locationList");
    for(let item of l){
        this.fullMerchantList.push(item);
    }

    this.filterMerchantList = this.fullMerchantList;
    console.log('merchant list will be ', this.filterMerchantList)

  }


  setFilteredItems(ev:any){
    this.filterMerchantList = [];
    this.searchTerm = ev.target.value;
    if(this.searchTerm.trim() === ''){
      this.filterMerchantList = this.fullMerchantList;
    }
    this.fullMerchantList.filter((item) => {
        if(item.merchant.sharedInfo.name.toLowerCase().indexOf(this.searchTerm.toLowerCase()) > -1 || item.merchant.sharedInfo.tags.toString().toLowerCase().indexOf(this.searchTerm.toLowerCase()) > -1){
          this.filterMerchantList.push(item);
        };
    });
  }

  selectMerchant(merchant){
    let data = {selectedMerchantList: [],selectMerchant: merchant,filter:this.searchTerm};
    this.modalController.dismiss(data);
  }


  onSearchInput(){
    this.searching = true;
  }

  dismiss() {
      this.modalController.dismiss();
  }

  getKeys(map){
    return Array.from(map.keys());
  }

  resetMerchantList(){
    this.filterMerchantList = this.fullMerchantList;
  }


  checkOpenStatus(merchantLocation):string{
    let d = new Date();
    let locationDetail = merchantLocation.currentLocation;
    let timeZone = '04:00';
    if(this.userData.getRegionTimeZoneMap().get(merchantLocation.sharedInfo.region) != null){
      timeZone = this.userData.getRegionTimeZoneMap().get(merchantLocation.sharedInfo.region);
    }
    let startTime = Date.parse(locationDetail.eventYear+'-'+locationDetail.eventMonth+'-'+locationDetail.eventDay+'T'+locationDetail.acStartTime+'.000-'+timeZone);
    let endTime = Date.parse(locationDetail.eventYear+'-'+locationDetail.eventMonth+'-'+locationDetail.eventDay+'T'+locationDetail.acEndTime+'.000-'+timeZone);
    let now = Date.now();
    if(startTime <= now && now <= endTime ){
      //console.log('the merchant is open');
      return 'true';
    }else{
      return 'false';
    }
  }

  getLocationSchedule(locationDetail){
    let d = new Date();
    let sc = '' + locationDetail.eventYear + locationDetail.eventMonth + locationDetail.eventDay;
    let cur = '';
    if(d.getMonth()>8)
      cur= '' + d.getFullYear()+(d.getMonth()+1)+d.getDate();
    else
      cur= '' + d.getFullYear()+'0'+(d.getMonth()+1)+d.getDate();
    if(sc == cur){
      return locationDetail.acStartTime + ' - ' + locationDetail.acEndTime;
    }else{
      return locationDetail.acStartTime + ' - ' + locationDetail.acEndTime + ', ' + locationDetail.eventMonth + '/'+ locationDetail.eventDay+'/'+locationDetail.eventYear;
    }
  }

  getStartTime(locationDetail){

    let t = locationDetail.acStartTime.split(':');
    let d;
    d= new Date(locationDetail.eventYear,parseInt(locationDetail.eventMonth)-1,locationDetail.eventDay,t[0],t[1],0,0);
    return d;
  }

  getEndTime(locationDetail){
    let t = locationDetail.acEndTime.split(':');
    let d;
    d= new Date(locationDetail.eventYear,parseInt(locationDetail.eventMonth)-1,locationDetail.eventDay,t[0],t[1],0,0);
    return d;
  }

  getBriefAddress(address){
    let a = address.split(',');
    return a[0];
  }

  getStatusText(merchant):any {
    const dateKey = this.getTodayDateKey();
    const dateKey1 = merchant.currentLocation.eventYear + '-' + merchant.currentLocation.eventMonth + '-' + merchant.currentLocation.eventDay;
    if(this.checkOpenStatus(merchant) === 'true'){
      //this is an open one
      return 'Current Location:'
    }else{
      if(dateKey === dateKey1){
        return 'Today\'s Location:';
      }else{
        if(dateKey.localeCompare(dateKey1)){
          return 'Previous Location:';
        }else{
          return 'Next Location';
        }
      }
    }

  }

  getTodayDateKey():any {
    const d: Date = new Date();
    let dateKey = '';
    if (d.getMonth() > 8) {
      dateKey = d.getFullYear() + '-' + (d.getMonth() + 1);
    } else {
      dateKey = d.getFullYear() + '-0' + (d.getMonth() + 1);
    }
    if (d.getDate() < 10) {
      dateKey = dateKey + '-0' + d.getDate();
    } else {
      dateKey = dateKey + '-' + d.getDate();
    }
    return dateKey;
  }

  getMerchantLogo(logoLink: any) {
    if(logoLink.trim() === 'https://firebasestorage.googleapis.com/v0/b/gulpmealdev.appspot.com/o/truck_default.png?alt=media&token=8e882d15-f8b9-4c81-b037-4d61def885e6')
      return 'assets/truck_default.png';
    else
      return logoLink;
  }




}
