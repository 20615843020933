import { Component, OnInit } from '@angular/core';
import {AlertController, ModalController, NavParams} from "@ionic/angular";
import {OwnerUserDataService} from "../../../services/owner/user-data-service";
import {OwnerAddressGeocodeService} from "../../../services/owner/address-geocode-service";
import {OwnerMerchantService} from "../../../services/owner/merchant-service";

import {  Geolocation } from '@ionic-native/geolocation/ngx';
import { google } from "google-maps";

@Component({
  selector: 'app-merchant-location-search',
  templateUrl: './merchant-location-search.component.html',
  styleUrls: ['./merchant-location-search.component.scss'],
})
export class MerchantLocationSearchComponent implements OnInit {


  autocompleteItems: any;
  autocomplete: any;
  acService:any;
  placesService: any;
  locationHistory: string[] = [];

  favoriteLocationList: any = [];
  merchantId :any;
  showFavorite: any = true;
  searchOption: any = 'address'
  geocoder = new google.maps.Geocoder;

  selectedLocation: any;
  favoriteDropDownList:any = [];
  selectedFavoriteLocation:any;

  constructor(public geolocation: Geolocation,public navParams: NavParams,
              public userData: OwnerUserDataService,public alertCtrl: AlertController,
              public addressGeocodeService: OwnerAddressGeocodeService,private merchantService: OwnerMerchantService,
              public modalCtrl: ModalController
  ) {
  }

  ngOnInit() {
    this.acService = new google.maps.places.AutocompleteService();
    this.autocompleteItems = [];
    this.autocomplete = {
      query: ''
    };
    this.userData.getLocationHistory().then((value) => {
          console.log('value is', value);
          if(value != null){
            try{
              this.locationHistory = JSON.parse(value);
            }catch(e){
              console.log('error happened during parsing the locationHistory ', e);
              this.locationHistory = [];
            }
          }
        }

    );

  }

  ionViewWillEnter(){
    this.merchantId = this.navParams.get("merchantId");
    this.showFavorite = this.navParams.get("showFavorite");

    console.log('The merchant id is ',this.merchantId)
    if(this.merchantId != null){
      this.merchantService.getMerchantFavoriteLocation(this.merchantId).subscribe(v=>{
        if(v != null){
          this.favoriteLocationList = v;
          for(let favoriteLocation of this.favoriteLocationList){
            this.favoriteDropDownList.push({label:favoriteLocation.locationName,value:favoriteLocation})
          }

          console.log('Favorite location is ', v);
        }
      });
    }


  }

  dismiss() {
    this.modalCtrl.dismiss(null);
  }

  chooseItem(item: any) {

    //console.log('modal > chooseItem > item > ', item);
    this.addressGeocodeService.getGeocodeFromAddress(item.description).subscribe(v =>{
      console.log('location for select item is: ', v);
      this.userData.addLocationHistory(v.address);
      this.selectedLocation = v;
      this.modalCtrl.dismiss(v);
    });
  }

  chooseHistoryItem(item: any) {
    //console.log('modal > chooseItem > item > ', item);
    this.addressGeocodeService.getGeocodeFromAddress(item).subscribe(v =>{
      console.log('location for select item is: ', v);
      this.modalCtrl.dismiss(v);
    });
  }



  chooseCurrentLocation(){

    this.geolocation.getCurrentPosition().then(pos => {
      let currentAddress: any = null;
      //console.log('lat: ' + pos.coords.latitude + ', lon: ' + pos.coords.longitude);
      var latlng = {lat: pos.coords.latitude, lng:  pos.coords.longitude};
      this.geoCodeLatLng(this.modalCtrl,pos);
    }).catch((error) => {
      this.showAlert("Location Error", error.message)});
  }

  geoCodeLatLng(viewCtrlLocal,pos){
    let currentAddress: any = null;
    let latlng = {lat: pos.coords.latitude, lng:  pos.coords.longitude};
    this.geocoder.geocode({'location': latlng}, function(results, status:any) {
      if (status === 'OK') {
        if (results[0]) {
          console.log('find address is ', results[0]);
          currentAddress = {address:results[0].formatted_address, lat:pos.coords.latitude,lng:pos.coords.longitude};
          //console.log("current address for current location: ", JSON.stringify(results[0]), ' returned address will be ', currentAddress);
          console.log("select address: ", currentAddress)
          viewCtrlLocal.dismiss(currentAddress);
        } else {
          console.log('No results found');
        }
      } else {
        console.log('Geocoder failed due to: ' + status);
      }

    });

  }





  async showAlert(title:string, description:string) {
    let alert = await this.alertCtrl.create({
      header: title,
      message: description,
      buttons: ['OK']
    });
    await alert.present();
  }

  updateSearch() {
    console.log('modal > updateSearch');
    if (this.autocomplete.query == '') {
      this.autocompleteItems = [];
      return;
    }
    let self = this;
    let config = {
      types:  ['geocode'], // other types available in the API: 'establishment', 'regions', and 'cities'
      input: this.autocomplete.query
      //componentRestrictions: { country: 'US' }
    }
    this.acService.getPlacePredictions(config, function (predictions, status) {
      console.log('modal > getPlacePredictions > status > ', status);
      if(status != 'ZERO_RESULTS')
      {
        self.autocompleteItems = [];
        predictions.forEach(function (prediction) {
          self.autocompleteItems.push(prediction);
        });
      }
    });
  }

  selectFavoriteLocation(){
    if(this.selectedFavoriteLocation != null){
      let address = {address:this.selectedFavoriteLocation.address,locationName: this.selectedFavoriteLocation.locationName, lat:this.selectedFavoriteLocation.lat,lng:this.selectedFavoriteLocation.lng};
      console.log("select address: ", address)
      this.modalCtrl.dismiss(address);
    }

  }

  cleanLocationHistory(){
    this.userData.cleanLocationHistory();
    this.locationHistory = [];

  }


}
