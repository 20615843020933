import {Component} from '@angular/core';

import {ModalController, Platform} from '@ionic/angular';
import {SplashScreen} from '@ionic-native/splash-screen/ngx';
import {StatusBar} from '@ionic-native/status-bar/ngx';

import {ShareDataService} from "./services/shareData-service";
import {UserData} from "./services/user-data";
import {Router} from "@angular/router";
import {MerchantScheduleUpdateComponent} from "./pages/merchant-pages/merchant-schedule-update/merchant-schedule-update.component";
import {ViewportScroller} from "@angular/common";
import {UserAccountService} from "./services/user-account-service";
import {FcmNotificationService} from "./services/fcm_notification_service";
import {AuthenticationService} from "./services/authentication-service";
import {BehaviorSubject} from "rxjs";
import {OwnerMerchantService} from "./services/owner/merchant-service";
import {MerchantService} from "./services/merchant-service";
import {EventsService} from "./services/events-service";
import {Storage} from '@ionic/storage-angular';

import { environment } from '../environments/environment';



@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html'
})
export class AppComponent {

  clientappPages = [
    {
      title: 'Region List',
      url: '/region',
      icon: 'home'
    },
    {
      title: 'Yummy Meal',
      url: '/yummy/tabs/merchant',
      icon: 'restaurant'
    },
    {
      title: 'My Cart',
      url: '/yummy/tabs/mycart',
      icon: 'cart'
    }
  ];

  clientappLoginPages = [
    {
      title: 'Login',
      url: 'clientLogin',
      icon: 'log-in'
    },
    {
      title: 'Sign Up',
      url: '/yummy/tabs/account/login',
      icon: 'person-add'
    }
  ];

  clientAfterLoginPages = [
    {
      title: 'Region List',
      url: '/region',
      icon: 'home'
    },
    {
      title: 'Yummy Meal',
      url: '/yummy/tabs/merchant',
      icon: 'restaurant'
    },
    {
      title: 'My Cart',
      url: '/yummy/tabs/mycart',
      icon: 'cart'
    },
    {
      title: 'My Account',
      url: '/yummy/tabs/account',
      icon: 'person-outline'
    }
  ];

  merchantappLoginPages = [
    {
      title: 'Vendor Login',
      url: 'vendorLogin',
      icon: 'log-in'
    }
  ];

  merchantappAfterLoginPages = [
    {
      title: 'Order List',
      url: 'merchant/tabs/order',
      icon: 'restaurant'
    },
    {
      title: 'My Cart',
      url: 'merchant/tabs/cart',
      icon: 'cart'
    },
    {
      title: 'Menu Review',
      url: 'merchant/tabs/menu',
      icon: 'menu'
    },
    {
      title: 'My Account',
      url: 'merchant/tabs/account',
      icon: 'person-outline'
    }
  ];

  adminappLoginPages = [
    {
      title: 'Administrator Login',
      url: 'adminLogin',
      icon: 'log-in'
    }
  ];

  loggedIn = false;
  userRole = 'client'

  prodEnvironment = false;
  version = "1.0.0"


  constructor(
    private platform: Platform, public events: EventsService, public router: Router, public modalCtrl: ModalController,
    private splashScreen: SplashScreen, private viewportScroller: ViewportScroller, public storage: Storage,
    private statusBar: StatusBar, public authService: AuthenticationService, public userData: UserData,
    public shareDataService: ShareDataService, private merchantService: MerchantService,
    public userAccountService: UserAccountService, public fcmService: FcmNotificationService
  ) {
    this.viewportScroller;
    this.initializeApp();
    this.prodEnvironment = environment.production;
    this.version = environment.version;
    console.log("Is production environment? ", this.prodEnvironment, " Version: ", this.version );
  }

  async ngOnInit() {
    // If using a custom driver:
    // await this.storage.defineDriver(MyCustomDriver)
    await this.storage.create();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
      this.listenToLoginEvents();
    });
  }

  listenToLoginEvents() {
    this.events.getObservable().subscribe(v => {
      console.log('event received ', v);
      if (v == 'user:login') {
        this.updateUserAccount();
      } else if (v == 'user:vendorLogin') {
        this.updateVendorAccount();
      } else if (v == 'user:signup') {
        console.log('user sign up')
        this.updateUserAccount();
      } else if (v == 'user:logout') {
        this.loggedIn = false;
        this.userRole = this.shareDataService.userRole;
        this.userData.setFavoriteList(null);
        console.log('user log out with current user role ', this.shareDataService.userRole, ' login status ', this.loggedIn)
      }
    })
  }


  logout() {
    this.shareDataService.userRole = 'client';
    this.authService.signOut();
    this.router.navigateByUrl('/yummy/tabs/merchant');
    this.loggedIn = false;
    this.userRole = this.shareDataService.userRole;
    this.userData.setFavoriteList(null);
    console.log('user logout from side menu');
  }

  updateUserAccount() {

    const s1 = this.userAccountService.getUserAccount(this.authService.currentUserId).subscribe(v => {
      if (v != null) {
        s1.unsubscribe();
        let u: any = v;
        const userID = u.userId;
        this.userData.setUserAccount({"name": u.fullName, "email": u.emailAddress, "phone": u.phoneNumber});
        if (this.platform.is('cordova')) {
          console.log('initialize the message service')
          this.fcmService.initialize(userID);
          this.fcmService.getToken();
          this.fcmService.listenToNotifications();
        }
        this.loggedIn = true;
        this.shareDataService.userRole = 'client';
        this.userRole = 'client';
        this.updateFavoriateList();
        console.log('Current user role is ', this.userRole, ' is user login ', this.loggedIn);
        console.log('finishing sync the user account -- ', u);

        console.log('before getting the user account ');
      }
    });
  }

  updateVendorAccount() {
    const s1 = this.userAccountService.getUserAccount(this.authService.currentUserId).subscribe(v => {
      if (v != null) {
        s1.unsubscribe();
        let u: any = v;
        const userID = u.userId;
        this.userData.setUserAccount({"name": u.fullName, "email": u.emailAddress, "phone": u.phoneNumber});
        if (this.platform.is('cordova')) {
          console.log('initialize the message service')
          this.fcmService.initialize(userID);
          this.fcmService.getToken();
          this.fcmService.listenToNotifications();
        }
        this.loggedIn = true;
        this.shareDataService.userRole = 'owner';
        this.userRole = 'owner';
        console.log('Current user role is ', this.userRole);
        console.log('finishing sync the vendor account -- ', u);
      }
    });
  }


  updateFavoriateList() {
    console.log('try to get the user favorite');
    let s = this.userAccountService.getUserFavoriteList(this.authService.currentUserId).subscribe(v => {
      if (v != null) {
        s.unsubscribe();
        this.userData.setFavoriteList(v);
      }
    });
  }

  async updateMerchantLocation() {
    console.log('merchant id for the login account is ', this.shareDataService.merchantIDForLoginAccount, this.shareDataService.merchant);
    let locationList: any = [];

    let ss = this.merchantService.getMerchantDetail(this.shareDataService.merchantIDForLoginAccount).subscribe(v => {
      if (v != null) {
        ss.unsubscribe();
        let merchant: any = v;
        const processedLocation = new BehaviorSubject(0); // 0 is the initial value
        let s1 = processedLocation.subscribe(count => {
          if (count == merchant.locationList.length) {
            s1.unsubscribe();
            this.openLocationUpdate(locationList, this.shareDataService.merchantIDForLoginAccount, this.shareDataService.merchantLocationIDforLoginAccount)
          }
        });
        for (let locationID of merchant.locationList) {
          let s2 = this.merchantService.getLocationDetail(locationID).subscribe(v => {
            if (v != null) {
              s2.unsubscribe();
              let location: any = v;
              locationList.push({name: location.locationName, id: locationID});
              processedLocation.next(1 + processedLocation.getValue());
            }
          })
        }
      }
    });

  }

  async openLocationUpdate(locationList, merchantId, locationId) {
    let modal = await this.modalCtrl.create(
      {
        component: MerchantScheduleUpdateComponent,
        componentProps: {
          merchantId: this.shareDataService.merchantIDForLoginAccount,
          merchantLocationId: this.shareDataService.merchantLocationIDforLoginAccount,
          merchantLocationList: locationList
        }
      });
    await modal.present();
  }

}
