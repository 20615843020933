import {Injectable} from '@angular/core';
import {AngularFireDatabase} from '@angular/fire/database';
import 'rxjs/add/operator/map';

import {AlertController} from '@ionic/angular';


import {UserData} from '../services/user-data';
import {AuthService} from '../services/auth-service';
import {Subject} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {ShareDataService} from "./shareData-service";
import {AuthenticationService} from "./authentication-service";


export class FoodOrder {
  constructor(
    public customerId: string,
    public customerName: string,
    public customerEmail: string,
    public customerPhone: string,
    public subtotal: number,
    public salesTax: number,
    public discount: number,
    public totalOrderPrice: number,
    public merchantId: string,
    public merchantLocationId: string,
    public merchantName: string,
    public orderComment: string,
    public orderItems: OrderItem[],
    public status: string,
    public qrCode: string,
    public orderYear: number,
    public orderMonth: number,
    public orderDay: number,
    public orderTimeStamp: number,
    public orderType: string,
    public orderBy: string,
    public dateKey: string,
    public merchantRegion: string,
    public orderNumber: string
  ) {
  }
}

export class OrderItem {
  constructor(
    public menuitemId: string,
    public primaryItemDescription: string,
    public optionalItemDescription: string,
    public totalPrice: number,
    public quantity: number,
    public itemComment: string
  ) {
  }
}


@Injectable({
  providedIn: 'root'
})
export class OrderProcessService {

  foodcart: any;
  orderSubmission = new Subject();
  orderSummarySubmission = new Subject();

  constructor(private db: AngularFireDatabase,
              private alertCtrl: AlertController,
              private userData: UserData,
              private authService: AuthenticationService,
              private http: HttpClient,
              private sharedDataService: ShareDataService
  ) {

  }

  submitOrder(orderComment, orderType, customerName, customerPhone, customerEmail, orderRegion): any {
    var userAccount = this.userData.getUserAccount();
    var merchant = this.userData.getMerchant();
    console.log(merchant, ' - userId: ', this.authService.currentUserId, ' - user account: ', userAccount);

    this.foodcart = this.userData.getCartStatus();
    var orderitems: OrderItem[] = [];
    //var totalPrice : number = 0;
    for (var orderitem of this.foodcart.orderItems) {
      var optionalDescription: string = "";
      if (orderitem.optionalSelections != null && orderitem.optionalSelections.length > 0) {
        optionalDescription = orderitem.optionalSelections.join(',');
      }
      var item: OrderItem = new OrderItem(orderitem.menuitem.id, orderitem.orderdescription, optionalDescription, orderitem.totalPrice, orderitem.quantity, '' + orderitem.comment);
      //totalPrice = totalPrice + orderitem.totalPrice;
      orderitems.push(item);
    }


    let orderRef = this.db.database.ref('merchantOrder/' + merchant.merchant.merchantID + '/' + merchant.key);
    let orderKey = orderRef.push().key;

    let s1 = this.http.get('https://us-central1-gulpmealdev.cloudfunctions.net/getDate', {responseType: 'text'}).subscribe({
      next: data => {
        s1.unsubscribe();
        console.log('the order submission date from server is ', data);
        let orderTime: Date = new Date();
        orderTime.setTime(Date.parse(data));

        let year = orderTime.getFullYear();
        let month = orderTime.getMonth() + 1;
        let day = orderTime.getDate();
        let timestamp = orderTime.getTime();
        let dateKey = this.sharedDataService.getDateKey(orderTime);
        let name = customerName;
        let email = customerEmail;
        let phone = customerPhone;
        if (orderType == 'Customer') {
          name = userAccount.name || '';
          email = userAccount.email || '';
          phone = userAccount.phone || '';
        }
        let qrCode = '';

        let merchantOrder: FoodOrder = new FoodOrder(this.authService.currentUserId, name, email, phone, this.foodcart.totalOrderPrice, this.foodcart.saleTax,
          this.foodcart.discount, this.foodcart.finalPrice, merchant.merchant.merchantID, merchant.key, merchant.merchant.sharedInfo.name,
          '' + orderComment, orderitems, "SUBMITTED", qrCode, year, month, day,
          timestamp, 'Customer', this.authService.currentUserId, dateKey, orderRegion, '');
        if (orderType == 'Vendor') {
          merchantOrder.status = 'ACCEPTED'
        }

        let orderSummaryRef = this.db.database.ref('merchantOrderSummary/' + dateKey + '/' + merchantOrder.merchantId + '/' + merchantOrder.merchantLocationId);
        orderSummaryRef.transaction((summary) => {
          //use a transaction to get the total order number to generate the order number
          if (summary == null) {
            summary = {totalOrder: 0, orders: {}}
          }
          summary.totalOrder++;
          let orderNumber = dateKey.replaceAll('-', '') + '-' + this.prepenZero(summary.totalOrder, 4);
          summary.orders[orderKey] = {
            orderNumber: orderNumber,
            total: merchantOrder.totalOrderPrice,
            merchantName: merchantOrder.merchantName,
            region: merchantOrder.merchantRegion,
            merchantId: merchantOrder.merchantId,
            locationId: merchantOrder.merchantLocationId
          };
          merchantOrder.orderNumber = orderNumber;
          return summary;
        }).then(v => {

          console.log("the final customer order will be : ", merchantOrder)
          const merchantOrderTrack = {
            orderId: orderKey,
            dateKey: merchantOrder.dateKey,
            total: merchantOrder.totalOrderPrice
          }

          const customerOrder = {
            merchantId: merchantOrder.merchantId,
            merchantLocationId: merchantOrder.merchantLocationId,
            orderId: orderKey,
            dateKey: merchantOrder.dateKey,
            orderTime: merchantOrder.orderTimeStamp,
            merchantName: merchantOrder.merchantName,
            total: merchantOrder.totalOrderPrice
          };

          const orderHistory = {
            customerId: merchantOrder.customerId,
            merchantId: merchantOrder.merchantId,
            merchantLocationId: merchantOrder.merchantLocationId,
            orderPlaceTimeStamp: merchantOrder.orderTimeStamp,
            orderNumber: merchantOrder.orderNumber,
            orderCompleteTime: 0,
            TotalTime: 0,
            statusTrack: {
              SUBMITTED: {
                status: "SUBMITTED",
                timeStamp: merchantOrder.orderTimeStamp,
                updateAccountId: merchantOrder.customerId,
                updateUserName: userAccount.name
              }
            }
          };


          const cusOrderPath = 'customerOrderTrack/' + merchantOrder.customerId + '/' + orderKey + '/';
          const orderHisPath = 'orderHistory/' + merchantOrder.dateKey + '/' + orderKey + '/';
          const merOrderTrackPath = 'merchantOrderTrack/' + merchantOrder.merchantId + '/' + merchantOrder.dateKey + '/' + merchantOrder.merchantLocationId + '/' + orderKey + '/';
          const merOrderListPath = 'merchantOrder/' + merchantOrder.merchantId + '/' + merchantOrder.merchantLocationId + '/' + orderKey + '/';

          let updates = {};
          updates[cusOrderPath] = customerOrder;
          updates[orderHisPath] = orderHistory;
          updates[merOrderTrackPath] = merchantOrderTrack;
          this.db.database.ref(merOrderListPath).update(merchantOrder).then(x => {
            this.db.database.ref(cusOrderPath).update(customerOrder).then(x => {
              this.db.database.ref(merOrderTrackPath).update(merchantOrderTrack).then(x => {
                this.db.database.ref(orderHisPath).update(orderHistory).then(x => {

                  let orderSubmissionSummary = {
                    orderID: orderKey,
                    orderNumber: merchantOrder.orderNumber,
                    status: merchantOrder.status,
                    merchantId: merchantOrder.merchantId,
                    merchantLocationId: merchantOrder.merchantLocationId,
                    merchantName: merchantOrder.merchantName,
                    orderItems: merchantOrder.orderItems,
                    subtotal: merchantOrder.subtotal,
                    discount: merchantOrder.discount,
                    salesTax: merchantOrder.salesTax,
                    totalOrderPrice: merchantOrder.totalOrderPrice
                  };
                  this.orderSubmission.next(orderSubmissionSummary);

                })
              })
            })
          })
        });
      },
      error: error => {
        console.error('Error happened when calling server ', error);
      }
    })


  }

  prepenZero(str, len) {
    if (typeof str === 'number' || Number(str)) {
      str = str.toString();
      return (len - str.length > 0) ? new Array(len + 1 - str.length).join('0') + str : str;
    } else {
      return str;
    }
  }


}
