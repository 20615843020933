import {Injectable} from '@angular/core';
import {AngularFireDatabase} from '@angular/fire/database';
import {GeoFire} from 'geofire'
import {distanceBetween} from 'geofire-common'
import 'rxjs/Rx'
import {BehaviorSubject} from "rxjs";
import {map} from "rxjs/operators";
import {ShareDataService} from "./shareData-service";


@Injectable({
    providedIn: 'root'
})
export class MerchantService {

    dbRef: any;
    geoFire: any;
    hits = new BehaviorSubject({key: null, location: null, distance: 0, merchant: null});
    findLocation = new BehaviorSubject({key: null, location: null, distance: 0});


    constructor(public af: AngularFireDatabase,public sharedDataService: ShareDataService) {

        this.dbRef = af.list("location");
        //this.geoFire = new GeoFire(this.dbRef.query.ref);


        //this.getLocations(100,[40.962536,-73.184753]);
    }

    getMerchantLocationByLocation(radius: number, coords: Array<number>) {
        this.findLocation = new BehaviorSubject({key: null, location: null, distance: 0});
        this.findLocation.next({key: null, location: null, distance: 0});
        console.log('the findLocation object should be without much information ', this.findLocation);
        console.log('This is called -- radius ', radius, ' coords -- ', coords, this.findLocation);

        this.geoFire.query({
            center: coords,
            radius: radius
        })
            .on('key_entered', (key, location, distance) => {
                let hit = {
                    key: key,
                    location: location,
                    distance: distance,
                };
                console.log('find this ', hit);
                this.findLocation.next(hit);
                console.log(' just find these locations ', this.findLocation);
            });

        this.findLocation.subscribe(v => {
            console.log('new find location is added', v.key);
        });
    }


    getMerchantsByLocation(radius: number, coords: Array<number>) {
        this.hits = new BehaviorSubject({key: null, location: null, distance: 0, merchant: null});
        console.log('This is called -- radius ', radius, ' coords -- ', coords);

        this.geoFire.query({
            center: coords,
            radius: radius
        })
            .on('key_entered', (key, location, distance) => {

                this.getLocationDetail(key).pipe(map(v => {
                    let hit = {
                        key: key,
                        location: location,
                        distance: distance,
                        merchant: v,
                    };

                    //let currentHits = this.hits.value;
                    //currentHits.push(hit);

                    //currentHits.set(key,hit);
                    //this.hits.next(currentHits);
                    this.hits.next(hit);
                    //console.log('Detail is: ', v);
                })).subscribe();
            })
    }

    getLocationDetail(key) {
        return this.af.object('merchantLocation/' + key).valueChanges();
    }

    getMerchantDetail(key) {
        return this.af.object('merchant/' + key).valueChanges();
    }

    getMerchantByShortName(shortName){
        return this.af.list('merchant', ref => ref.orderByChild('sharedInfo/shortName').equalTo(shortName)).snapshotChanges().pipe(map(values => {
            return values.map(c => {
                return {key: c.payload.key, ...c.payload.val() as {}};
            });
        }));
    }



    getAllMerchantList() {
        return this.af.list('merchant').snapshotChanges().pipe(map(values => {
            return values.map(c => ({key: c.payload.key, ...c.payload.val() as {}}));
        }));
    }

    getAllMerchantLocationList() {
        return this.af.list('merchantLocation').snapshotChanges().pipe(map(values => {
            return values.map(c => ({key: c.payload.key, ...c.payload.val() as {}}));
        }));
    }

    getMerchantLocationHistoryList(locationID) {
        return this.af.list('merchantLocationHistory/' + locationID).valueChanges();
    }


    getLocationDistance(location1, location2): number {
        return distanceBetween(location1, location2) / 1.6;
    }

    getMerchantProfile(key) {
        return this.af.object('merchantProfile/' + key).valueChanges();
    }

    getMerchantLocationListByRegion(region) {
        if (region == null || region == undefined || region.trim() == '') {
            return this.getAllMerchantLocationList();
        }else{
            return this.af.list('merchantLocation', ref => ref.orderByChild('locationRegion').equalTo(region)).snapshotChanges().pipe(map(values => {
                return values.map(c => {
                    return {key: c.payload.key, ...c.payload.val() as {}};
                });
            }));
        }
    }

  getMerchantLocationListByMembershipStatus(status) {
    if (status == null || status == undefined || status.trim() == '') {
      return this.getAllMerchantLocationList();
    }else{
      return this.af.list('merchantLocation', ref => ref.orderByChild('sharedInfo/merchantStatusInfo/membershipStatus').equalTo(status)).snapshotChanges().pipe(map(values => {
        return values.map(c => {
          return {key: c.payload.key, ...c.payload.val() as {}};
        });
      }));
    }
  }

    getMerchantLocationByMerchantID(merchantID) {
        return this.af.list('merchantLocation', ref => ref.orderByChild('merchantID').equalTo(merchantID)).snapshotChanges().pipe(map(values => {
            return values.map(c => ({key: c.payload.key, ...c.payload.val() as {}}));
        }));
    }

  getMerchantLocationByShortName(shortName){
    return this.af.list('merchantLocation', ref => ref.orderByChild('locationShortName').equalTo(shortName)).snapshotChanges().pipe(map(values => {
      return values.map(c => {
        return {key: c.payload.key, ...c.payload.val() as {}};
      });
    }));
  }

    getMerchantReviewList(merchantID) {
        return this.af.list('merchantReview/' + merchantID + '/reviews').valueChanges()
    }

    getMerchantReviewSummary(merchantID) {
        return this.af.object('merchantReview/' + merchantID + '/review_summary').valueChanges();
    }

    createMerchantReview(merchantID, reviewContent, rating, userID) {
        let review = {userID: userID, content: reviewContent, rating: rating};
        return this.af.list('merchantReview/' + merchantID + '/reviews').push(review);
    }

    getMerchantScheduleByDate(merchantID, locationID, dateKey) {
        let path = 'merchantSchedule/' + merchantID + '/' + locationID + '/';
        return this.af.list(path, ref => ref.orderByChild('locationDetail/dateKey').equalTo(dateKey)).snapshotChanges().pipe(map(values => {
            return values.map(c => ({key: c.payload.key, ...c.payload.val() as {}}));
        }));
    }

    getMerchantFutureScheduleByDate(merchantID, locationID, dateKey) {
        let path = 'merchantSchedule/' + merchantID + '/' + locationID + '/';
        return this.af.list(path, ref => ref.orderByChild('locationDetail/dateKey').startAt(dateKey)).snapshotChanges().pipe(map(values => {
            return values.map(c => ({key: c.payload.key, ...c.payload.val() as {}}));
        }));
    }

    getMerchantSchedule(merchantId,locationId){
        let path = 'merchantSchedule/'  + merchantId + '/'  + locationId + '/';
        return this.af.list(path).snapshotChanges().pipe(map(values => {
            return values.map(c => ({ key: c.payload.key, ...c.payload.val() as {} }));
        }));
    }

    getMerchantListByRegion  (region) {
        if (region == null || region == undefined || region.trim() == '') {
            return this.getAllMerchantList()
        } else {
            return this.af.list('merchant', ref => ref.orderByChild('sharedInfo/region').equalTo(region)).snapshotChanges().pipe(map(values => {
                return values.map(c => {
                    return {key: c.payload.key, ...c.payload.val() as {}};
                });
            }));
        }

    }

    getMerchantProfileListByRegion(region) {
        return this.af.list('merchantProfile', ref => ref.orderByChild('sharedInfo/region').equalTo(region)).snapshotChanges().pipe(map(values => {
            return values.map(c => {
                return {key: c.payload.key, ...c.payload.val() as {}};
            });
        }));
    }

    getMerchantRegularSchedule(merchantID, locationID, weekday) {
        let path = 'merchantRegularSchedule/' + merchantID + '/' + locationID + '/' + weekday + '/';
        return this.af.list(path).snapshotChanges().map(values => {
            return values.map(c => ({key: c.payload.key, ...c.payload.val() as {}}));
        });
    }

    updateMerchantProfile(profile): any {
        if (profile.merchantID === null) {
            //this is a new merchant profile
            console.log('will create merchant profile with value ', profile);
            let path = 'merchantProfile/';
            return this.af.list(path).push(profile);

        } else {
            //this is update to existing merchant profile
            console.log('will update merchant profile with value ', profile);
            let path = 'merchantProfile/' + profile.merchantID;
            return this.af.object(path).update(profile);
        }
    }

    updateMerchantOnBoardReadyStatus(status, merchantID) {
        let path = 'merchant/' + merchantID + '/sharedInfo/merchantOnBoardReadyStatus';
        console.log(path, status)
        return this.af.object(path).set(status);
    }

    updateMerchantOnBoardStatus(status, merchantID) {
        let path = 'merchant/' + merchantID + '/sharedInfo/merchantStatusInfo/onboardStatus';
        console.log(path, status)
        return this.af.object(path).set(status);
    }

  updateMerchantActiveStatus(status, merchantID) {
    let path = 'merchant/' + merchantID + '/sharedInfo/merchantStatusInfo/membershipStatus';
    console.log(path, status)
    return this.af.object(path).set(status);
  }

    createMerchantRegistration(merchantRegistration) {
        let val = {registration: merchantRegistration, status: 'New'}
        let path = 'merchantRegistration/';
        return this.af.list(path).push(val);
    }

    updateRegistrationStatus(merchantRegistration,status,key){
        let val = {registration: merchantRegistration, status: status}
        let path = 'merchantRegistration/'+key+'/';
        return this.af.object(path).set(val);
    }

    createOrderTrack(merchantID,locationID,utcTime){
      let orderTime = new Date();
      orderTime.setTime(utcTime);
      let dateKey = this.sharedDataService.getDateKey(orderTime);
      let orderTestref = this.af.database.ref('ordertest/'+merchantID+'/'+locationID);
      let orderKey = orderTestref.push().key;
      console.log('new order key is ', orderKey);
      let orderSummaryRef = this.af.database.ref('orderSummary/'+dateKey+'/'+merchantID+'/'+locationID);
      let newOrderNumber = '';
      orderSummaryRef.transaction((summary) =>{
        if(summary == null){
          summary = {totalOrder: 0,orders:{}}
        }
        summary.totalOrder++;
        summary.orders[orderKey] = {orderNumber: summary.totalOrder, total: 100*Math.random()};
        newOrderNumber = dateKey + '-' + this.sharedDataService.prepenZero(summary.totalOrder,4);
        return summary;
      }, function(error, committed, snapshot) {
        if (error) {
          console.log('Transaction failed abnormally!', error);
        } else if (!committed) {
          console.log('We aborted the transaction (because ada already exists).');
        } else {
          console.log('transaction submitted with new order number to be ', newOrderNumber);
        }
        console.log("Ada's data: ", snapshot.val());
      });
    }

  findMerchantByFacebook(facebook) {
    return this.af.list('merchant', ref => ref.orderByChild('socialLinks/facebookLink').equalTo(facebook)).snapshotChanges().pipe(map(values => {
      return values.map(c => {
        return {key: c.payload.key, ...c.payload.val() as {}};
      });
    }));
  }

  getMerchantListByOnBoardStatus  (onboardStatus) {
    if (onboardStatus == null || onboardStatus == undefined || onboardStatus.trim() == '') {
      return this.getAllMerchantList()
    } else {
      return this.af.list('merchant', ref => ref.orderByChild('sharedInfo/merchantStatusInfo/onboardStatus').equalTo(onboardStatus)).snapshotChanges().pipe(map(values => {
        return values.map(c => {
          return {key: c.payload.key, ...c.payload.val() as {}};
        });
      }));
    }
  }

  getMerchantListByMemberShipStatus  (status) {
    if (status == null || status == undefined || status.trim() == '') {
      return this.getAllMerchantList()
    } else {
      return this.af.list('merchant', ref => ref.orderByChild('sharedInfo/merchantStatusInfo/membershipStatus').equalTo(status)).snapshotChanges().pipe(map(values => {
        return values.map(c => {
          return {key: c.payload.key, ...c.payload.val() as {}};
        });
      }));
    }
  }

}
