import { Component, OnInit } from '@angular/core';
import {ModalController, NavParams} from "@ionic/angular";
import {finalize} from "rxjs/operators";
import {AngularFireStorage} from '@angular/fire/storage';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
})
export class FileUploadComponent implements OnInit {

  fileType : any = '';
  uploadFileLink: any = '';
  filePath :any = '/temp';
  fileName : any = '';

  constructor(public modalController: ModalController, public navParams: NavParams, public afStorage: AngularFireStorage) { }

  ngOnInit() {
    this.fileType = this.navParams.get("fileType")==undefined?'':this.navParams.get("fileType");
    this.uploadFileLink = this.navParams.get("uploadFileLink")==undefined?'':this.navParams.get("uploadFileLink");
    this.filePath = this.navParams.get("filePath")==undefined?'':this.navParams.get("filePath");
    this.fileName = this.navParams.get("fileName")==undefined?'':this.navParams.get("fileName");
    console.log(this.fileType + ' -- ' + this.uploadFileLink + ' -- ' + this.filePath + ' --- ' + this.fileName);
  }


  dismiss() {
    this.modalController.dismiss();
  }

  fileUploader(event) {
    console.log("Upload button is clicked ", event)
    if (event.files !== undefined) {
      for (var file of event.files) {
        console.log('inside file upload ', event.files)
        const file = event.files[0];
        const uploadFilePath = this.filePath + '/' + this.fileName;
        const fileRef = this.afStorage.ref(uploadFilePath);
        console.log('will upload file to ', uploadFilePath, ' --- ', event.files[0]);
        const task =
            this.afStorage.upload(uploadFilePath, event.files[0]);
        task.snapshotChanges().pipe(
            finalize(() => {
              fileRef.getDownloadURL().subscribe(v =>{
                if(v != null){
                  console.log("result is ", v);
                  this.uploadFileLink = v;
                  let data = {uploadFileLink: this.uploadFileLink};
                  this.modalController.dismiss(data);
                  this.dismiss();
                }
              })
            })
        ).subscribe()
      }

    }
  }

}
