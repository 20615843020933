import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./pages/home-region-page/home-region-page.module').then(m => m.HomeRegionPagePageModule)
  },
  {
    path: 'region',
    loadChildren: () => import('./pages/home-region-page/home-region-page.module').then(m => m.HomeRegionPagePageModule)
  },
  {
    path: 'yummy',
    loadChildren: () => import('./pages/tabs/tabs.module').then(m => m.TabsPageModule)
  },
  {
    path: 'vendor',
    loadChildren: () => import('./pages/vendor-list/vendor-list.module').then(m => m.VendorListPageModule)
  },
  {
    path: 'vendorLogin',
    loadChildren: () => import('./pages/merchant-pages/merchant-login/merchant-login.module').then(m => m.MerchantLoginPageModule)
  },
  {
    path: 'vendorSignup/:shortName',
    loadChildren: () => import('./pages/merchant-pages/merchant-signup/merchant-signup.module').then(m => m.MerchantSignupPageModule)
  },
  {
    path: 'adminLogin',
    loadChildren: () => import('./pages/admin-pages/admin-login/admin-login.module').then(m => m.AdminLoginPageModule)
  },
  {
    path: 'merchant',
    loadChildren: () => import('./pages/merchant-pages/merchant-tabs/merchant-tabs.module').then(m => m.MerchantTabsPageModule)
  },
  {
    path: 'admin',
    loadChildren: () => import('./pages/admin-pages/admin-tabs/admin-tabs.module').then(m => m.AdminTabsPageModule)
  },
  {
    path: 'clientLogin',
    loadChildren: () => import('./pages/login/login.module').then(m => m.LoginPageModule)
  },  {
    path: 'book-catering',
    loadChildren: () => import('./pages/book-catering/book-catering.module').then( m => m.BookCateringPageModule)
  }



];

@NgModule({
  imports: [
    RouterModule.forRoot(routes)
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
