import {Component, OnInit, NgModule} from '@angular/core';
import {AlertController, ModalController, NavParams} from "@ionic/angular";
import {OwnerMerchantService} from "../../../services/owner/merchant-service";
import {BehaviorSubject} from "rxjs";
import {OwnerReferenceDataService} from "../../../services/owner/reference-data-service";

@Component({
  selector: 'app-merchant-location-edit',
  templateUrl: './merchant-location-edit.component.html',
  styleUrls: ['./merchant-location-edit.component.scss'],
})
export class MerchantLocationEditComponent implements OnInit {

  merchantLocationID: any;
  merchantID: any;
  merchantLocation: any;
  merchant: any;
  locationInput: any = {locationName: '', locationRegion: '', onlineOrder: false, type: '', status:'',orderAhead:false,openStatusFromSchedule:false};
  locationStatusList: any = [];
  locationRegionList: any = [];
  locationTypeList: any = [];
  totalReferenceData = 1;


  constructor(public navParams: NavParams, private merchantService: OwnerMerchantService,
              public modalCtrl: ModalController,public refdataService: OwnerReferenceDataService,
              public alertCtrl: AlertController) {
  }

  ngOnInit() {
    this.merchantLocationID = this.navParams.get("locationID");
    this.locationStatusList.push({label: 'Active', value: 'Active'});
    this.locationStatusList.push({label: 'Suspend', value: 'Suspend'});
    this.locationStatusList.push({label: 'Deactivated', value: 'Deactivated'});

    this.locationTypeList.push({label: 'Food Truck', value: 'mobile'});
    this.locationTypeList.push({label: 'Food Stand', value: 'foodstand'});
    this.locationTypeList.push({label: 'Food Court', value: 'foodcourt'});
    this.locationTypeList.push({label: 'Restaurant', value: 'restaurant'});



    const processedRefdata = new BehaviorSubject(0); // 0 is the initial value
    let ss = processedRefdata.subscribe(count => {
      if (count == this.totalReferenceData) {
        //start to load data after reference data is loaded
        ss.unsubscribe();
        let s1 = this.merchantService.getMerchantDetail(this.merchantID).subscribe(v => {
          s1.unsubscribe();
          if (v != null) {
            this.merchant = v;
            if (this.merchantLocationID != null) {
              let s = this.merchantService.getLocationDetail(this.merchantLocationID).subscribe(v => {
                s.unsubscribe();
                if (v != null) {
                  this.merchantLocation = v;
                  this.locationInput = {
                    locationName: this.merchantLocation.locationName,
                    locationRegion: this.merchantLocation.locationRegion,
                    onlineOrder: this.merchantLocation.onlineOrder,
                    type: this.merchantLocation.type,
                    status: this.merchantLocation.status,
                    orderAhead:this.merchantLocation.orderAhead,
                    openStatusFromSchedule:this.merchantLocation.openStatusFromSchedule
                  };
                }
              });
            }else{
              this.locationInput = {
                locationName: '',
                locationRegion: this.merchant.sharedInfo.region,
                onlineOrder: false,
                type: 'mobile',
                status: 'Active'
              };
            }
          }
        });
      }
    });

    let s1 = this.refdataService.getRegionList().subscribe(v=>{
      s1.unsubscribe;
      if(v != null){
        for(let region of v){
          this.locationRegionList.push({label: region.region, value: region.region});
        }
      }
      processedRefdata.next(1+processedRefdata.getValue());
    });
  }



  dismiss() {
    this.modalCtrl.dismiss();
  }

  async save() {
    if (this.locationInput.locationName == '' || this.locationInput.locationName == null) {
      let alert = await this.alertCtrl.create({
        header: 'Missing Field',
        message: 'Please make sure the following required fields are filled: Location Name',
        buttons: ['OK']
      });
      await alert.present();
    }else{
      if(this.merchantLocationID == null){
        //this is a new location
        this.merchantLocation = {
          id: null,
          source: 'manual',
          currentLocation:{},
          locationName: this.locationInput.locationName,
          locationRegion: this.locationInput.locationRegion,
          locationShortName: this.merchant.sharedInfo.shortName + '-' + (this.merchant.locationList.length),
          merchantID: this.merchant.id,
          onlineOrder:this.locationInput.onlineOrder,
          processTime:'10',
          saleTaxRate:this.merchant.sharedInfo.saleTax,
          sharedInfo: this.merchant.sharedInfo,
          status:this.locationInput.status,
          type:this.locationInput.type
        }
        this.merchantService.createNewMerchantLocation(this.merchantLocation);

      }else{
        //this is modification of existing location
        this.merchantLocation.locationName = this.locationInput.locationName;
        this.merchantLocation.locationRegion = this.locationInput.locationRegion;
        this.merchantLocation.onlineOrder = this.locationInput.onlineOrder;
        this.merchantLocation.type = this.locationInput.type;
        this.merchantLocation.status = this.locationInput.status;
        this.merchantService.updateMerchantLocation(this.merchantLocation);
      }
      this.modalCtrl.dismiss();
    }


  }
}
