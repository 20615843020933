import {Injectable} from '@angular/core';

import {Storage} from '@ionic/storage';
import {AlertController} from '@ionic/angular';
import {Platform} from '@ionic/angular';
import {ReferenceDataService} from "./reference-data-service";
import {AuthService} from "./auth-service";
import {UserAccountService} from "./user-account-service";
import {EventsService} from "./events-service";


export class CustomerOrder {
    constructor(
        public customerOrderId: any,
        public customerName: string,
        public totalOrderPrice: number,
        public saleTax: number,
        public discount: number,
        public finalPrice: number,
        public merchantId: string,
        public merchantName: string,
        public orderItems: any[],
        public status: string,
        public qrCode: string,
        public orderDate: string,
        public orderComment: string
    ) {
    }
}

export class OrderItem {
    constructor(
        public menuitemId: string,
        public primaryItemDescription: string,
        public optionalItemDescription: string,
        public totalPrice: number,
        public quantity: number,
        public itemComment: string
    ) {
    }
}

@Injectable({
    providedIn: 'root'
})
export class UserData {
    _favorites: any[] = [];
    HAS_LOGGED_IN = 'hasLoggedIn';
    HAS_SEEN_TUTORIAL = 'hasSeenTutorial';
    LOCATION_HISTORY = 'locationHistory';

    CURRENT_SEARCH_LOCATION = 'currentSearchLocation';

    foodcart: any = new CustomerOrder('', '', 0.0, 0.0, 0.0, 0.0, '', '', [], '', '', '', '');
    merchant: any;
    currentLocation: string;
    locationHistory: string[] = [];
    searchHistory: string[] = [];
    orderItemId: number = 0;
    orderId: number = 1;
    orderHistory: CustomerOrder[] = [];
    currentUserAccount: any = {
        "name": '',
        "email": '',
        "phone": '',
        "accountType": '',
        "merchantID": '',
        "userName": ''
    };

    allMerchantLocationList: any;
    allMerchantList: any;
    allMerhchantLocationMap: Map<string, any> = new Map<string, any>();
    allMerhchantMap: Map<string, any> = new Map<string, any>();
    userFavoriteListMap: Map<string, any> = new Map<string, any>();

    popularLocationList: any;
    regionList: any;
    regionTimeZoneMap: Map<string, any> = new Map<string, any>();


    MOBILE_MAX_WIDTH = 500;  // Adjust as needed


    constructor(
        public events: EventsService,
        public storage: Storage, public refdataService: ReferenceDataService,
        public alertCtrl: AlertController,
        public platform: Platform
    ) {
    }


    setUserAccount(userAccount: any): void {
        console.log('will set user account as ', userAccount);
        this.currentUserAccount = userAccount;
    }

    getUserAccount(): any {
        return this.currentUserAccount;
    }


    setMerchant(merchant) {
        this.merchant = merchant;
    }

    getMerchant() {
        return this.merchant;
    }

    getCurrentSearchLocation(): Promise<string> {
        return this.storage.get(this.CURRENT_SEARCH_LOCATION).then((value) => {
            return value;
        });
    };

    setCurrentSearchLocation(location) {
        this.storage.set(this.CURRENT_SEARCH_LOCATION, JSON.stringify(location));
    }

    getAllMerchantLocationList() {
        return this.allMerchantLocationList;
    }

    setAllMerchantLocationList(merchantList) {
        this.allMerchantLocationList = merchantList;
        this.allMerhchantLocationMap = new Map<string, any>();
        for (let item of merchantList) {
            this.allMerhchantLocationMap.set(item.key, item);
        }
    }

    findMerchantLocationByKey(key) {
        return this.allMerhchantLocationMap.get(key);
    }


    getAllMerchantList() {
        return this.allMerchantList;
    }

    setAllMerchantList(merchantList) {
        this.allMerchantList = merchantList;
        this.allMerhchantMap = new Map<string, any>();
        for (let item of merchantList) {
            this.allMerhchantMap.set(item.key, item);
        }
    }

    findMerchantByKey(key) {
        return this.allMerhchantMap.get(key);
    }

    getFavoriteList() {
        return Array.from(this.userFavoriteListMap.values());
    }

    setFavoriteList(favoriteList) {
        if (favoriteList != null) {
            console.log('set favorite list ', favoriteList)
            for (let item of favoriteList) {
                this.userFavoriteListMap.set(item.merchantID, item);
            }
        }
    }

    isFavoriteExist(merchantID) {
        console.log('favorite list map ', this.userFavoriteListMap);
        return this.userFavoriteListMap.has(merchantID);
    }

    addFavorite(favorite) {
        this.userFavoriteListMap.set(favorite.merchantID, favorite);
    }

    removeFavorite(favorite) {
        this.userFavoriteListMap.delete(favorite.merchantID);
    }

    getPopularLocationList() {

        if (this.popularLocationList == null) {
            const populatLocationSub = this.refdataService.getPopularLocationList().subscribe(v => {
                if (v != null) {
                    console.log('Popular location list is ', v);
                    this.setPopularLocationList(v);
                    populatLocationSub.unsubscribe();
                    return this.popularLocationList;
                }
            });
        } else {
            return this.popularLocationList;
        }
    }

    setPopularLocationList(locationList) {
        this.popularLocationList = locationList;
    }

    getRegionList() {
        if (this.regionList === undefined || this.regionList === null || this.regionList.length === 0) {
            let regionListSub = this.refdataService.getRegionList().subscribe(v => {
                if (v != null) {
                    this.regionList = v;
                    regionListSub.unsubscribe();
                    this.regionList = v;
                    return this.regionList;
                } else {
                    return null;
                }
            });
        } else {
            return this.regionList;
        }
    }

    setRegionList(list) {
        this.regionList = list;
        this.regionTimeZoneMap = new Map<string, any>();
        for (let item of list) {
            this.regionTimeZoneMap.set(item.region, item.timezone);
        }
    }

    getRegionTimeZoneMap(): Map<string, any> {
        return this.regionTimeZoneMap;
    }


    getCurrentMerchant() {
        return this.merchant;
    }

    getCurrentMerchantId() {
        if (this.merchant != null)
            return this.merchant.key;
        else
            return null;
    }

    addToCart(order) {
        if (this.foodcart.orderItems.length == 0) {
            this.setMerchant(order.merchant);
        } else {
            if (this.getMerchant().key != order.merchant.key) {
                this.showMultiMerchantAlert();
                return;
            }
        }
        for (var i = 0; i < this.foodcart.orderItems.length; i++) {
            if (this.foodcart.orderItems[i].orderItemId == order.orderItemId) {
                //this is the old order to be updated
                this.foodcart.orderItems[i] = order;
            }
        }
        if (order.orderItemId == 0) {
            //this is a new order
            this.orderItemId = this.orderItemId + 1;
            order.orderItemId = this.orderItemId;
            this.foodcart.orderItems.push(order);
        }

        this.calculateCartPrice();
        console.log('The cart is now after update ', this.foodcart);
        this.events.publish('Cart Updated');
    }

    removeFromCart(order) {
        console.log('The cart is now before remove ', this.foodcart);
        for (var i = 0; i < this.foodcart.orderItems.length; i++) {
            if (this.foodcart.orderItems[i].menuitem.name == order.menuitem.name) {
                this.foodcart.orderItems.splice(i, 1);
                this.events.publish('Cart Updated');
                return;
            }
        }
        this.calculateCartPrice();
    }

    calculateCartPrice() {
        console.log('The merchant information is ', this.merchant.merchant)
        var totalOrderPrice = 0.0;
        var saleTax = 0.0;
        var finalPrice = 0.0;
        for (var i = 0; i < this.foodcart.orderItems.length; i++) {
            totalOrderPrice = totalOrderPrice + this.foodcart.orderItems[i].totalPrice;
        }
        if (this.merchant != null)
            saleTax = totalOrderPrice * this.merchant.merchant.sharedInfo.saleTax;
        finalPrice = totalOrderPrice + saleTax;
        this.foodcart.totalOrderPrice = totalOrderPrice;
        this.foodcart.saleTax = saleTax;
        this.foodcart.finalPrice = finalPrice;
    }

    getCartStatus() {
        return this.foodcart;
    }

    emptyCart() {
        this.foodcart.orderitems = [];
        this.foodcart = new CustomerOrder('', '', 0.0, 0.0, 0.0, 0.0, '', '', [], '', '', '', '');
        this.orderItemId = 0;
        this.events.publish('Cart Updated');
    }

    async showMultiMerchantAlert() {
        const alert = await this.alertCtrl.create({
            header: 'Order Issue',
            subHeader: 'You have selected item from another merchant. Orders cannot be combined!',
            buttons: ['OK']
        });
        alert.present();
    }


    addLocationHistory(newLocation: string): void {


        var locationList: string[] = [];

        this.getLocationHistory().then((value) => {
            console.log('the value is ', value);
            if (value === null) {
                locationList.push(newLocation);
                console.log("the location now is --- : ", locationList);
            } else {
                var currentLocation = JSON.parse(value);
                console.log("current location", currentLocation, " newLocation", newLocation);
                if (currentLocation != null) {
                    for (var location of currentLocation) {
                        if (location != newLocation) {
                            locationList.push(location);
                        }
                    }
                }
                locationList.push(newLocation);
                console.log("the location or is --- : ", locationList);
            }
            console.log("The location list : ", locationList, "json object: ", JSON.stringify(locationList));
            this.storage.set(this.LOCATION_HISTORY, JSON.stringify(locationList));
        });


    };

    getLocationHistory(): Promise<string> {
        return this.storage.get(this.LOCATION_HISTORY).then((value) => {
            return value;
        });
    };

    cleanLocationHistory() {
        this.storage.set(this.LOCATION_HISTORY, JSON.stringify(null));
    }

    resetLocalStorage() {
        this.storage.clear();
    }

    resetFavoriteList() {

    }


    getOrderHistory() {
        return this.orderHistory;
    }

    isSmallWindow(): any {
        if (this.platform.width() < this.MOBILE_MAX_WIDTH) {
            return true;
        } else {
            return false;
        }
    }


}
