import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams} from "@ionic/angular";
import {OwnerUserDataService} from "../../../services/owner/user-data-service";

@Component({
  selector: 'app-merchant-order-summary',
  templateUrl: './merchant-order-summary.component.html',
  styleUrls: ['./merchant-order-summary.component.scss'],
})
export class MerchantOrderSummaryComponent implements OnInit {

  merchant: any;
  submittedOrder: any;

  constructor(public userData: OwnerUserDataService,
              public navParams: NavParams, public modalCtrl: ModalController
              ){

  }



  hasOptionalSelections(optionalSelections: any){
    if(optionalSelections == "")
      return false;
    else
      return true;
  }

  dismiss() {
    this.modalCtrl.dismiss();
  }

  ngOnInit(): void {
    this.submittedOrder = this.navParams.data.submittedOrder;
  }

}
