import {Component,OnInit} from '@angular/core';
import { Router } from '@angular/router';


@Component({
  templateUrl: './catering_select_vendor.html',
})
export class SelectCateringVendorComponent implements OnInit {

  personalInformation: any;
  submitted: boolean = false;

  constructor( private router: Router) { }

  ngOnInit() {
  }

  nextPage() {
    this.router.navigate(['steps/seat']);
  }
}
