import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {RouteReuseStrategy} from '@angular/router';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';


import {IonicModule, IonicRouteStrategy} from '@ionic/angular';
import {SplashScreen} from '@ionic-native/splash-screen/ngx';
import {StatusBar} from '@ionic-native/status-bar/ngx';
import {IonicStorageModule} from '@ionic/storage-angular';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';

import {AngularFireModule} from '@angular/fire';
// for AngularFireDatabase
import {AngularFireDatabaseModule} from '@angular/fire/database';
import {AngularFireDatabase} from '@angular/fire/database';
// for AngularFireAuth
import {AngularFireAuthModule} from '@angular/fire/auth';
import {AngularFireAuth} from '@angular/fire/auth';
import {AngularFirestoreModule} from '@angular/fire/firestore';
import {AngularFireStorageModule} from '@angular/fire/storage';
import {Firebase} from '@ionic-native/firebase/ngx';

import {Geolocation} from '@ionic-native/geolocation/ngx';

import {HttpClientModule} from '@angular/common/http';

import {MessageService} from 'primeng/api';

import {NgxQRCodeModule} from 'ngx-qrcode2';
import {QRScanner, QRScannerStatus} from '@ionic-native/qr-scanner/ngx';

import {MerchantData} from './services/merchant-data';
import {UserData} from './services/user-data';
import {MerchantService} from './services/merchant-service';
import {MerchantMenuService} from './services/merchant-menu-service';
import {AuthService} from './services/auth-service';
import {OrderProcessService} from './services/order-process-service';
import {UserAccountService} from './services/user-account-service';
import {AddressGeocodeService} from './services/address-geocode-service';
import {ReferenceDataService} from './services/reference-data-service'
import {FcmNotificationService} from './services/fcm_notification_service'
import {ShareDataService} from './services/shareData-service'
import {AuthenticationService} from "./services/authentication-service";


import {LocationSearchComponent} from './pages/location-search/location-search.component'
import {MerchantSearchComponent} from './pages/merchant-search/merchant-search.component'
import {MerchantFilterComponent} from './pages/merchant-filter/merchant-filter.component'
import {OrderCustomerContactComponent} from "./pages/order-customer-contact/order-customer-contact.component";
import {OrderSubmissionSummaryComponent} from "./pages/order-submission-summary/order-submission-summary.component";
import {OrderHistoryDetailComponent} from './pages/order-history-detail/order-history-detail.component'
import {MenuItemComponent} from "./pages/menu-item/menu-item.component";
import {MenuSearchComponent} from "./pages/menu-search/menu-search.component";
import {MerchantReviewAddComponent} from './pages/merchant-review-add/merchant-review-add.component'

import {CommonModule} from "@angular/common";
import {FormsModule} from "@angular/forms";
import {PrimeNGModule} from "./prime.module";
import {ServiceWorkerModule} from '@angular/service-worker';
import {environment} from '../environments/environment';
import {OwnerAuthService} from "./services/owner/vendor-auth-service";
import {OwnerUserDataService} from "./services/owner/user-data-service";
import {OwnerAddressGeocodeService} from "./services/owner/address-geocode-service";
import {OwnerFcmNotificationService} from "./services/owner/fcm_notification_service";
import {OwnerMerchantMenuService} from "./services/owner/merchant-menu-service";
import {OwnerMerchantOrderService} from "./services/owner/merchant-order-service";
import {OwnerMerchantService} from "./services/owner/merchant-service";
import {OwnerOrderProcessService} from "./services/owner/order-process-service";
import {OwnerReferenceDataService} from "./services/owner/reference-data-service";
import {MerchantOrderDetailComponent} from "./pages/merchant-pages/merchant-order-detail/merchant-order-detail.component";
import {MerchantMenuCategoryEditComponent} from "./pages/merchant-pages/merchant-menu-category-edit/merchant-menu-category-edit.component";
import {MerchantMenuCategoryReorderComponent} from "./pages/merchant-pages/merchant-menu-category-reorder/merchant-menu-category-reorder.component";
import {MerchantMenuItemReorderComponent} from "./pages/merchant-pages/merchant-menu-item-reorder/merchant-menu-item-reorder.component";
import {MerchantMenuItemEditComponent} from "./pages/merchant-pages/merchant-menu-item-edit/merchant-menu-item-edit.component";
import {MerchantMenuOptionalItemEditComponent} from "./pages/merchant-pages/merchant-menu-optional-item-edit/merchant-menu-optional-item-edit.component";
import {MerchantMenuSelectionItemEditComponent} from "./pages/merchant-pages/merchant-menu-selection-item-edit/merchant-menu-selection-item-edit.component";
import {MerchantModifyAccountsComponent} from "./pages/merchant-pages/merchant-modify-accounts/merchant-modify-accounts.component";
import {MerchantEditProfileComponent} from "./pages/merchant-pages/merchant-edit-profile/merchant-edit-profile.component";
import {MerchantLocationSearchComponent} from "./pages/merchant-pages/merchant-location-search/merchant-location-search.component";
import {MerchantFavoriteLocationEditComponent} from './pages/merchant-pages/merchant-favorite-location-edit/merchant-favorite-location-edit.component';
import {MerchantDailyScheduleEditComponent} from "./pages/merchant-pages/merchant-daily-schedule-edit/merchant-daily-schedule-edit.component";
import {MerchantRegularScheduleEditComponent} from "./pages/merchant-pages/merchant-regular-schedule-edit/merchant-regular-schedule-edit.component";
import {MerchantMenuItemComponent} from "./pages/merchant-pages/merchant-menu-item/merchant-menu-item.component";
import {MerchantOrderSummaryComponent} from "./pages/merchant-pages/merchant-order-summary/merchant-order-summary.component";
import {MerchantLocationStatusUpdateComponent} from "./pages/merchant-pages/merchant-location-status-update/merchant-location-status-update.component";
import {MerchantScheduleUpdateComponent} from "./pages/merchant-pages/merchant-schedule-update/merchant-schedule-update.component";
import {AdminModifyMerchantLocationComponent} from "./pages/admin-pages/admin-modify-merchant-location/admin-modify-merchant-location.component";
import {AdminCreateNewMerchantComponent} from "./pages/admin-pages/admin-create-new-merchant/admin-create-new-merchant.component";
import {MerchantSelectionComponent} from "./pages/merchant-selection/merchant-selection.component";
import {FileUploadComponent} from "./pages/file-upload/file-upload.component";
import {UserAccountLoginComponent} from "./pages/user-account-login/user-account-login.component";
import {TermPolicyComponent} from "./pages/term-policy/term-policy.component";
import {AutoCompleteModule} from "primeng/autocomplete";
import {MerchantLocationEditComponent} from "./pages/merchant-pages/merchant-location-edit/merchant-location-edit.component";
import {AdminViewScheduleComponent} from "./pages/admin-pages/admin-view-schedule/admin-view-schedule.component";
import {GeoLocationService} from "./services/geo-location-service";
import {SelectCateringVendorComponent} from "./pages/book-catering/vendor/select-catering-vendor.component";




@NgModule({
  declarations: [
    AppComponent,
    LocationSearchComponent,
    MerchantSearchComponent,
    MerchantFilterComponent,
    MenuItemComponent,
    OrderCustomerContactComponent,
    OrderSubmissionSummaryComponent,
    OrderHistoryDetailComponent,
    MenuSearchComponent,
    MerchantReviewAddComponent,
    MerchantOrderDetailComponent,
    MerchantMenuCategoryEditComponent,
    MerchantMenuCategoryReorderComponent,
    MerchantMenuItemReorderComponent,
    MerchantMenuItemEditComponent,
    MerchantMenuOptionalItemEditComponent,
    MerchantMenuSelectionItemEditComponent,
    MerchantModifyAccountsComponent,
    MerchantEditProfileComponent,
    MerchantLocationSearchComponent,
    MerchantFavoriteLocationEditComponent,
    MerchantDailyScheduleEditComponent,
    MerchantRegularScheduleEditComponent,
    MerchantMenuItemComponent,
    MerchantOrderSummaryComponent,
    MerchantLocationStatusUpdateComponent,
    MerchantScheduleUpdateComponent,
    AdminModifyMerchantLocationComponent,
    AdminCreateNewMerchantComponent,
    MerchantSelectionComponent,
    FileUploadComponent,
    UserAccountLoginComponent,
    MerchantLocationEditComponent,
    AdminViewScheduleComponent,
    SelectCateringVendorComponent
  ],
  entryComponents: [
    LocationSearchComponent,
    MerchantSearchComponent,
    MerchantFilterComponent,
    MenuItemComponent,
    OrderCustomerContactComponent,
    OrderSubmissionSummaryComponent,
    OrderHistoryDetailComponent,
    MenuSearchComponent,
    MerchantReviewAddComponent,
    MerchantOrderDetailComponent,
    MerchantMenuCategoryEditComponent,
    MerchantMenuCategoryReorderComponent,
    MerchantMenuItemReorderComponent,
    MerchantMenuItemEditComponent,
    MerchantMenuOptionalItemEditComponent,
    MerchantMenuSelectionItemEditComponent,
    MerchantModifyAccountsComponent,
    MerchantEditProfileComponent,
    MerchantLocationSearchComponent,
    MerchantFavoriteLocationEditComponent,
    MerchantDailyScheduleEditComponent,
    MerchantRegularScheduleEditComponent,
    MerchantMenuItemComponent,
    MerchantOrderSummaryComponent,
    MerchantLocationStatusUpdateComponent,
    MerchantScheduleUpdateComponent,
    AdminModifyMerchantLocationComponent,
    AdminCreateNewMerchantComponent,
    MerchantSelectionComponent,
    FileUploadComponent,
    UserAccountLoginComponent,
    MerchantLocationEditComponent,
    AdminViewScheduleComponent,
    SelectCateringVendorComponent
  ],
    imports: [
        CommonModule,
        FormsModule,
        PrimeNGModule,
        BrowserModule,
        HttpClientModule,
        IonicModule.forRoot(),
        AppRoutingModule,
        IonicStorageModule.forRoot(
        ),
        AngularFireModule.initializeApp(environment.firebaseConfig),
        AngularFireDatabaseModule,
        AngularFireAuthModule,
        AngularFirestoreModule,
        AngularFireStorageModule,
        BrowserAnimationsModule,
        NgxQRCodeModule
      //,
        //ServiceWorkerModule.register('ngsw-worker.js', {enabled: environment.production})
    ],
  providers: [
    StatusBar,
    SplashScreen,
    Firebase,
    Geolocation,
    QRScanner,
    MerchantData,
    UserData,
    MerchantService,
    MerchantMenuService,
    AuthService,
    AuthenticationService,
    OrderProcessService,
    UserAccountService,
    AddressGeocodeService,
    ReferenceDataService,
    MessageService,
    FcmNotificationService,
    ShareDataService,
    OwnerAuthService,
    OwnerUserDataService,
    OwnerAddressGeocodeService,
    OwnerFcmNotificationService,
    OwnerMerchantMenuService,
    OwnerMerchantOrderService,
    OwnerMerchantService,
    OwnerOrderProcessService,
    OwnerReferenceDataService,
    GeoLocationService,
    {provide: RouteReuseStrategy, useClass: IonicRouteStrategy}
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
