import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFireAuth } from '@angular/fire/auth';
import * as firebase from 'firebase';
import { Platform} from '@ionic/angular';



import {AlertController} from '@ionic/angular';


import { UserData } from '../services/user-data';
import {reject} from "q";
import {UserAccountService} from "./user-account-service";
import {FcmNotificationService} from "./fcm_notification_service";
import {EventsService} from "./events-service";


@Injectable({
    providedIn: 'root'
})
export class AuthenticationService {

    authState: any = null;
    loginCredential : any = null;
    loginUserId : any = null;

    constructor(private afAuth: AngularFireAuth,
                private db: AngularFireDatabase,
                private alertCtrl: AlertController,
                private events: EventsService,
                private userData: UserData,
                //public userAccountService : UserAccountService,
                public platform: Platform,
                //public fcmService: FcmNotificationService
    ) {

        this.afAuth.authState.subscribe((auth) => {
            let oldUserId = this.loginUserId;
            this.authState = auth;
            if(auth != null)
                this.loginUserId = auth.uid;
            else
                this.loginUserId = null;
            console.log("authState change: ", this.authState, " -- ", this.loginUserId, " -- ", oldUserId);
            if(this.loginUserId != null && oldUserId == null){
                //should send out a notification to let the side menu adjusted correctly
                console.log('sending notification');
                this.events.publish('user:login');
            }
            /*           if(this.isLogin()){
                           //this is authorized user
                           console.log('check the current user account ', this.userData.currentUserAccount);
                           if(this.userData.currentUserAccount.email == '' || this.userData.currentUserAccount.email == undefined){
                               //user account information need to be set up and menu set up need to be set up too
                               const s1 = this.userAccountService.getUserAccount().subscribe(v =>{
                                   s1.unsubscribe();
                                   if(v != null){
                                       let u:any = v;
                                       const userID= this.currentUserId;
                                       this.userData.setUserAccount({"name": u.fullName,"email": u.emailAddress,"phone":u.phoneNumber});
                                       if(this.platform.is('cordova')){
                                           console.log('initialize the message service')
                                           this.fcmService.initialize(userID);
                                           this.fcmService.getToken();
                                           this.fcmService.listenToNotifications();
                                       }
                                       this.events.publish('user:login');
                                       console.log('sync the user account');
                                   }
                               });            }
                       }*/
        });
    }

    // Returns true if user is logged in
    get authenticated(): boolean {
        console.log('authState is ', this.authState);
        return this.authState !== null;
    }


    // Returns current user UID
    get currentUserId(): string {
        return this.loginUserId;
    }

    // Returns
    get currentUserObservable(): any {
        return this.afAuth.authState
    }



    //// Email/Password Auth ////
    signupUser(email:string, password:string,name:string):any {
        console.log(`register account ${email} + ${password} + ${name}`);
        return new Promise((resolve, reject) => {
            this.afAuth.createUserWithEmailAndPassword(email, password)
                .then((user) => {
                    console.log('the new created user is ', JSON.stringify(user));
                    this.events.publish('user:signup');
                    this.loginUserId = user.user.uid;
                    resolve(user.user.uid);
            })
            .catch(error => {
                console.log(error);
                reject(error);
            });
        });
    }

    emailLogin(email:string, password:string) {
        return new Promise((resolve, reject) => {
            this.afAuth.signInWithEmailAndPassword(email, password)
                .then((user) => {
                    this.authState = user;
                    this.loginUserId = user.user.uid;
                    resolve(user.user.uid);
                    this.events.publish('user:login');
                })
                .catch(error => {
                    console.log(error);
                    reject(error);
                });
        });
    }

    passwordReset(email:string){
        return new Promise((resolve, reject) => {
            this.afAuth.sendPasswordResetEmail(email)
                .then((result) => {
                    resolve('success');
                })
                .catch(error => {
                    console.log(error);
                    reject('fail');
                });
        });
    }

    signOut() {
        return this.afAuth.signOut().then(v =>{
            this.loginCredential = null;
            this.loginUserId = null;
            this.events.publish('user:logout');
        });
    }

    isLogin(){
        if(this.loginUserId != null && this.loginUserId != undefined && this.loginUserId != ''){
            //this is a real login user
            return true;
        }else{
            return false;
        }
    }

    // Anonymous User
    get currentUserAnonymous(): boolean {
        return this.authenticated ? this.authState.isAnonymous : true
    }
}
