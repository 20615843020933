import {Component, OnInit} from '@angular/core';
import {AlertController, ModalController, NavParams} from "@ionic/angular";
import {OwnerMerchantService} from "../../../services/owner/merchant-service";
import {MerchantLocationSearchComponent} from "../merchant-location-search/merchant-location-search.component";

@Component({
    selector: 'app-merchant-daily-schedule-edit',
    templateUrl: './merchant-daily-schedule-edit.component.html',
    styleUrls: ['./merchant-daily-schedule-edit.component.scss'],
})
export class MerchantDailyScheduleEditComponent implements OnInit {


    merchantid: any;
    merchantLocationId: any;
    scheduleId: any;
    merchantSchedule: any = {
        eventYear: '',
        eventMonth: '',
        eventDay: '',
        acStartTime: '',
        acEndTime: '',
        locationName: '',
        address: '',
        lat: 0.0,
        lng: 0.0,
        enterBy: '',
        dateKey: ''
    }

    locationName: any;
    scheduledAddreass: any;
    lat: any;
    lng: any;
    scheduleDate: any;
    startTime: any = '11:00';
    endTime: any = '15:00';
    weekday = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    monthList = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    scheduleDateNew: Date = new Date();
    startTimeNew: Date = new Date();
    endTimeNew: Date = new Date();
    merchantLocationList: any = [];
    locationDropDownList: any = [];

    constructor(public navParams: NavParams, public modalCtrl: ModalController, private merchantService: OwnerMerchantService,
                public alertCtrl: AlertController) {
    }

    ngOnInit() {

        this.merchantid = this.navParams.get("merchantid");
        this.merchantLocationId = this.navParams.get("merchantLocationId");
        this.scheduleId = this.navParams.get("scheduleId");
        this.merchantLocationList = this.navParams.get("merchantLocationList");

        let sc = this.navParams.get("currentSchedule");
        console.log('Passed in schedule ', sc);

        if (sc === null) {
            this.scheduleId = null;
            this.merchantSchedule = {
                eventYear: '',
                eventMonth: '',
                eventDay: '',
                acStartTime: '',
                acEndTime: '',
                locationName: '',
                address: '',
                lat: 0.0,
                lng: 0.0,
                enterBy: '',
                dateKey: ''
            }
            this.startTimeNew.setHours(11);
            this.startTimeNew.setMinutes(0)
            this.endTimeNew.setHours(15);
            this.endTimeNew.setMinutes(0);
        } else {
            this.merchantSchedule = {
                eventYear: '',
                eventMonth: '',
                eventDay: '',
                acStartTime: '',
                acEndTime: '',
                locationName: '',
                address: '',
                lat: 0.0,
                lng: 0.0,
                enterBy: '',
                dateKey: ''
            }
            this.merchantSchedule.eventYear = sc.locationDetail.eventYear;
            this.merchantSchedule.eventMonth = sc.locationDetail.eventMonth;
            this.merchantSchedule.eventDay = sc.locationDetail.eventDay;
            this.merchantSchedule.acStartTime = sc.locationDetail.acStartTime;
            this.merchantSchedule.acEndTime = sc.locationDetail.acEndTime;
            this.merchantSchedule.locationName = sc.locationDetail.locationName;
            this.merchantSchedule.address = sc.locationDetail.address;
            this.merchantSchedule.lat = sc.locationDetail.lat;
            this.merchantSchedule.lng = sc.locationDetail.lng;
            this.merchantSchedule.enterBy = sc.locationDetail.enterBy;
            this.merchantSchedule.dateKey = sc.locationDetail.dateKey;
            this.scheduleId = sc.key;

            this.scheduledAddreass = sc.locationDetail.address;
            this.locationName = sc.locationDetail.locationName;
            this.scheduleDateNew.setFullYear(sc.locationDetail.eventYear);
            this.scheduleDateNew.setMonth(sc.locationDetail.eventMonth - 1);
            this.scheduleDateNew.setDate(sc.locationDetail.eventDay);
            let startTimeString = sc.locationDetail.acStartTime.split(':');
            this.startTimeNew.setHours(startTimeString[0]);
            this.startTimeNew.setMinutes(startTimeString[1]);
            let endTimeString = sc.locationDetail.acEndTime.split(':');
            this.endTimeNew.setHours(endTimeString[0]);
            this.endTimeNew.setMinutes(endTimeString[1]);

            this.scheduleDate = sc.locationDetail.eventYear + '-' + sc.locationDetail.eventMonth + '-' + sc.locationDetail.eventDay;
            this.startTime = sc.locationDetail.acStartTime;
            this.endTime = sc.locationDetail.acEndTime;
            this.lat = sc.locationDetail.lat;
            this.lng = sc.locationDetail.lng;
        }

        if (this.merchantLocationList == undefined || this.merchantLocationList == null) {
            this.locationDropDownList.push({value: this.merchantLocationId, label: 'Default Location'});
        } else {
            for (let location of this.merchantLocationList) {
                this.locationDropDownList.push({value: location.id, label: location.name});
            }
        }
        console.log('location list is ', this.merchantLocationList, ' drop down list is ', this.locationDropDownList);

    }

    saveNew() {
        console.log(this.scheduleDateNew, ' ----', this.startTimeNew, '----', this.endTimeNew);
        console.log(this.scheduleDateNew.getFullYear(), '-', this.scheduleDateNew.getMonth() + 1, '-', this.scheduleDateNew.getDate());
        console.log(this.startTimeNew.getHours(), ':', this.startTimeNew.getMinutes(), ' to ', this.endTimeNew.getHours(), ':', this.endTimeNew.getMinutes());
        this.merchantSchedule.eventYear = this.scheduleDateNew.getFullYear();
        this.merchantSchedule.eventMonth = (this.scheduleDateNew.getMonth() + 1) > 9 ? '' + (this.scheduleDateNew.getMonth() + 1) : '0' + (this.scheduleDateNew.getMonth() + 1);
        this.merchantSchedule.eventDay = this.scheduleDateNew.getDate() > 9 ? '' + this.scheduleDateNew.getDate() : '0' + this.scheduleDateNew.getDate();
        console.log(this.merchantSchedule.eventYear, '-', this.merchantSchedule.eventMonth, '-', this.merchantSchedule.eventDay);
        let startHour = this.startTimeNew.getHours() > 9 ? '' + this.startTimeNew.getHours() : '0' + this.startTimeNew.getHours();
        let startMinute = this.startTimeNew.getMinutes() > 9 ? '' + this.startTimeNew.getMinutes() : '0' + this.startTimeNew.getMinutes();
        let endHour = this.endTimeNew.getHours() > 9 ? '' + this.endTimeNew.getHours() : '0' + this.endTimeNew.getHours();
        let endMinute = this.endTimeNew.getMinutes() > 9 ? '' + this.endTimeNew.getMinutes() : '0' + this.endTimeNew.getMinutes();
        this.merchantSchedule.acStartTime = startHour + ':' + startMinute + ':00';
        this.merchantSchedule.acEndTime = endHour + ':' + endMinute + ':00';
        console.log(this.merchantSchedule.acStartTime, ' - ', this.merchantSchedule.acEndTime);
        this.merchantSchedule.locationName = this.locationName==null?'':this.locationName;
        this.merchantSchedule.address = this.scheduledAddreass;
        this.merchantSchedule.lat = this.lat;
        this.merchantSchedule.lng = this.lng;
        this.merchantSchedule.dateKey = this.merchantSchedule.eventYear + '-' + this.merchantSchedule.eventMonth + '-' + this.merchantSchedule.eventDay;
        console.log(this.merchantSchedule);
        console.log('merchant id ', this.merchantid, ' location id', this.merchantLocationId);

        if (this.scheduleId === null) {
            //this is a new schedule
            //console('create a new schedule');
            this.merchantService.createMerchantSchedule(this.merchantid, this.merchantLocationId, this.merchantSchedule).then(v => {
                this.dismiss();
            });
        } else {
            //console('update existing schedule');
            this.merchantService.updateMerchantSchedule(this.merchantid, this.merchantLocationId, this.scheduleId, this.merchantSchedule).then(v => {
                this.dismiss();
            });
        }
    }


    save() {
        console.log(this.scheduleDate, ' ----', this.startTime, '----', this.endTime);
        var date1 = new Date(this.scheduleDate);
        var date2 = new Date(this.startTime);
        var date3 = new Date(this.endTime)
        let s = this.scheduleDate.split("-");
        this.merchantSchedule.eventYear = s[0];
        this.merchantSchedule.eventMonth = s[1];
        this.merchantSchedule.eventDay = s[2];
        if (this.startTime.search('00:00') < 0)
            this.merchantSchedule.acStartTime = this.startTime + ':00';
        else
            this.merchantSchedule.acStartTime = this.startTime;
        if (this.endTime.search('00:00') < 0)
            this.merchantSchedule.acEndTime = this.endTime + ':00';
        else
            this.merchantSchedule.acEndTime = this.endTime;
        this.merchantSchedule.locationName = this.locationName==null?'':this.locationName;
        this.merchantSchedule.address = this.scheduledAddreass;
        this.merchantSchedule.lat = this.lat;
        this.merchantSchedule.lng = this.lng;
        this.merchantSchedule.dateKey = s[0] + '-' + s[1] + '-' + s[2]


        console.log(this.merchantSchedule);
        console.log('merchant id ', this.merchantid, ' location id', this.merchantLocationId);
        if (this.scheduleId === null) {
            //this is a new schedule
            //console('create a new schedule');
            this.merchantService.createMerchantSchedule(this.merchantid, this.merchantLocationId, this.merchantSchedule).then(v => {
                this.dismiss();
            });
        } else {
            //console('update existing schedule');
            this.merchantService.updateMerchantSchedule(this.merchantid, this.merchantLocationId, this.scheduleId, this.merchantSchedule).then(v => {
                this.dismiss();
            });
        }
    }

    removeSchedule() {
        this.merchantService.removeMerchantSchedule(this.merchantid, this.merchantLocationId, this.scheduleId).then(v => {
            this.dismiss();
        });
    }


    dismiss() {
        this.modalCtrl.dismiss();
    }


    async locationSearch() {
        console.log('location search is called');
        let modal = await this.modalCtrl.create(
            {
                component: MerchantLocationSearchComponent,
                componentProps: {
                    merchantId: this.merchantid,
                    showFavorite: true
                }
            });
        modal.onDidDismiss().then(v => {
            let data = v.data;
            console.log('will use the new location to update merchant', data);
            if (data != null) {
                this.scheduledAddreass = data.address;
                if (data.locationName === null) {
                    this.locationName = data.address;
                } else {
                    this.locationName = data.locationName;
                }
                this.lat = data.lat;
                this.lng = data.lng;
            }


        });
        await modal.present();

    }


}
