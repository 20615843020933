import { Component, OnInit } from '@angular/core';
import {OwnerMerchantMenuService} from "../../../services/owner/merchant-menu-service";
import {AlertController, LoadingController, ModalController, NavParams} from "@ionic/angular";
import {MerchantMenuSelectionItemEditComponent} from "../merchant-menu-selection-item-edit/merchant-menu-selection-item-edit.component";

@Component({
  selector: 'app-merchant-menu-optional-item-edit',
  templateUrl: './merchant-menu-optional-item-edit.component.html',
  styleUrls: ['./merchant-menu-optional-item-edit.component.scss'],
})
export class MerchantMenuOptionalItemEditComponent  {

  merchantid :any;
  originalItem:any;
  updatedItem = {id:'', item:{name:'',description:'',requiredSelection:0,maxSelection:1,selections:[]}};



  optionalitemMap: Map<string, any> = new Map<string, any>();
  selectedOptionalItemMap :Map<string, any> = new Map<string, any>();
  selectedOptionalItem: any[] = [];
  optionalItemList: any[] = [];



  constructor(public merchantMenuService : OwnerMerchantMenuService,public navParams: NavParams,
              public loadingCtrl: LoadingController,public alertCtrl: AlertController,
              public modalCtrl: ModalController) {
    this.merchantid = navParams.get("merchantid");
    this.originalItem = navParams.get("optionalItem");
    if(this.originalItem != null){
      //this is to update existing optional item
      this.updatedItem.id = this.originalItem.key;
      this.updatedItem.item.name = this.originalItem.name;
      this.updatedItem.item.description = this.originalItem.description;
      this.updatedItem.item.requiredSelection = this.originalItem.requiredSelection;
      this.updatedItem.item.maxSelection = this.originalItem.maxSelection;
      if(this.originalItem.selections != null){
        for(let selection of this.originalItem.selections){
          this.updatedItem.item.selections.push(selection);
        }
      }
    }


    console.log('Original Item is ',this.originalItem, 'updated item will be ', this.updatedItem);

  }

  save(){

    console.log('the update item will be ', this.updatedItem);
    let data = {'oldItem': this.originalItem, 'updatedItem': this.updatedItem};


    this.modalCtrl.dismiss(data);
  }

  dismiss() {
    this.modalCtrl.dismiss();
  }

  removeSelection(selection){
    let newSelection = [];
    for(let s of this.updatedItem.item.selections){
      if(s.name != selection.name){
        newSelection.push(s);
      }
    }
    this.updatedItem.item.selections = newSelection;
  }


  async editSelection(selection){
    console.log('will edit/create selection item', selection);
    let newItem :any;
    let originalItem: any;
    let newSelectionList: any[] = [];

    let modal = await this.modalCtrl.create(
        {
          component: MerchantMenuSelectionItemEditComponent,
          componentProps: {'selectionItem': selection}
        });
    modal.onDidDismiss().then(v => {
      let data:any = v;
      console.log('returned data is ',data);
      if(data != null){
        //the menu item need to be updated
        newItem = data.data.updatedItem;
        originalItem = data.data.oldItem;
        console.log('updated menu item will be ', newItem);
        if(originalItem === null){
          //this will be the first selection to add to the optional item
          newSelectionList.push({'name':newItem.name, 'description': newItem.description, 'price': newItem.price});
          for(let s of this.updatedItem.item.selections){
            newSelectionList.push(s);
          }

        }else{
          for(let s of this.updatedItem.item.selections){
            if(s.name === originalItem.name){
              newSelectionList.push({'name':newItem.name, 'description': newItem.description, 'price': newItem.price})
            }else{
              newSelectionList.push(s);
            }
          }
        }
        this.updatedItem.item.selections = newSelectionList;
      }
    });

    await modal.present();
  }


}
