import {Component} from '@angular/core';
import {AlertController, ModalController, NavParams} from "@ionic/angular";
import {OwnerMerchantService} from "../../../services/owner/merchant-service";
import {OwnerReferenceDataService} from "../../../services/owner/reference-data-service";
import {AngularFireStorage} from '@angular/fire/storage';
import {NgForm} from "@angular/forms";
import {SelectItem} from "primeng/api";
import {UserData} from "../../../services/user-data";
import {ShareDataService} from "../../../services/shareData-service";

@Component({
    selector: 'app-merchant-edit-profile',
    templateUrl: './merchant-edit-profile.component.html',
    styleUrls: ['./merchant-edit-profile.component.scss'],
})
export class MerchantEditProfileComponent {

    tagSelection: SelectItem[] = [];
    merchantID: any;
    merchantProfile: any = {sharedInfo: {name: ''}, sourceInfo: {}, socialLinks: {}, contactInfo: {}};
    sectionType: any = null;
    primaryInfo: any = {name: '', shortDescription: '', tags: [],region:'',saleTax:0.0}
    contactInfo: any = {email: '', phone: '', contactName: ''};
    socialLinkInfo: any = {website: '', facebook: '', twitter: '', instagram: '', yelp: ''}
    serviceInfo: any = { serviceTypes: [],membership:''}

    merchantTags: any = [];
    regionDropdownList:any = [];
    editRegion : any = false;
    serviceSelection:any = [];


    constructor(public navParams: NavParams, private merchantService: OwnerMerchantService,public shareDataService: ShareDataService,
                public refDataService: OwnerReferenceDataService,public userDataService: UserData,
                public alertCtrl: AlertController, public modalCtrl: ModalController, public afStorage: AngularFireStorage) {

    }

    ionViewWillEnter() {
        this.merchantID = this.navParams.get("merchantID");
        this.sectionType = this.navParams.get("sectionType");
        this.merchantProfile = this.navParams.get("profile");

        let s = this.refDataService.getTagList().subscribe(v => {
            s.unsubscribe();
            if (v != null) {
                this.merchantTags = v;
                for (let tag of this.merchantTags) {
                    this.tagSelection.push({label: tag, value: tag});
                }
            }
            console.log('the passed in merchant is ', this.merchantProfile)
            this.primaryInfo = {
                name: this.merchantProfile.sharedInfo.name,
                shortDescription: this.merchantProfile.sharedInfo.shortDescription,
                tags: this.merchantProfile.sharedInfo.tags,
                region: this.merchantProfile.sharedInfo.region,
                saleTax: this.merchantProfile.sharedInfo.saleTax
            }
            if(this.merchantProfile.contactInfo != null){
                this.contactInfo = {
                    contactName: this.merchantProfile.contactInfo.contactName,
                    phone: this.merchantProfile.contactInfo.primaryPhoneNumber,
                    email: this.merchantProfile.contactInfo.primaryEmail
                }
            }
            if(this.merchantProfile.socialLinks != null){
                this.socialLinkInfo = {
                    website: this.merchantProfile.socialLinks.website,
                    facebook: this.merchantProfile.socialLinks.facebookLink,
                    twitter: this.merchantProfile.socialLinks.tweetName,
                    instagram: this.merchantProfile.socialLinks.instagramLink,
                    yelp: this.merchantProfile.socialLinks.yelpAlias

                }
            }
            this.serviceInfo = { serviceTypes: this.merchantProfile.sharedInfo.serviceTypes,membership:this.merchantProfile.sharedInfo.merchantStatusInfo.memberShipType}
            if(this.userDataService.getRegionList() != null ){
                for(let region of this.userDataService.getRegionList()){
                    this.regionDropdownList.push({label: region.region, value: region.region});
                }
            }
            if(this.shareDataService.userRole === 'admin'){
                this.editRegion = true;
            }
            this.serviceSelection.push({label: 'Dinning', value: 'Dinning'})
            this.serviceSelection.push({label: 'Private Catering', value: 'Private Catering'})
            this.serviceSelection.push({label: 'Corporate Catering', value: 'Corporate Catering'})
            this.serviceSelection.push({label: 'Event Catering', value: 'Event Catering'})


        });

    }


    savePrimary() {
        console.log('Save primary information ', this.primaryInfo);
        this.merchantProfile.sharedInfo.name = this.primaryInfo.name;
        this.merchantProfile.sharedInfo.shortDescription = this.primaryInfo.shortDescription;
        this.merchantProfile.sharedInfo.tags = this.primaryInfo.tags;
        this.merchantProfile.sharedInfo.saleTax = this.primaryInfo.saleTax;
        this.merchantProfile.sharedInfo.region = this.primaryInfo.region;
        this.merchantService.updateMerchantSharedinfo(this.merchantProfile).then(x => {
            this.dismiss();
        });
    }

    saveContacts() {
        console.log('Save contact information ', this.contactInfo);
        this.merchantProfile.contactInfo.contactName = this.contactInfo.contactName;
        this.merchantProfile.contactInfo.primaryPhoneNumber = this.contactInfo.phone;
        this.merchantProfile.contactInfo.primaryEmail = this.contactInfo.email;
        this.merchantService.updateMerchantContactinfo(this.merchantProfile).then(x => {
            this.dismiss();
        });
    }

    saveSocialLinks() {
        console.log('Save social link information ', this.socialLinkInfo);
        this.merchantProfile.socialLinks.website = this.socialLinkInfo.website;
        this.merchantProfile.socialLinks.facebookLink = this.socialLinkInfo.facebook;
        this.merchantProfile.socialLinks.tweetName = this.socialLinkInfo.twitter;
        this.merchantProfile.socialLinks.instagramLink = this.socialLinkInfo.instagram;
        this.merchantProfile.socialLinks.yelpAlias = this.socialLinkInfo.yelp;
        this.merchantService.updateMerchantSocialLinks(this.merchantProfile).then(x => {
            this.dismiss();
        });
    }

    dismiss() {
        this.modalCtrl.dismiss();
    }

    fileUploader(event) {
        console.log("Upload button is clicked")
        if (event.files !== undefined) {
            for (var file of event.files) {
                console.log('inside file upload ', event.files)
                const file = event.files[0];
                const filePath =
                    this.afStorage.upload('/test/test1.jpg', event.files[0])
            }

        }
    }

    saveMembership() {
        console.log('Save service & membership ', this.serviceInfo);
        this.merchantProfile.sharedInfo.serviceTypes = this.serviceInfo.serviceTypes;
        this.merchantService.updateMerchantSharedinfo(this.merchantProfile).then(x => {
            this.dismiss();
        });
    }
}
