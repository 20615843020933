import {Component, OnInit} from '@angular/core';
import {AlertController, LoadingController, ModalController, NavParams} from "@ionic/angular";
import {OwnerMerchantService} from "../../../services/owner/merchant-service";
import {MerchantLocationSearchComponent} from "../merchant-location-search/merchant-location-search.component";
import {BehaviorSubject} from "rxjs";

@Component({
    selector: 'app-merchant-schedule-update',
    templateUrl: './merchant-schedule-update.component.html',
    styleUrls: ['./merchant-schedule-update.component.scss'],
})
export class MerchantScheduleUpdateComponent implements OnInit {


    merchantid: any;
    merchantLocationId: any;
    scheduleId: any;
    merchantSchedule: any = {
        eventYear: '',
        eventMonth: '',
        eventDay: '',
        acStartTime: '',
        acEndTime: '',
        locationName: '',
        address: '',
        lat: 0.0,
        lng: 0.0,
        enterBy: '',
        dateKey: ''
    }

    locationName: any;
    scheduledAddreass: any;
    lat: any;
    lng: any;
    scheduleDate: Date = new Date();
    startTime: Date = new Date();
    endTime: Date = new Date();
    weekday = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    monthList = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    locationDropDownList: any = [];
    merchantLocationList: any = [];

    constructor(public navParams: NavParams, public modalCtrl: ModalController, private merchantService: OwnerMerchantService,
                public loadingCtrl: LoadingController, public alertCtrl: AlertController) {


    }

    ngOnInit() {
        this.merchantid = this.navParams.get("merchantId");
        this.merchantLocationId = this.navParams.get("merchantLocationId");
        this.merchantLocationList = this.navParams.get("merchantLocationList");

        if (this.merchantLocationList == undefined || this.merchantLocationList == null) {
            this.locationDropDownList.push({value: this.merchantLocationId, label: 'Default Location'});
        } else {
            for (let location of this.merchantLocationList) {
                this.locationDropDownList.push({value: location.id, label: location.name});
            }
        }
        this.merchantSchedule = {
            eventYear: '',
            eventMonth: '',
            eventDay: '',
            acStartTime: '',
            acEndTime: '',
            locationName: '',
            address: '',
            lat: 0.0,
            lng: 0.0,
            enterBy: '',
            dateKey: ''
        }
        this.startTime.setHours(11);
        this.startTime.setMinutes(0)
        this.endTime.setHours(15);
        this.endTime.setMinutes(0);
        let s = this.merchantService.getLocationDetail(this.merchantLocationId).subscribe(v => {
            s.unsubscribe();
            if (v != null) {
                console.log('find location ', v)
                let l: any = v;
                if (l.currentLocation !== undefined) {
                    this.locationName = l.currentLocation.locationName;
                    this.scheduledAddreass = l.currentLocation.address;
                    this.lat = l.currentLocation.lat;
                    this.lng = l.currentLocation.lng;
                }
            }
        })
    }


    save() {
        console.log(this.scheduleDate, ' ----', this.startTime, '----', this.endTime, ' -- location ', this.locationName);
        var date1 = new Date(this.scheduleDate);
        var date2 = new Date(this.startTime);
        var date3 = new Date(this.endTime)
        console.log(date1.getFullYear(), '-', date1.getUTCMonth() + 1, '-', date1.getDate() + 1);
        console.log(this.scheduleDate.getFullYear(), '-', this.scheduleDate.getMonth() + 1, '-', this.scheduleDate.getDate());
        console.log(this.startTime.getHours(), ':', this.startTime.getMinutes(), ' to ', this.endTime.getHours(), ':', this.endTime.getMinutes());
        this.merchantSchedule.eventYear = this.scheduleDate.getFullYear();
        this.merchantSchedule.eventMonth = (this.scheduleDate.getMonth() + 1) > 9 ? '' + (this.scheduleDate.getMonth() + 1) : '0' + (this.scheduleDate.getMonth() + 1);
        this.merchantSchedule.eventDay = this.scheduleDate.getDate() > 9 ? '' + this.scheduleDate.getDate() : '0' + this.scheduleDate.getDate();
        console.log(this.merchantSchedule.eventYear, '-', this.merchantSchedule.eventMonth, '-', this.merchantSchedule.eventDay);
        let startHour = this.startTime.getHours() > 9 ? '' + this.startTime.getHours() : '0' + this.startTime.getHours();
        let startMinute = this.startTime.getMinutes() > 9 ? '' + this.startTime.getMinutes() : '0' + this.startTime.getMinutes();
        let endHour = this.endTime.getHours() > 9 ? '' + this.endTime.getHours() : '0' + this.endTime.getHours();
        let endMinute = this.endTime.getMinutes() > 9 ? '' + this.endTime.getMinutes() : '0' + this.endTime.getMinutes();
        this.merchantSchedule.acStartTime = startHour + ':' + startMinute + ':00';
        this.merchantSchedule.acEndTime = endHour + ':' + endMinute + ':00';
        console.log(this.merchantSchedule.acStartTime, ' - ', this.merchantSchedule.acEndTime);
        this.merchantSchedule.locationName = this.locationName==null?'':this.locationName;
        this.merchantSchedule.address = this.scheduledAddreass;
        this.merchantSchedule.lat = this.lat;
        this.merchantSchedule.lng = this.lng;
        this.merchantSchedule.dateKey = this.merchantSchedule.eventYear + '-' + this.merchantSchedule.eventMonth + '-' + this.merchantSchedule.eventDay;
        console.log(this.merchantSchedule);
        console.log('merchant id ', this.merchantid, ' location id', this.merchantLocationId);
        this.merchantService.updateMerchantLocationAddress(this.merchantLocationId, this.merchantSchedule).then(v => {
            this.dismiss();
        });

    }


    dismiss() {
        this.modalCtrl.dismiss();
    }

    async locationSearch() {
        console.log('location search is called');
        let modal = await this.modalCtrl.create(
            {
                component: MerchantLocationSearchComponent,
                componentProps: {
                    merchantId: this.merchantid,
                    showFavorite: true
                }
            });
        modal.onDidDismiss().then(v => {
            let data = v.data;
            console.log('will use the new location to update merchant', data);
            if (data != null) {
                this.scheduledAddreass = data.address;
                if (data.locationName === null) {
                    this.locationName = data.address;
                } else {
                    this.locationName = data.locationName;
                }
                this.lat = data.lat;
                this.lng = data.lng;
            }
        });
        await modal.present();

    }

    removeSchedule() {
        this.merchantService.removeMerchantSchedule(this.merchantid, this.merchantLocationId, this.scheduleId).then(v => {
            this.dismiss();
        });
    }
}
