import { Injectable } from '@angular/core';



import 'rxjs/add/operator/map';
import 'rxjs/add/observable/of';


export class MenuItem {
  constructor(
      public id: number,
      public name: string,
      public description: string,
      public price: number,
      public quantity: number,
      public selected: boolean) {
  }
}

export class OptionalMenuItem {
  constructor(
      public name: string,
      public description: string,
      public menuitems: MenuItem[],
      public requiredSelection: number,
      public maxSelection: number,
      public totalPrice: number) {
  }
}

export class MerchantMenu {
  constructor(
      public id: number,
      public name: string,
      public description: string,
      public image: string,
      public quantity: number,
      public totalPrice: number,
      public primaryMenuItem: MenuItem,
      public requiredMenuItems: OptionalMenuItem[],
      public optionalMenuItems: OptionalMenuItem[]) {
  }
}

@Injectable({
  providedIn: 'root'
})
export class MerchantData {
  data: any;
  merchantList: any;
  menuList: any[];
  merchantMenuList: MerchantMenu[];

  constructor() { }
/*

  loadMerchantList(): any {
    if (this.merchantList) {
      return Observable.of(this.merchantList);
    } else {
      return this.http.get('assets/data/merchant.json')
        .map(this.processMerchantData, this);
    }
  }

  processMerchantData(data: any) {
    // just some good 'ol JS fun with objects and arrays
    // build up the data by linking speakers to sessions
    this.merchantList = data.json().merchants;
    return this.merchantList;
  }


  loadMenuList(): any {
    if (this.menuList) {
      return Observable.of(this.menuList);
    } else {
      return this.http.get('assets/data/menu.json')
        .map(this.processMenuData, this);
    }
  }

  processMenuData(data: any) {
    // just some good 'ol JS fun with objects and arrays
    // build up the data by linking speakers to sessions
    this.menuList = data.json().menuitems;
    console.log("menu data: ",this.menuList);
    return this.menuList;
  }


  load(): any {
    if (this.data) {
      return Observable.of(this.data);
    } else {
      return this.http.get('assets/data/data.json')
        .map(this.processData, this);
    }
  }

  processData(data: any) {
    // just some good 'ol JS fun with objects and arrays
    // build up the data by linking speakers to sessions
    this.data = data.json();
    return this.data;
  }

  getMerchantList(){
    return this.loadMerchantList().map((data: any) =>{
      return this.merchantList;
    });
  }


  getMerchant(){
    return this.load().map((data: any) =>{
      return data.merchant;
    });
  }

  getMerchantDetailByID(merchantId: number){
    return this.loadMerchantList().map((data: any) =>{
      return this.merchantList.find(p => p.id === merchantId);
    });
  }

  getMerchantMenuByID(merchantId: number){
    return this.loadMenuList().map((data: any) =>{
      let merchnatMenuList: MerchantMenu[] = [];
      for(var menu of this.menuList){
        var merchantMenu: MerchantMenu;
        var primaryMenuItem = new MenuItem(menu.primaryMenuItem.id,menu.primaryMenuItem.name,menu.primaryMenuItem.description,menu.primaryMenuItem.price,1,false);
        var optionalMenuItems : OptionalMenuItem[] = [];
        var requiredMenuItems : OptionalMenuItem[] = [];
        if(menu.optionalMenuItems != null){
          for(var optionalMenu of menu.optionalMenuItems){
            var menuitems : MenuItem[] = [];
            for(var baseItem of optionalMenu.menuitems){
              var item1 = new MenuItem(baseItem.id,baseItem.name,baseItem.description,baseItem.price,1,false);
              menuitems.push(item1);
            }
            var optionalMenuItem : OptionalMenuItem = new OptionalMenuItem(optionalMenu.name,optionalMenu.description,menuitems,optionalMenu.requiredSelection,optionalMenu.maxSelection,0);
            if(optionalMenuItem.requiredSelection > 0){
              requiredMenuItems.push(optionalMenuItem);
            }else{
              optionalMenuItems.push(optionalMenuItem);
            }
          }
        }
        merchantMenu = new MerchantMenu(menu.id,menu.name,menu.description,menu.image,0,0,primaryMenuItem,requiredMenuItems,optionalMenuItems);
        merchnatMenuList.push(merchantMenu);
      }
      return merchnatMenuList;
      //return this.menuList.find(p => p.merchantId === merchantId);
    });
  }

  */
}
