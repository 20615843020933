import { Injectable } from '@angular/core';
import { AngularFireDatabaseModule, AngularFireDatabase } from '@angular/fire/database';
import { AngularFireAuth } from '@angular/fire/auth';
import * as firebase from 'firebase';
import {AlertController} from '@ionic/angular';




import { OwnerUserDataService } from '../owner/user-data-service';
import {EventsService} from "../events-service";


@Injectable()
export class OwnerAuthService {


  authState: any = null;

  constructor(private afAuth: AngularFireAuth,
              private db: AngularFireDatabase,
              private alertCtrl: AlertController,
              private events: EventsService,
              private userDataService: OwnerUserDataService
              ) {

            this.afAuth.authState.subscribe((auth) => {
              this.authState = auth;
            });
          }

  // Returns true if user is logged in
  get authenticated(): boolean {
    return this.authState !== null;
  }

  // Returns current user data
  get currentUser(): any {
    return this.authenticated ? this.authState : null;
  }

  // Returns
  get currentUserObservable(): any {
    return this.afAuth.authState
  }

  // Returns current user UID
  get currentUserId(): string {
    return this.authenticated ? this.authState.uid : ' ';
  }

  // Anonymous User
  get currentUserAnonymous(): boolean {
    return this.authenticated ? this.authState.isAnonymous : false
  }

  // Returns current user display name or Guest
  get currentUserDisplayName(): string {
    if (!this.authState) { return 'Guest' }
    else if (this.currentUserAnonymous) { return 'Anonymous' }
    else { return this.authState['displayName'] || 'User without a Name' }
  }
/*
  //// Social Auth ////
  githubLogin() {
    const provider = new firebase.GithubAuthProvider();
    return this.socialSignIn(provider);
  }

  googleLogin() {
    const provider = new firebase.GoogleAuthProvider();
    return this.socialSignIn(provider);
  }

  facebookLogin() {
    const provider = new firebase.FacebookAuthProvider();
    return this.socialSignIn(provider);
  }

  twitterLogin(){
    const provider = new firebase.TwitterAuthProvider();
    return this.socialSignIn(provider);
  }
*/
  private socialSignIn(provider) {
    return this.afAuth.signInWithPopup(provider)
      .then((credential) =>  {
          this.authState = credential.user;
        this.publishLoginEvent('user:login');
      })
      .catch(error => console.log(error));
  }


  //// Anonymous Auth ////
  anonymousLogin() {
    return this.afAuth.signInAnonymously()
    .then((user) => {
      this.authState = user;
      //this.updateUserData();
      this.publishLoginEvent('user:logout');
    })
    .catch(error => console.log(error));
  }

  //// Email/Password Auth ////
  emailSignUp(email:string, password:string) {
    return this.afAuth.createUserWithEmailAndPassword(email, password)
      .then((user) => {
        this.authState = user;
        this.updateUserData();
        this.publishLoginEvent('user:signup');
      })
      .catch(error => {
        console.log(error);
        this.showSignUpErrorAlert(error);
      });
  }

  emailLogin(email:string, password:string) {
     return this.afAuth.signInWithEmailAndPassword(email, password)
       .then((user) => {
         console.log(' login user infor ', user)
         this.authState = user;
         this.publishLoginEvent('user:vendorLogin');
         this.setUserAccountAfterLogin(this.authState.uid);
       })
       .catch(error => {
         console.log(error);
         this.showLoginErrorAlert(error);
       });
  }

  // Sends email allowing user to reset password
  resetPassword(email: string) {

    return this.afAuth.sendPasswordResetEmail(email)
      .then(() => console.log("email sent"))
      .catch((error) => console.log(error))
  }


  //// Sign Out ////
  signOut(): void {
    this.afAuth.signOut().then(v =>{
      this.authState = null;
      this.events.publish('user:logout');
    });
  }


  //// Helpers ////
  private updateUserData(): any {
  // Writes user name and email to realtime db
  // useful if your app displays information about users or for admin features
    let path = `merchantAccount/${this.currentUserId}`; // Endpoint on firebase
    let data = {
                  emailAddress: this.authState.email,
                  fullName: this.authState.displayName || "",
                  facebookAccount: "",
                  googleAccount: "",
                  firstName: "",
                  lastName: "",
                  phoneNumber: "",
                  merchantID: '',
                  status: 'Pending'
                };

    this.db.object(path).update(data).then(  v=>  {
      console.log("user created for ", data, ' result ', v);
      this.setUserAccountAfterLogin(this.currentUserId);
      return v; }
)
    .catch(error => console.log(error));

  }

  async showSignUpErrorAlert(title){
    let alert= await this.alertCtrl.create({
      header: 'Account Sign up',
      message: title,
      buttons: ['OK']
    });
    await alert.present();
  }

  async showLoginErrorAlert(title){
    let alert= await this.alertCtrl.create({
      header: 'User Login',
      message: title,
      buttons: ['OK']
    });
    alert.present();
  }

  setUserAccountAfterLogin(userID: string){
    this.db.object('merchantAccount/' + userID).valueChanges().subscribe(v =>{
      if(v != null){
        var u = v;
        console.log('find user account for setup is ', u);
        this.userDataService.setUserAccount({id:userID, account:u});
      }
    });

  }

  getUserAccountInformation(userID){
    console.log('try to get use account for use id: ', userID);
    return this.db.object('merchantAccount/' + userID).valueChanges();
  }

  publishLoginEvent(topic){
    this.events.publish(topic);
  }

  getAdminUserAccountInformation(userID){
    return this.db.object('adminAccount/' + userID).valueChanges();
  }



}
