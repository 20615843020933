import { Injectable  } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';


@Injectable()
export class OwnerReferenceDataService {




  constructor(public af:AngularFireDatabase) {

  }


  getPopularLocationList():any {
    return this.af.list('referencedata/popularlocation').valueChanges();
  }


  getRegionList(): any{
    return this.af.list('referencedata/regionList').valueChanges();
  }

  getTagList(){
    return this.af.list('referencedata/tagList').valueChanges();    
  }

}
