import {Injectable} from "@angular/core";
import {UserData} from "./user-data";
import {AuthService} from "./auth-service";
import {ReferenceDataService} from "./reference-data-service";


@Injectable({
  providedIn: 'root'
})
export class ShareDataService {

  constructor(public userData: UserData
  ) {
  }

  get merchantLocationIDforLoginAccount(): any {
    return this._merchantLocationIDforLoginAccount;
  }

  set merchantLocationIDforLoginAccount(value: any) {
    this._merchantLocationIDforLoginAccount = value;
  }


  locationList: any[] = [];
  selectedLocation: any;
  allMerchantLocationMap: Map<string, any> = new Map<string, any>();
  searchCriteria = {
    distance: 5,
    star: 0,
    openNow: false,
    todaySchedule: false,
    ownerClaimed: false,
    favorite: false,
    orderby: 'distance',
    tagSet: null
  };
  private _userRole = "client";
  private _merchantIDForLoginAccount: any;
  private _isOwnerAccountLogin: boolean = false;
  private _isAdminAccountLogin: boolean = false;
  private _userAccount: any;
  private _merchant: any;
  private _merchantLocationIDforLoginAccount: any

  private _selectedAddress: any = {};

  currentUserAccount: any = {"name": '', "email": '', "phone": '', "accountType": '', "merchantID": '', "userName": ''};


  setUserAccount(userAccount: any): void {
    console.log('set account is called for ', userAccount);
    this.currentUserAccount = userAccount;
  }

  getUserAccount(): any {
    return this.currentUserAccount;
  }


  get merchant(): any {
    return this._merchant;
  }

  set merchant(value: any) {
    this._merchant = value;
  }

  getLocationList() {
    return this.locationList
  }

  setLocationList(l) {
    this.locationList = l;
  }

  getSelectedLocation() {
    return this.selectedLocation;
  }

  setSelectedLocation(location) {
    this.selectedLocation = location;
  }


  getAllMerchantLocationMap() {
    return this.allMerchantLocationMap;
  }

  setAllMerchantLocationMap(allLocationMap) {
    this.allMerchantLocationMap = allLocationMap;
  }

  getSearchCriteria() {
    return this.searchCriteria;
  }

  setSearchCriteria(criteria) {
    this.searchCriteria = criteria;
  }


  get userRole(): string {
    return this._userRole;
  }

  set userRole(value: string) {
    this._userRole = value;
  }


  get merchantIDForLoginAccount(): any {
    return this._merchantIDForLoginAccount;
  }

  set merchantIDForLoginAccount(value: any) {
    this._merchantIDForLoginAccount = value;
  }

  get isOwnerAccountLogin(): boolean {
    return this._isOwnerAccountLogin;
  }

  set isOwnerAccountLogin(value: boolean) {
    this._isOwnerAccountLogin = value;
  }

  get isAdminAccountLogin(): boolean {
    return this._isAdminAccountLogin;
  }

  set isAdminAccountLogin(value: boolean) {
    this._isAdminAccountLogin = value;
  }

  get userAccount(): any {
    return this._userAccount;
  }

  set userAccount(value: any) {
    this._userAccount = value;
  }


  get selectedAddress(): any {
    return this._selectedAddress;
  }

  set selectedAddress(value: any) {
    this._selectedAddress = value;
  }

  getTodayDateKey() {
    return this.getDateKey(new Date());
  }


  getDateKey(d): any {
    let dateKey = '';
    if (d.getMonth() > 8) {
      dateKey = d.getFullYear() + '-' + (d.getMonth() + 1);
    } else {
      dateKey = d.getFullYear() + '-0' + (d.getMonth() + 1);
    }
    if (d.getDate() < 10) {
      dateKey = dateKey + '-0' + d.getDate();
    } else {
      dateKey = dateKey + '-' + d.getDate();
    }
    return dateKey;
  }

  getStatusText(merchant): any {
    const dateKey = this.getTodayDateKey();
    const dateKey1 = merchant.currentLocation.eventYear + '-' + merchant.currentLocation.eventMonth + '-' + merchant.currentLocation.eventDay;
    //console.log('comparing ', dateKey, ' to ', dateKey1)
    if (this.checkOpenStatus(merchant) == true) {
      //this is an open one
      return 'Current Location:'
    } else {
      if (dateKey === dateKey1) {
        return 'Today\'s Location:';
      } else {
        if (dateKey.localeCompare(dateKey1) === 1) {
          return 'Last Location:';
        } else {
          return 'Next Location';
        }
      }
    }
  }

  getBriefAddress(address) {
    if (address != null) {
      const a = address.split(',');
      return a[0];
    } else {
      return '';
    }
  }

  getStartTime(locationDetail) {

    const t = locationDetail.acStartTime.split(':');
    let d;
    d = new Date(locationDetail.eventYear, parseInt(locationDetail.eventMonth) - 1, locationDetail.eventDay, t[0], t[1], 0, 0);
    return d;
  }

  getEndTime(locationDetail) {
    const t = locationDetail.acEndTime.split(':');
    let d;
    d = new Date(locationDetail.eventYear, parseInt(locationDetail.eventMonth) - 1, locationDetail.eventDay, t[0], t[1], 0, 0);
    return d;
  }

  getMerchantLogo(logoLink: any) {
    if (logoLink == undefined || logoLink.trim() === 'https://firebasestorage.googleapis.com/v0/b/gulpmealdev.appspot.com/o/truck_default.png?alt=media&token=8e882d15-f8b9-4c81-b037-4d61def885e6')
      return 'assets/truck_default.png';
    else
      return logoLink;
  }

  checkClaimStatus(merchantLocation: any): boolean {
    console.log('location: ' + merchantLocation)
    if (merchantLocation.sharedInfo.merchantStatusInfo.onboardStatus != null && merchantLocation.sharedInfo.merchantStatusInfo.onboardStatus != undefined && merchantLocation.sharedInfo.merchantStatusInfo.onboardStatus === 'OwnerVerified')
      return true;
    else
      return false;
  }

  checkOnlineOrderStatus(merchantLocation: any): boolean {
    if (merchantLocation.onlineOrder != null && merchantLocation.onlineOrder != undefined && merchantLocation.onlineOrder === 'true')
      return true;
    else
      return false;
  }

  checkOpenStatus(merchantLocation): boolean {
    const d = new Date();
    const locationDetail = merchantLocation.currentLocation;
    let timeZone = '04:00';
    if (this.userData.getRegionTimeZoneMap().get(merchantLocation.sharedInfo.region) != null) {
      timeZone = this.userData.getRegionTimeZoneMap().get(merchantLocation.sharedInfo.region);
    }
    const startTime = Date.parse(locationDetail.eventYear + '-' + locationDetail.eventMonth + '-' + locationDetail.eventDay + 'T' + locationDetail.acStartTime + '.000-' + timeZone);
    const endTime = Date.parse(locationDetail.eventYear + '-' + locationDetail.eventMonth + '-' + locationDetail.eventDay + 'T' + locationDetail.acEndTime + '.000-' + timeZone);
    const now = Date.now();
    if (startTime <= now && now <= endTime) {
      // console.log('the merchant is open');
      return true;
    } else {
      return false;
    }
  }

  prepenZero(str, len) {
    if (typeof str === 'number' || Number(str)) {
      str = str.toString();
      return (len - str.length > 0) ? new Array(len + 1 - str.length).join('0') + str : str;
    } else {
      return str;
    }
  }

}
