// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebaseConfig : {
    apiKey: "AIzaSyCa3FNTlJZ962aPZhG4ZwlIJBXKxwFNZiE",
    authDomain: "gulpmealdev.firebaseapp.com",
    databaseURL: "https://gulpmealdev.firebaseio.com",
    projectId: "gulpmealdev",
    storageBucket: "gulpmealdev.appspot.com",
    messagingSenderId: "1097578868611"
  },
  version: "1.0.1",
  googleApiKey:"AIzaSyBq5eSUrGI64BPBx2LLmSuSuMSQuHiMf_0"
};


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
