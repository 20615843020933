import { Component, OnInit } from '@angular/core';
import {MenuItem} from "../../services/merchant-menu-service";
import {FormControl} from '@angular/forms';
import {MerchantData} from "../../services/merchant-data";
import {UserData} from "../../services/user-data";
import {ModalController, NavParams} from "@ionic/angular";
import {MenuItemComponent} from "../menu-item/menu-item.component";

@Component({
  selector: 'app-menu-search',
  templateUrl: './menu-search.component.html',
  styleUrls: ['./menu-search.component.scss'],
})
export class MenuSearchComponent {

  merchantDetail: any;
  menulist: MenuItem[] = [];


  merchant: any;
  fullMenuList: MenuItem[] = [];

  heartIcon : string = "heart";
  filterMenuList: MenuItem[] = [];
  searchTerm: string = '';
  searchControl: FormControl;
  searching: any = false;





  constructor(public dataProvider: MerchantData, public userData: UserData,
              public navParams: NavParams, public modalCtrl: ModalController) {
    this.searchControl = new FormControl();
  }

  ionViewWillEnter() {
    this.merchant = this.navParams.get("merchant");
    this.fullMenuList = this.navParams.get("menuList");
    this.filterMenuList = this.fullMenuList;
  }

  async openMenuItemModal(menuitem) {
    var quantity = 1;
    const modal = await this.modalCtrl.create({
      component: MenuItemComponent,
      componentProps: {
        'merchant': this.merchant,
        'menuItem':menuitem,
        'quantity': quantity,
        'orderItemId' : 0
      }
    });
    modal.onDidDismiss().then(v=>{
      console.log(v);
    });
    await modal.present();

  }


  setFilteredItems(ev:any){
    this.filterMenuList = [];
    this.searchTerm = ev.target.value;
    if(this.searchTerm.trim() === ''){
      this.filterMenuList = this.fullMenuList;
    }else{
      this.fullMenuList.filter((item) => {
        console.log("test ", item,item.primaryMenuItem.name.toLowerCase().indexOf(this.searchTerm.toLowerCase()));
        if(item.primaryMenuItem.name.toLowerCase().indexOf(this.searchTerm.toLowerCase()) > -1 || item.primaryMenuItem.description.toLowerCase().indexOf(this.searchTerm.toLowerCase()) > -1){
          this.filterMenuList.push(item);
        };
      });
    }
  }


  dismiss() {
    this.modalCtrl.dismiss();
  }

  resetMenuList(){
    this.filterMenuList = this.fullMenuList;
  }


}
