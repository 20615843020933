import { Injectable  } from '@angular/core';
import { AngularFireDatabase, AngularFireList, AngularFireObject } from '@angular/fire/database';
import {map} from "rxjs/operators";
import {BehaviorSubject} from "rxjs";


@Injectable()
export class OwnerMerchantService {

  dbRef: any;
  geoFire: any;
  hits = new BehaviorSubject([]);
  default_truck_image = 'https://firebasestorage.googleapis.com/v0/b/gulpmealdev.appspot.com/o/default_truck.png?alt=media&token=65f2c97a-79bd-4687-9cde-ff863f596f2e';


  constructor(public af:AngularFireDatabase) {

    this.dbRef = af.list("location");


  }




  getMerchantsByLocation(radius: number, coords: Array<number>) {
    this.hits = new BehaviorSubject([]);
    console.log('This is called -- radius ', radius, ' coords -- ', coords);

   this.geoFire.query({
     center: coords,
     radius: radius
   })
   .on('key_entered', (key, location, distance) => {

     this.getLocationDetail(key).pipe(map(v=>{
       let hit = {
         key: key,
         location: location,
         distance: distance,
         merchant: v,
       };

       let currentHits = this.hits.value;
       currentHits.push(hit);
       this.hits.next(currentHits);
       console.log('Detail is: ', v);
     })).subscribe();
   })
  }

  getLocationDetail(key){
    return this.af.object('merchantLocation/' + key).valueChanges();
  }


  getMerchantDetail(key){
    return this.af.object('merchant/' + key).valueChanges();
  }

  getMerchantList(){
    return this.af.list('merchant').snapshotChanges().pipe(map(values => {
      return values.map(c => ({ key: c.payload.key, ...c.payload.val() as {}}));
    }));
  }

  getMerchantLocationList(){
    return this.af.list('merchantLocation',
    ref=> ref.orderByChild('name')).snapshotChanges().pipe(map(values => {
      return values.map(c => ({ key: c.payload.key, ...c.payload.val() as {} }));
    }));
  }

  getMerchantProfileList(){
    return this.af.list('merchantProfile').snapshotChanges().pipe(map(values => {
      return values.map(c => ({ key: c.payload.key, ...c.payload.val() as {}}));
    }));
  }


  getMerchantLocationListByRegion(region){
    return this.af.list('merchantLocation',
    ref=> ref.orderByChild('sharedInfo/region').equalTo(region)).snapshotChanges().pipe(map(values => {
      return values.map(c => ({ key: c.payload.key, ...c.payload.val() as {}}));
    }));
  }


  updateMerchant(merchant) : any{
    if(merchant.id === null){
      //this is a new merchant
      console.log('will create merchant with value ', merchant);
      let path = 'merchant/';
      return this.af.list(path).push(merchant.merchant);

    }else{
    //this is update to existing merchant
    console.log('will update merchant with value ', merchant);
      let path = 'merchant/'  + merchant.id;
      return this.af.object(path).update(merchant.merchant);
    }
  }

  updateMerchantLocation(merchantLocation) : any{
    if(merchantLocation.id === null){
      //this is a new merchant location
      console.log('will create merchant location with value ', merchantLocation);
      let path = 'merchantLocation/';
      return this.af.list(path).push(merchantLocation);

    }else{
    //this is update to existing merchant location
    console.log('will update merchant location with value ', merchantLocation);
      let path = 'merchantLocation/'  + merchantLocation.id;
      return this.af.object(path).update(merchantLocation);
    }
  }

  updateMerchantProfile(merchantProfile){
    console.log('will update merchant profile with value ', merchantProfile);
      let path = 'merchant/'  + merchantProfile.merchantID;
      return this.af.object(path).update(merchantProfile);
  }

  updateMerchantSharedinfo(merchant){
    console.log('will update merchant share info with value ', merchant);
      let path = `merchant/${merchant.merchantID}/sharedInfo`;
      return this.af.object(path).update(merchant.sharedInfo);
  }

  updateMerchantContactinfo(merchant){
    console.log('will update merchant contact info with value ', merchant);
    let path = `merchant/${merchant.merchantID}/contactInfo`;
    return this.af.object(path).update(merchant.contactInfo);
  }

  updateMerchantSocialLinks(merchant){
    console.log('will update merchant social info with value ', merchant);
    let path = `merchant/${merchant.merchantID}/socialLinks`;
    return this.af.object(path).update(merchant.socialLinks);
  }

  getDefaultImageLink(){
    return this.default_truck_image;
  }

  updateMerchantGeoFireLocation(key,lat,lng){
    this.geoFire.set(key,[lat,lng]).then(function(){
      console.log('location updated for ', key);
    },function(error) {
      console.log("Error for saving location: " , error);
    });
  }

  updateMerchantLocationAddress(key,locationAddress){
    let path = 'merchantLocation/'  + key + '/currentLocation';
    return this.af.object(path).update(locationAddress);
  }

  updateMerchantLocationHistory(key,locationHistory){

    let hisPath = 'merchantLocationHistory/' + key ;
    return this.af.list(hisPath).push(locationHistory);
  }

  updateAllMerchantLocationHistory(key,name,locationHistory){
    let path = 'allMerchantLocHistory/'+locationHistory.year+'/'+locationHistory.month+'/'+locationHistory.day+'/'+key;
    let val = {name:name, location:locationHistory.location};
    return this.af.object(path).set(val);
  }

  getMerchantSchedule(merchantId,locationId){
    let path = 'merchantSchedule/'  + merchantId + '/'  + locationId + '/';
    return this.af.list(path).snapshotChanges().pipe(map(values => {
      return values.map(c => ({ key: c.payload.key, ...c.payload.val() as {} }));
    }));
  }

  updateMerchantSchedule(merchantId, locationId,scheduleId, schedule){
    let path = 'merchantSchedule/'  + merchantId + '/'  + locationId + '/'+ scheduleId + '/' ;
    return this.af.object(path).update({merchantID:merchantId,merchantLocationID:locationId,locationDetail:schedule});
  }

  createMerchantSchedule(merchantId, locationId, schedule){
    let path = 'merchantSchedule/'  + merchantId + '/'  + locationId + '/' ;
    return this.af.list(path).push({merchantID:merchantId,merchantLocationID:locationId,locationDetail:schedule});
  }

  removeMerchantSchedule(merchantId, locationId,scheduleId){
    let path = 'merchantSchedule/'  + merchantId + '/'  + locationId + '/'+ scheduleId + '/' ;
    return this.af.object(path).set(null);
  }

  getMerchantRegularSchedule(merchantId,locationId,weekday){
    const path = 'merchantRegularSchedule/'  + merchantId + '/'  + locationId + '/' + weekday+'/';
    return this.af.list(path).snapshotChanges().map(values => {
      return values.map(c => ({ key: c.payload.key, ...c.payload.val() as {} }));
    });
  }

  createMerchantRegularSchedule(merchantId, locationId, schedule){
    let path = 'merchantRegularSchedule/'  + merchantId + '/'  + locationId + '/' + schedule.eventDay ;
    return this.af.list(path).push({merchantID:merchantId,merchantLocationID:locationId,weekday:schedule.eventDay,locationDetail:schedule});
  }

  updateMerchantRegularSchedule(merchantId, locationId,scheduleId, schedule){
    let path = 'merchantRegularSchedule/'  + merchantId + '/'  + locationId + '/'+ schedule.dateKey + '/'+scheduleId + '/' ;
    return this.af.object(path).update({merchantID:merchantId,merchantLocationID:locationId,locationDetail:schedule});
  }

  removeMerchantRegularSchedule(merchantId, locationId,scheduleId,weekday){
    let path = 'merchantRegularSchedule/'  + merchantId + '/'  + locationId + '/'+ weekday + '/'+ scheduleId + '/' ;
    console.log('going to remove schedule for ', path)
    return this.af.object(path).set(null);
  }

  getMerchantFavoriteLocation(merchantId){
    let path = 'merchantFavoriteLocation/'  + merchantId + '/';
    return this.af.list(path).snapshotChanges().pipe(map(values => {
      return values.map(c => ({ key: c.payload.key, ...c.payload.val() as {}}));
    }));
  }

  createMerchantFavoriteLocation(merchantId, location){
    let path = 'merchantFavoriteLocation/'  + merchantId + '/'  ;
    return this.af.list(path).push(location);
  }

  updateMerchantFavoriteLocation(merchantId, id, location){
    let path = 'merchantFavoriteLocation/'  + merchantId + '/'  + id + '/' ;
    return this.af.object(path).update(location);
  }

  removeMerchantFavoriteLocation(merchantId, id){
    let path = 'merchantFavoriteLocation/'  + merchantId + '/'  +  id + '/' ;
    return this.af.object(path).set(null);
  }

  removeMerchantAccount(account){
    const path = `merchantAccount/${account.key}/`;
    return this.af.object(path).set(null);
  }


  getMerchantScheduleByDate(merchantID, locationID,dateKey){
    let path = 'merchantSchedule/'+merchantID+'/'+locationID+ '/';
    return this.af.list(path,ref=> ref.orderByChild('locationDetail/dateKey').equalTo(dateKey)).snapshotChanges().pipe(map(values => {
      return values.map(c => ({ key: c.payload.key, ...c.payload.val() as {} }));
    }));
  }

  getMerchantProfile(merchantID){
    let path = 'merchantProfile/'+merchantID+ '/';
    return this.af.object(path).valueChanges();
  }

  getMerchantReviewList(merchantID){
    return this.af.list('merchantReview/'+merchantID+'/reviews').valueChanges()
  }

  createNewMerchantAccount(account){
    const path = 'adminRequest/account';
    return this.af.list(path).push(account);
  }

  getMerchantUserAccount(userID){
    return this.af.object('merchantAccount/'+userID).valueChanges();
  }

  getMerchantUserAccountList(merchantID){
    let path = 'merchantAccount/';
    console.log('looking for merchant account list ',merchantID);
    return this.af.list(path,ref=> ref.orderByChild('merchantID').equalTo(merchantID)).snapshotChanges().pipe(map(values => {
      return values.map(c => ({ key: c.payload.key,value:{ ...c.payload.val() as {}} }));
    }));
  }

  updateMerchantAccount(account){
    const path = `merchantAccount/${account.key}/`;
    return this.af.object(path).set(account.value);
  }

  getMerchantListByRegion(region){
    return this.af.list('merchant',ref => ref.orderByChild('sharedInfo/region').equalTo(region)).snapshotChanges().pipe(map(values => {
       return values.map(c => {
            return { key: c.payload.key, ...c.payload.val() as {}};
      });
    }));
  }

  registerFCMToken(merchantID, token){
    return this.af.list(`merchantNotification/${merchantID}/`).push(token);
  }

  registerTruckOwner(registerRecord){
    return this.af.list('truckOwnerRegister/').push(registerRecord);

  }

  createNewAdminAccount(account){
    const path = 'adminAccount/bjaEscpu08g8o6A7loaGzwy4soR2';
    return this.af.object(path).set(account);
  }


  createNewMerchantLocation(merchantLocation){
    let val = {location: merchantLocation, status: 'New'}
    let path = 'stagingObjects/merchantLocation' ;
    return this.af.list(path).push(val);

  }

  createNewMerchant(merchant){
    let val = {merchant: merchant, status: 'New'}
    let path = 'stagingObjects/merchant' ;
    return this.af.list(path).push(val);

  }

  getUserAccountByEmail(email){
    return this.af.list('userAccount',ref => ref.orderByChild('emailAddress').equalTo(email)).snapshotChanges().pipe(map(values => {
      return values.map(c => {
        return { key: c.payload.key, ...c.payload.val() as {}};
      });
    }));
  }

  createMerchantLocation(merchant, merchantLocation){
    console.log('will update merchant ', merchant, ' merchant location ', merchantLocation);

    let latestIDRef = this.af.database.ref('referencedata/latestID');
    let orderSummaryRef = this.af.database.ref('merchantOrderSummary/' + '2021-08-28' + '/' + '10013' + '/' + '10013');

    let s = this.af.object('referencedata/latestID').valueChanges().subscribe(v=>{
      //s.unsubscribe();
      console.log('the result of latest id is ', v, ' -- ', Date.now())
      latestIDRef.transaction((summary)=>{
        console.log('summary is ', summary, ' --- ', Date.now());
      });
      s.unsubscribe();
    })



    /*

    latestIDRef.transaction((latestID) => {
      console.log('the latest IDs are: ', latestID);
      latestID.latestLocationID++;
      merchantLocation.id = latestID.latestLocationID;
      merchant.locationList.push(merchantLocation.id);
      return latestID;
    }).then(v=>{
      let path = 'merchantLocation/merchantLocation.id';
      this.af.object(path).set(merchantLocation).then(v=>{
        let merchantPath = 'merchant/merchant.id';
        this.af.object(merchantPath).set(merchant);
      });
    });
*/

  }


}
